//react
import React from "react"
//grommet
import {Anchor, Box, Button, Collapsible, DataTable, Heading, Meter, Paragraph, Text} from "grommet";
import {Alert, Hide, Star, View} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';

import YesNoModal from "./YesNoModal";
import Loading from "./Loading";
//lodash
import _ from 'lodash';
import PropTypes from "prop-types";
import ModalSkeleton from "./ModalSkeleton";
import Breadcrumb from "./Breadcrumb";
import MetaTags from "react-meta-tags";
import StudentActionButton from "./StudentActionButton";
import Models from "../models/appServices";

class Grades extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.getGradesAsArray = this.getGradesAsArray.bind(this);
    this.approveAll = this.approveAll.bind(this);
    this.approveAllConfirm = this.approveAllConfirm.bind(this);
    this.findAssignmentName = this.findAssignmentName.bind(this);
    this.toggleCollapseColumn = this.toggleCollapseColumn.bind(this);
    this.showAllColumns = this.showAllColumns.bind(this);
    this.showColumnsWithPendingApprovals = this.showColumnsWithPendingApprovals.bind(this);
    this.applyToAllAssignments = this.applyToAllAssignments.bind(this);
    this.assignmentHasPendingApprovals = this.assignmentHasPendingApprovals.bind(this);
    this.setGradeDetail = this.setGradeDetail.bind(this);
    this.refreshGradeTable = this.refreshGradeTable.bind(this);

    this.showGradeDetail = (studentUsername, assignmentID) => this.setGradeDetail(true, studentUsername, assignmentID);
    this.hideGradeDetail = () => this.setGradeDetail(false, null, null);
  }

  componentDidMount() {
    this.refreshGradeTable();
  }

  refreshGradeTable() {
    HyperGrade.services.getGradingInfo(this.props.courseID).then(server => {
      if (server.success) {
        this.setState({gradingInfo: server.data, loading: false});
        //refreshGradeTable will be called after approvial of grades in addition to being called on page load
        //"beginWorking" will be called when teacher confirms they want to approve all grades
        this.props.appServices.endWorking();
      }
    })
  }

  setGradeDetail(showGradeDetail, gradeDetailStudentUsername, gradeDetailAssignmentID) {
    this.setState({showGradeDetail, gradeDetailStudentUsername, gradeDetailAssignmentID});
  }

  applyToAllAssignments(callback) {
    let updatedGradingInfo = _.cloneDeep(this.state.gradingInfo);
    updatedGradingInfo.assignments.forEach(callback);
    this.setState({gradingInfo: updatedGradingInfo});
  }

  showAllColumns() {
    let assignmentToToggle = [];
    this.applyToAllAssignments(assignment => {
      if (assignment.grade_column_hide) {
        assignment.grade_column_hide = false;
        assignmentToToggle.push(assignment.id);
      }
    });
    HyperGrade.services.toggleHideAssignmentsInGradeCenter(assignmentToToggle);
  }

  assignmentHasPendingApprovals(assignmentID) {
    assignmentID = parseInt(assignmentID);

    for (const kv of Object.entries(this.state.gradingInfo.grades)) {
      let gradeObj = kv[1]; //get the value, don't need the key (username)
      for (const [assignmentIDKey, details] of Object.entries(gradeObj))
        if (parseInt(assignmentIDKey) === assignmentID && details.needsApproval)
          return true;
    }

    return false;
  }

  showColumnsWithPendingApprovals() {
    let assignmentToToggle = [];
    this.applyToAllAssignments(assignment => {
      let newValue = !this.assignmentHasPendingApprovals(assignment.id);
      if (newValue !== !!assignment.grade_column_hide) {
        assignmentToToggle.push(assignment.id);
        assignment.grade_column_hide = newValue;
      }
    });
    HyperGrade.services.toggleHideAssignmentsInGradeCenter(assignmentToToggle);
  }

  toggleCollapseColumn(assignmentID) {
    let updatedGradingInfo = _.cloneDeep(this.state.gradingInfo);
    let assignment = updatedGradingInfo.assignments.find(assignment => assignment.id === assignmentID);
    if (assignment) {
      assignment.grade_column_hide = !assignment.grade_column_hide;
      this.setState({gradingInfo: updatedGradingInfo});
      HyperGrade.services.toggleHideSingleAssignmentInGradeCenter(assignmentID);
    } else {
      console.log("HyperGrade: Cannot find that assignment");
    }
  }

  getGradesAsArray() {
    return this.state.gradingInfo.students.map(student => {

      let grades = this.state.gradingInfo.grades[student.username];

      let totalPointsEarned = 0;
      let totalCoursePointsOfAttemptedAssignments = 0;
      let totalCoursePoints = 0;

      Object.keys(grades).forEach(assignmentID => {
        if (grades[assignmentID].recommended_points) {
          totalPointsEarned += parseInt(grades[assignmentID].recommended_points);
          totalCoursePointsOfAttemptedAssignments += parseInt(grades[assignmentID].assignment_total_points);
        }
        totalCoursePoints += parseInt(grades[assignmentID].assignment_total_points);
      });

      return {
        ...student,
        lastname: student.lastname ? student.lastname.charAt(0).toUpperCase() + student.lastname.slice(1) : "Missing name",
        totalPointsEarned,
        totalCoursePointsOfAttemptedAssignments,
        totalCoursePoints,
        currentPercent: totalPointsEarned / totalCoursePointsOfAttemptedAssignments * 100,
        ifNothingElseIsDoneWhatIsMyCurrentGrade: totalPointsEarned / totalCoursePoints * 100,
        progressPercent: totalCoursePointsOfAttemptedAssignments / totalCoursePoints * 100,
        grades
      };
    });
  }

  approveAllConfirm(assignmentID) {
    this.setState({pendingApproveAllAssignmentID: assignmentID});
  }

  approveAll() {
    this.setState({pendingApproveAllAssignmentID: null});
    this.props.appServices.beginWorking();
    HyperGrade.services.approveAll(this.state.pendingApproveAllAssignmentID).then(() => {
      this.refreshGradeTable();
    });
  }

  findAssignmentName(assignmentID) {
    let result = this.state.gradingInfo.assignments.find(assignment => assignment.id === assignmentID);
    return result ? result.name : undefined;
  }

  render() {

    if (this.state.loading) {
      return <Loading/>;
    }

    //build up columns for table: student info
    let columns = [
      {
        property: 'username',
        header: <Box style={{display: "none"}}/>,
        primary: true,
        search: false,
        sort: false,
        render: (data) => <Box style={{display: "none"}}/>
      },
      {
        property: 'lastname',
        header: <Text>Name</Text>,
        search: false,
        sort: false,
        render: data => <Text><b>{data.lastname}</b>, {data.firstname}</Text>
      },
      {
        property: 'ifNothingElseIsDoneWhatIsMyCurrentGrade',
        header: <Paragraph style={{width: "100%"}} textAlign={"center"}>Approved Total</Paragraph>,
        render: data => {

          let approved = this.state.gradingInfo.approvedGrades.find(current => current.username === data.username);

          let content = "-";
          if (approved && approved.numAssignmentsApproved > 0) {
            content = approved.approvedPointsTotal + " / " + approved.approvedPointsMax
              + " (" + Math.round(approved.approvedPercent) + "%)";
          }
          return (<Text textAlign={"center"} style={{whiteSpace: "nowrap"}}>{content}</Text>);
        }
      },
      {
        property: 'key',
        header: <Box align="center">Action</Box>,
        sortable: false,
        search: false,
        render: (studentObj) => <StudentActionButton updateStudent={this.refreshGradeTable}
                                                     initialStudent={studentObj}
                                                     key={"action-" + studentObj.key}
                                                     appServices={this.props.appServices}

        />
      },
      {
        property: 'progressPercent',
        header: <Text>Course Completion</Text>,
        primary: true,
        render: data => (
          <Box pad={{vertical: 'xsmall'}} direction={"row"} gap={"small"} align={"center"}>
            <Meter
              values={[{value: data.progressPercent}]}
              thickness="small"
              size="small"
            />

            {data.progressPercent >= 100 &&

            <Box title={"Student has attempted all assignments"}>
              <Star/>
            </Box>
            }
          </Box>
        )
      },
    ];

    let hiddenAssignments = [];

    //build up columns for table: grades for each assignment
    this.state.gradingInfo.assignments.forEach(assignment => {
      if (assignment.grade_column_hide) {
        hiddenAssignments.push(assignment);
      } else {
        columns.push({
          property: '' + assignment.id,
          sortable: false,
          header: <Box className="hg-assignment-table-name" align="center">
            <Text className="hg-raw-text">{assignment.name}</Text>
            <Box align="center">
              <Button label="Approve"
                      onClick={this.approveAllConfirm.bind(this, assignment.id)}
                      title="Approve all recommended grades"/>
              <Button icon={<Hide/>}
                      onClick={this.toggleCollapseColumn.bind(this, assignment.id)}
                      title="Collapse this column"/>
            </Box>
          </Box>,
          render: data => {

            let result = [];

            let contentInGradingCell = [<Text size={"xxlarge"} key="noData">-</Text>];

            if (data.grades[assignment.id]) {

              if (data.grades[assignment.id].recommended_percentage != null) {
                contentInGradingCell = [data.grades[assignment.id].recommended_percentage];
              } else if (data.grades[assignment.id].needsApproval) {
                contentInGradingCell = ['No files'];
              }

              if (data.grades[assignment.id].needsApproval) {
                contentInGradingCell.push(
                  <Box margin={{horizontal: "xsmall"}} key={"approval" + data.key + assignment.id}>
                    <Button plain key="needsApproval" icon={<Alert/>}/>
                  </Box>
                );
              }
            }

            result.push(
              <Anchor href={"/grade/" + data.key + "/" + assignment.id}
                      key={"reco" + data.key + assignment.id}>
                <Box direction="row" align="center" justify={"center"}>
                  {contentInGradingCell}
                </Box>
              </Anchor>
            );


            return <Box direction="row" align="center" justify="center" animation={assignment.animation}>{result}</Box>
          }
        });
      }
    });

    return (
      <Box margin="medium" gap="medium">
        <MetaTags>
          <title>Grade Book for {this.state.gradingInfo.course.name} - HyperGrade</title>
          <meta name="description" content="View and approve all student grades."/>
        </MetaTags>
        <Breadcrumb crumbs={[
          {label: this.state.gradingInfo.course.name, href: "/course/" + this.props.courseID + "/assignment-editor"},
          {label: "Grades"}
        ]}/>
        <Box pad="medium" elevation="large">
          <Box direction="row" justify="between" align="center">
            <Box direction="row" gap="large" align="end">
              <Heading level="3" margin="none">{this.state.gradingInfo.course.name}</Heading>
              <Text
                color="dark-6">{this.state.gradingInfo.students.length + " student" + (this.state.gradingInfo.students.length !== 1 ? "s" : "")}</Text>
              <Text color="dark-6">Course code {this.state.gradingInfo.course.id}</Text>
            </Box>
            {/*right side button goes here*/}
          </Box>

          <Box margin="medium" gap="small" width="large">
            <Collapsible open={hiddenAssignments.length > 0}>
              <Button label={"Show all columns"} onClick={this.showAllColumns}/>
            </Collapsible>
            <Button label={"Show only columns with pending approvals"}
                    onClick={this.showColumnsWithPendingApprovals}/>
          </Box>

          <Collapsible open={hiddenAssignments.length > 0}>
            <Box margin={{horizontal: "medium", top: "small"}}>
              <Box direction="row" gap="small" wrap={true}>
                {
                  hiddenAssignments.map(assignment =>
                    <Box animation="fadeIn" key={"toggle-visibility-" + assignment.id}>
                      <Button label={assignment.name}
                              margin={{bottom: "small"}}
                              icon={<View/>}
                              onClick={this.toggleCollapseColumn.bind(this, assignment.id)}/>
                    </Box>
                  )
                }
              </Box>
            </Box>
          </Collapsible>

          <Box className="hg-grade-table-container">
            <DataTable sortable={true}
                       background={["white", "light-2"]}
                       columns={columns}
                       data={this.getGradesAsArray()}
                       primaryKey="username"
                       step={1000} //render 1000 rows at a time
                       pin={true} //doesn't work?
            />
          </Box>
          {
            this.state.pendingApproveAllAssignmentID &&
            <YesNoModal onClickYes={this.approveAll}
                        onSelectNo={this.approveAllConfirm.bind(this, false)}
                        bodyText={
                          <Paragraph textAlign="center">
                            This will apply all of the grades that are recommended by HyperGrade
                            to <b>{this.findAssignmentName(this.state.pendingApproveAllAssignmentID)}</b>
                            . Grades that have already been approved will not be changed.
                            Is this OK?
                          </Paragraph>}
            />
          }
          {
            this.state.showGradeDetail &&
            <ModalSkeleton onClose={this.hideGradeDetail}>
              GradeDetail Modal (not used)
              {/*<GradeDetail username={this.state.gradeDetailStudentUsername}*/}
              {/*             assignmentID={this.state.gradeDetailAssignmentID}/>*/}
            </ModalSkeleton>

          }
        </Box>
      </Box>
    )
  }
}

Grades.propTypes = {
  courseID: PropTypes.number.isRequired,
  appServices: Models.appServices.isRequired,
};

Grades.defaultProps = {};


export default Grades