//react
import React from "react"
import {Redirect} from "react-router-dom";
//grommet
import {Box, Grid, Heading, Paragraph} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';
//lodash
import PropTypes from "prop-types";
import Loading from "./Loading";
import Pluralize from "react-pluralize";
import AssignmentProgressCard from "./AssignmentProgressCard";
import Moment from "moment";
import {StatusUnknown, Validate} from "grommet-icons";
import TooltipButton from "./TooltipButton";

class Course extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.test = this.test.bind(this);
    this.useLateDays = this.useLateDays.bind(this);
    this.updateTimes = this.updateTimes.bind(this);
    this.refresh = this.refresh.bind(this);
    this.noAssignments = this.noAssignments.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    return HyperGrade.services.getMyAssignments(this.props.enrolledCourse.id).then(res => {
      this.updateTimes();
      this.setState({assignments: res.data.assignments, loading: false});
    });
  }

  test() {
    //let secondsInADay = 24 * 60 * 60;
    //TODO: seconds until next day
    //let secondsUntilNextDay = secondsInADay - (new Moment().unix()) % secondsInADay;
    setTimeout(this.test, 1000);
  }

  updateTimes() {

    let numLateDays = parseInt(this.props.enrolledCourse.late_days);
    if (isNaN(numLateDays)) {
      numLateDays = 0;
    }

    let point1 = new Moment().subtract(numLateDays, "days");
    let point2 = new Moment().add(10, "days");
    this.setState({point1, point2});
  }

  useLateDays(assignmentID, howMany) {
    this.props.useLateDays(assignmentID, howMany).then(res => {
      this.refresh().then(res => {
        HyperGrade.util.scrollToTop();
      });
    });
  }

  noAssignments() {
    return this.state.assignments.length === 0;
  }

  render() {

    if (this.state.loading) {
      return <Loading/>
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    let pastAssignments = this.state.assignments.filter(assignment => assignment.myDueDateMoment.isBefore(this.state.point1));
    let upcomingAssignments = this.state.assignments.filter(assignment => assignment.myDueDateMoment.isBetween(this.state.point1, this.state.point2));
    let futureAssignments = this.state.assignments.filter(assignment => assignment.myDueDateMoment.isAfter(this.state.point2));

    return (
      <Box margin="medium" gap="medium">
        <Box align="center" gap="medium">
          <Box>
            <Heading textAlign="center" level="2" margin="none">{this.props.enrolledCourse.name}</Heading>
            <Paragraph textAlign="center" margin="none" color="dark-6" size="small">Course
              code {this.props.enrolledCourse.id}</Paragraph>
          </Box>

          {!this.noAssignments() &&
          <Box>
            <TooltipButton text="One late day will add 24 hours to a due date.">
              <Paragraph margin="none">You have <strong><Pluralize singular={'late day'}
                                                                   count={this.props.enrolledCourse.late_days}/></strong> remaining <sup><StatusUnknown
                size="small"/></sup></Paragraph>
            </TooltipButton>

          </Box>
          }
          {this.noAssignments() &&
          <Box align="center" gap="medium">
            <Box animation="pulse">
              <Validate size="large" color="status-ok"/>
            </Box>
            <Heading level="4" textAlign="center" margin="none">You have successfully joined the course, but no
              assignments are
              available yet!</Heading>
          </Box>
          }
        </Box>

        {upcomingAssignments.length > 0 &&
        <Box>
          <Grid gap="small" columns="medium">
            {upcomingAssignments.map(assignment =>
              <AssignmentProgressCard key={"ua" + assignment.id}
                                      useLateDays={this.useLateDays.bind(this, assignment.id)}
                                      lateDaysRemaining={this.props.enrolledCourse.late_days}
                                      assignment={assignment}/>
            )}
          </Grid>
        </Box>
        }

        {futureAssignments.length > 0 &&
        <Box>
          <Heading level="3">Future Assignments</Heading>
          <Grid gap="small" columns="medium">
            {futureAssignments.map(assignment =>
              <AssignmentProgressCard key={"fa" + assignment.id}
                                      useLateDays={this.useLateDays.bind(this, assignment.id)}
                                      lateDaysRemaining={this.props.enrolledCourse.late_days}
                                      assignment={assignment}/>
            )}
          </Grid>
        </Box>
        }

        {pastAssignments.length > 0 &&
        <Box>
          <Heading level="3">Past Assignments</Heading>
          <Grid gap="small" columns="medium">
            {pastAssignments.map(assignment =>
              <AssignmentProgressCard key={"pa" + assignment.id}
                                      useLateDays={this.useLateDays.bind(this, assignment.id)}
                                      lateDaysRemaining={this.props.enrolledCourse.late_days}
                                      assignment={assignment}/>
            )}
          </Grid>
        </Box>
        }
      </Box>
    )
  }
}

Course.propTypes = {
  enrolledCourse: PropTypes.object,
  useLateDays: PropTypes.func
};

Course.defaultProps = {};

export default Course