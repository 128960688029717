import React from "react";
import Moment from "moment";

class LastActiveTime extends React.Component {

  render() {

    let lastActivityTimeMoment = new Moment(this.props.student.last_activity_time);

    //make a easier to read label
    let now = new Moment();
    let dayDiff = now.diff(lastActivityTimeMoment, 'days');

    let otherLabel = "";
    if (dayDiff > 3) {
      otherLabel = " (" + dayDiff + " days ago)";
    }

    return (
      <span>{lastActivityTimeMoment.format('lll') + otherLabel}</span>
    );
  }
}

export default LastActiveTime