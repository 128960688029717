//react
import React from "react"
//grommet
import {Box, Button, Collapsible, defaultProps, Heading, Menu, Select} from "grommet";
import {Clone, Down, Edit, More, Trash, Up, View} from 'grommet-icons';
//moment
//hypergrade
import HyperGrade from '../hg-config';
import HGDateTime from "./HGDateTime";
import TextQuestionModal from "./TextQuestionModal";
import QuestionSummary from "./QuestionSummary";
import ModalSkeleton from "./ModalSkeleton";
import YesNoModal from "./YesNoModal";
import Loading from "./Loading";
import {Redirect} from "react-router-dom";
import Moment from "moment";
import Models from "../models/appServices";

class AssignmentEditCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dateTime: this.props.assignment.due_date === '0000-00-00 00:00:00' ? null : this.props.assignment.due_date,
      assignmentName: this.props.assignment.name, //used when user changes assignment name
      open: false,
      showNameEditIcon: false,
      showAssignmentNameModal: false,
      openQuestionSummaryPanel: false,
      questionList: null,
      showAssignmentCopyModal: false,
      duplicateLoading: false,
      hasBeenDeleted: false
    };

    if (Array.isArray(props.allCourses) && props.allCourses.length > 0) {

      //simplify and sort
      this.state.allCoursesSimplified = props.allCourses
        .map(course => {
          return {
            id: course.id,
            name: course.name,
            compoundName: course.name + " (" + course.id + ")" + (props.course.id === course.id ? " [This course]" : "")
          }
        })
        .sort((first, second) => second.id - first.id); //descending with current course on top

      //get the current course
      let currentCourse = this.state.allCoursesSimplified.find(current => current.id === props.course.id);
      //remove the current course
      this.state.allCoursesSimplified = this.state.allCoursesSimplified.filter(current => current.id !== props.course.id);
      //put the current course at the top of the list
      this.state.allCoursesSimplified.unshift(currentCourse);
      //select that course by default
      this.state.selectedCourseToCopy = this.state.allCoursesSimplified[0];
    }

    this.setOpen = this.setOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.showNameEditIcon = this.showNameEditIcon.bind(this);
    this.hideNameEditIcon = this.hideNameEditIcon.bind(this);
    this.showAssignmentNameModal = this.showAssignmentNameModal.bind(this);
    this.hideAssignmentNameModal = this.hideAssignmentNameModal.bind(this);
    this.assignmentNameSubmit = this.assignmentNameSubmit.bind(this);
    this.openQuestionSummaryPanel = this.openQuestionSummaryPanel.bind(this);
    this.openAssignmentCopyModal = this.openAssignmentCopyModal.bind(this);
    this.performCopy = this.performCopy.bind(this);
    this.confirmDeleteSelf = this.confirmDeleteSelf.bind(this);
    this.deleteSelf = this.deleteSelf.bind(this);
  }

  componentDidMount() {

    if (this.props.openImmediately) {
      this.openQuestionSummaryPanel();
    }
  }


  openQuestionSummaryPanel() {
    HyperGrade.services.getAssignmentAndQuestions(this.props.assignment.id)
      .then(response => {
        if (response.data.question.length > 0) {
          this.setState({questionList: response.data})
          this.setState({openQuestionSummaryPanel: true});
        } else {
          HyperGrade.services.createQuestion(this.props.assignment.id).then(server => {
            this.setState({redirect: "/question/" + server.data + "/edit"});
          });
        }
      });
  }

  setOpen(open) {
    this.setState({open});
  }

  onClose() {
    this.setOpen(false);
  }

  onSave(updatedMoment) {
    if (updatedMoment) {
      HyperGrade.services.changeDueDate(this.props.assignment.id, updatedMoment);
      this.setState({dateTime: updatedMoment.format(HyperGrade.momentFormat)});
    } else {
      HyperGrade.services.clearDueDate(this.props.assignment.id);
      this.setState({dateTime: null});
    }
    this.props.appServices.showSuccessNotification("Due date has been saved");
    this.onClose();
  }

  showNameEditIcon() {
    this.setState({showNameEditIcon: true});
  }

  hideNameEditIcon() {
    this.setState({showNameEditIcon: false});
  }

  showAssignmentNameModal() {
    this.setState({showAssignmentNameModal: true});
  }

  hideAssignmentNameModal() {
    this.setState({showAssignmentNameModal: false});
  }

  assignmentNameSubmit(newName) {
    this.hideAssignmentNameModal();
    this.setState({assignmentName: newName});
    HyperGrade.services.changeAssignmentName(this.props.assignment.id, newName);
  }

  openAssignmentCopyModal() {
    this.setState({showAssignmentCopyModal: true});
  }

  performCopy() {
    this.setState({duplicateLoading: true});
    HyperGrade.services.copyAssignment(this.props.assignment.id, this.state.selectedCourseToCopy.id).then(result => {
      this.setState({showAssignmentCopyModal: false, duplicateLoading: false});

      if (this.props.course.id === this.state.selectedCourseToCopy.id) {
        this.props.insertNewAssignment(result.data, this.props.index + 1);
      } else {
        this.props.appServices.showSuccessNotification("The assignment has been copied to that course!");
      }
    });
  }

  confirmDeleteSelf(showDeleteSelfModal) {
    this.setState({showDeleteSelfModal});
  }

  deleteSelf() {
    //close the modal
    this.confirmDeleteSelf(false);
    //tell the server
    HyperGrade.services.deleteAssignment(this.props.assignment.id);
    //do an animation
    this.setState({hasBeenDeleted: true});
    //drop self from the assignment list after collapse
    setTimeout(this.props.deleteSelf, HyperGrade.util.css_time_to_milliseconds(defaultProps.theme.global.animation.duration))
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    return (
      <Collapsible open={!this.state.hasBeenDeleted}>
        <Box>
          {/*onclick must be specified for hoverIndicator to work*/}
          <Box direction="row" align="center" pad={{horizontal: "medium"}} hoverIndicator={"light-3"} onClick={() => {
          }}
               background={this.state.open ? "light-5" : undefined}>
            {/*assignment title*/}
            <Box animation={["fadeIn", "slideLeft"]}
                 basis="1/3" direction="row" align="center" onPointerEnter={this.showNameEditIcon}
                 title={"Edit assignment name"}
                 onPointerLeave={this.hideNameEditIcon} onClick={this.showAssignmentNameModal}>
              <Heading level="4" margin="none">{this.state.assignmentName}</Heading>
              {this.state.showNameEditIcon && <Button icon={<Edit size="small"/>}/>}
            </Box>

            {/*assignment due date changer*/}
            <Box basis={"1/3"} align="end">
              <HGDateTime
                initialMoment={this.state.dateTime ? new Moment(this.state.dateTime) : null}
                assignmentName={this.state.assignmentName}
                onSave={this.onSave}
                onClear={this.onSave.bind(this, null)}
              />
            </Box>

            {/*button panel*/}
            <Box direction="row" basis={"1/3"} justify="end">
              {/*reordering panel*/}
              <Box direction="row" margin={{right: "xlarge"}}>
                <Box style={this.props.isFirst ? {visibility: 'hidden'} : undefined}>
                  <Button icon={<Up/>} title="Reorder: Move up"
                          onClick={this.props.isFirst ? undefined : this.props.decrementPosition}/>
                </Box>
                <Box style={this.props.isLast ? {visibility: 'hidden'} : undefined}>
                  <Button icon={<Down/>} title="Reorder: Move down"
                          onClick={this.props.isLast ? undefined : this.props.incrementPosition}/>
                </Box>
              </Box>

              <Button icon={<View/>} title="View assignment in new tab" href={"/assignment/" + this.props.assignment.id}
                      target="_blank"/>
              <Button icon={<Edit/>} title="Edit assignment"
                      onClick={this.openQuestionSummaryPanel}/>
              <Button icon={<Clone/>} title="Duplicate assignment"
                      onClick={this.openAssignmentCopyModal}/>
              <Menu
                icon={<More/>}
                dropProps={{align: {top: "bottom", left: "left"}}}
                items={[
                  {
                    icon: <Box margin="small"><Trash/></Box>,
                    label: <Box alignSelf="center" margin={{right: "small"}}>Delete assignment</Box>,
                    onClick: this.confirmDeleteSelf.bind(this, true)
                  }
                ]}
              />
            </Box>
          </Box>

          {/*list of questions*/}
          {this.state.openQuestionSummaryPanel &&
            <ModalSkeleton onClose={() => this.setState({openQuestionSummaryPanel: false})} width="xxlarge" pad={"medium"}>
              <Box pad="small">
                {this.state.questionList ? <QuestionSummary assignment={this.state.questionList.assignment}
                                                            appServices={this.props.appServices}
                                                            questions={this.state.questionList.question}/> :
                  <Loading/>}
              </Box>
            </ModalSkeleton>
          }

          {this.state.showAssignmentNameModal &&
            <TextQuestionModal onClose={this.hideAssignmentNameModal}
                               onSubmit={this.assignmentNameSubmit}
                               startingValue={this.state.assignmentName}
                               instructions={"Enter the new name"}/>
          }
          {this.state.showAssignmentCopyModal &&
            <ModalSkeleton instructions={"Copy to which course?"}
                           onClose={() => this.setState({showAssignmentCopyModal: false})}>
              <Select margin={{bottom: "medium"}}
                      options={this.state.allCoursesSimplified}
                      labelKey="compoundName"
                      value={this.state.selectedCourseToCopy}
                      valueKey="id"
                      onChange={({option}) => this.setState({selectedCourseToCopy: option})}
                      placeholder={"Choose a course"}
              />
              <Box direction="row" justify="center" align="center" gap="small">
                <Box basis="1/4">
                  <Button primary disabled={this.state.duplicateLoading}
                          label={this.state.duplicateLoading ? "Working..." : "Copy"} onClick={this.performCopy}/>
                </Box>
                <Box basis="1/4">
                  <Button label="Cancel" disabled={this.state.duplicateLoading}
                          onClick={() => this.setState({showAssignmentCopyModal: false})}/>
                </Box>
              </Box>
            </ModalSkeleton>
          }
          {this.state.showDeleteSelfModal &&
            <YesNoModal instructions="Are you sure you want to delete this assignment?"
                        onClickYes={this.deleteSelf}
                        onSelectNo={this.confirmDeleteSelf.bind(this, false)}/>
          }
        </Box>
      </Collapsible>
    )
  }
}

AssignmentEditCard.propTypes = {
  appServices: Models.appServices.isRequired,
};

export default AssignmentEditCard