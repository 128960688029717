//react
import React from "react"

//grommet
import {Redirect} from "react-router-dom";
import Models from "../models/appServices";
import Loading from "./Loading";

//hypergrade

class LoadingPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.appServices.refreshUserData().then(() => {
      //window.location.reload();
      this.setState({redirect: '/home'});
    });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    return <Loading />;
  }
}

LoadingPage.propTypes = {
  appServices: Models.appServices.isRequired,
};

LoadingPage.defaultProps = {};

export default LoadingPage