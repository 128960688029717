//react
import React from 'react';
import PropTypes from "prop-types";
//grommet
import {Box, Button, Drop, Text} from "grommet";

class TooltipButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      over: false,
    }

    this.setOver = this.setOver.bind(this);

    this.myRef = React.createRef();
  }

  setOver(over) {
    this.setState({over});
  }

  render() {
    return (
      <Box>
        <Button ref={this.myRef}
                onMouseEnter={() => this.setOver(true)}
                onMouseLeave={() => this.setOver(false)}
                onFocus={() => this.setOver(true)}
                onBlur={() => this.setOver(false)}
                onClick={this.props.onClick}
                icon={this.props.icon}
                plain>
          {this.props.children}
        </Button>
        {this.state.over && (
          <Drop
            align={{bottom: 'top'}}
            target={this.myRef.current}
            plain
            // trapFocus set to false allows tabbing through
            trapFocus={false}
          >
            <Box pad="small" background="light-3" border={{color: "dark-3"}} margin="xsmall" elevation={"small"} round>
              <Text>{this.props.text}</Text>
            </Box>
          </Drop>
        )}
      </Box>
    );
  }
}

TooltipButton.propTypes = {
  text: PropTypes.string.isRequired, //helper text
  onClick: PropTypes.func,
  icon: PropTypes.element
};

TooltipButton.defaultProps = {
  onClick: null
};

export default TooltipButton;