//react
import React from "react"
//grommet
import {Box} from "grommet";

//hypergrade

class TrainingMessage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <Box background="neutral-3" round pad={{horizontal: "medium"}} alignSelf="center">
        {this.props.children}
      </Box>
    )
  }
}

TrainingMessage.propTypes = {

};

TrainingMessage.defaultProps = {

};

export default TrainingMessage