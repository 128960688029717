//react
import React from "react"
//grommet
import {Box, Button} from "grommet";
import {LinkDown, LinkUp} from 'grommet-icons';
//lodash
import PropTypes from "prop-types";

class Reorder extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.isFirst = this.isFirst.bind(this);
    this.isLast = this.isLast.bind(this);
  }

  isFirst() {
    return this.props.index === 1;
  }

  isLast() {
    return this.props.index === this.props.max;
  }

  render() {
    return (
      <Box direction="row">
        <Box style={this.isFirst() ? {visibility: 'hidden'} : undefined}>
          <Button icon={<LinkUp/>} title="Reorder: Move up"
                  onClick={this.isFirst() ? undefined : this.props.onMoveUp}/>
        </Box>
        <Box style={this.isLast() ? {visibility: 'hidden'} : undefined}>
          <Button icon={<LinkDown/>} title="Reorder: Move down"
                  onClick={this.isLast() ? undefined : this.props.onMoveDown}/>
        </Box>
      </Box>
    )
  }
}

Reorder.propTypes = {
  index: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
};

Reorder.defaultProps = {

};

export default Reorder