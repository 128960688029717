//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Button, Form, FormField, TextInput} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';
import Login from "./Login";

class PasswordChangeForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      password: '',
      password2: ''
    };

    this.validatePassword = HyperGrade.validation.validatePassword.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.currentPasswordRequired = () => !this.props.pin;
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount() {

  }

  clearForm() {
    this.setState({
      currentPasswordError: null,
      passwordError: null,
      password2Error: null,
      currentPassword: '',
      password: '',
      password2: ''
    });
  }

  submitPassword(event) {
    let problem = false;

    this.setState({currentPasswordError: null, passwordError: null, password2Error: null});

    if (this.currentPasswordRequired() && !this.state.currentPassword) {
      this.setState({currentPasswordError: "Current password is required"});
      problem = true;
    }

    problem |= this.validatePassword();

    if (!problem) {

      let setupErrors = (data) => {
        let err = {};

        for (let [key, value] of Object.entries(data)) err[key + "Error"] = value;

        this.setState({...err});
      };

      this.props.beginWorking();
      //if we're resetting the password using our current password (e.g. user settings)
      if (this.currentPasswordRequired()) {

        HyperGrade.services.updatePassword(this.state.currentPassword, this.state.password).then(server => {
          this.props.endWorking();
          if (server.success) {
            this.clearForm();
            if (this.props.successCallback) {
              this.props.successCallback();
            }
          } else {
            setupErrors(server.data);
          }
        });

        //if we're resetting the password using a pin (password reset)
      } else if (this.props.pin) {

        HyperGrade.services.updatePasswordWithPin(this.props.pin, this.state.password).then(res => {
          this.props.endWorking();
          if (res.success) {
            let username = res.data;
            this.setState({loginUsername: username, loginPassword: this.state.password});
          } else {
            setupErrors(res.data);
          }
        });

      } else {
        this.props.endWorking();
        this.props.showErrorNotification("Current password or pin is required");
      }
    }
  }

  render() {

    if (this.state.loginUsername && this.state.loginPassword) {
      return <Login {...this.props} username={this.state.loginUsername} password={this.state.loginPassword}/>
    }

    return (
      <Form onSubmit={(event) => this.submitPassword(event)}>

        {this.currentPasswordRequired() &&
        <FormField label="Current password" name="currentPassword"
                   error={this.state.currentPasswordError}>
          <TextInput value={this.state.currentPassword} type="password"
                     onChange={event => this.setState({currentPassword: event.target.value})}/>
        </FormField>
        }

        <FormField label="New password" name="password"
                   error={this.state.passwordError}>
          <TextInput value={this.state.password} type="password"
                     onChange={event => this.setState({password: event.target.value})}/>
        </FormField>

        <FormField label="Repeat new password" name="password2"
                   error={this.state.password2Error}>
          <TextInput value={this.state.password2} type="password"
                     onChange={event => this.setState({password2: event.target.value})}/>
        </FormField>

        <Button margin={{vertical: "small"}} primary label="Update Password" type="submit"/>
      </Form>
    )
  }
}

PasswordChangeForm.propTypes = {
  pin: PropTypes.string,
  beginWorking: PropTypes.func.isRequired,
  endWorking: PropTypes.func.isRequired,
  showSuccessNotification: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  successCallback: PropTypes.func
};

PasswordChangeForm.defaultProps = {};

export default PasswordChangeForm