//react
import React from "react"
//grommet
import {Box, Button, Heading, Paragraph, Text} from "grommet";
import {Add, Book, Group} from 'grommet-icons';
//lodash
import _ from 'lodash';
//hypergrade
import HyperGrade from '../hg-config';
import AssignmentEditCard from "./AssignmentEditCard";
import PropTypes from "prop-types";
import Loading from "./Loading";
import Breadcrumb from "./Breadcrumb";
import Models from "../models/appServices";
import TrainingMessage from "./TrainingMessage";
import * as moment from "moment";
import NotFound from "./NotFound";

class AssignmentEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.insertNewAssignment = this.insertNewAssignment.bind(this);
    this.tellServerAboutNewOrdering = this.tellServerAboutNewOrdering.bind(this);

    this.changePosition = this.changePosition.bind(this);
    this.incrementPosition = this.incrementPosition.bind(this);
    this.decrementPosition = this.decrementPosition.bind(this);
    this.createAssignment = this.createAssignment.bind(this);
    this.assignmentsExist = this.assignmentsExist.bind(this);
  }

  componentDidMount() {
    //get all my courses
    //this page needs to know about all courses because the user can transfer assignments to another course
    HyperGrade.services.getMyCourses().then(myCoursesResponse => {
      //extract all the courses
      let allCourses = myCoursesResponse.data;
      //find the course to display
      let courseForThisPage = allCourses.find(course => course.id === this.props.courseID)
      //save this data
      this.setState({course: courseForThisPage, allCourses});
      //if the found the course (most likely always but course might get deleted and page refreshed
      if (courseForThisPage) {
        HyperGrade.services.getAssignments(this.props.courseID).then(assignmentsResponse => {
          this.setState({
            assignments: assignmentsResponse.data,
            loading: false
          });
        }).catch(err => null);
      } else {
        this.setState({loading: false});
      }
    });
  }

  assignmentsExist() {
    return this.state.assignments && this.state.assignments.length > 0;
  }

  createAssignment() {
    this.props.appServices.beginWorking("Creating the assignment");
    HyperGrade.services.createAssignment(this.props.courseID).then(server => {

      let newAssignment = server.data;
      let assignments = _.cloneDeep(this.state.assignments);

      //unshift add elements to the beginning
      if (Array.isArray(assignments) && assignments.length > 0) {
        assignments.unshift(newAssignment);
      } else {
        assignments = [newAssignment];
      }

      this.setState({assignments}, () => {

        //if we're in training mode, then automatically set the due date
        if (this.props.account.training) {
          let dueDate = moment().add(7, 'd');
          HyperGrade.services.changeDueDate(newAssignment.id, dueDate);
        }
      });

      this.props.appServices.endWorking();
    });
  }

  changePosition(which, howMuch) {
    let assignments = _.cloneDeep(this.state.assignments);
    HyperGrade.util.swap(assignments, which, which + howMuch);
    this.setState({assignments});
    //tell server
    this.tellServerAboutNewOrdering(assignments);
  }

  incrementPosition(which) {
    this.changePosition(which, +1);
  }

  decrementPosition(which) {
    this.changePosition(which, -1);
  }

  deleteAssignment(where) {
    let assignments = _.cloneDeep(this.state.assignments);
    if (where >= 0 && where < assignments.length) {
      assignments.splice(where, 1);
      this.setState({assignments});
    } else {
      throw Error("HyperGrade: Cannot delete at position " + where);
    }
  }

  insertNewAssignment(assignmentObj, where = -1) {
    //copy the existing assignments
    let assignments = _.cloneDeep(this.state.assignments);
    if (where >= 0) {
      //insert the new assignment
      assignments.splice(where, 0, assignmentObj);
    } else {
      assignments.push(assignmentObj);
    }
    //tell react about the change
    this.setState({assignments});
    //tell server
    this.tellServerAboutNewOrdering(assignments);
  }

  tellServerAboutNewOrdering(assignments) {
    HyperGrade.services.rearrangeAssignments(assignments.map(current => current.id));
  }

  render() {

    if (this.state.loading) {
      return <Loading/>;
    }

    let courseBox = null;
    if (this.state.course) {
      courseBox =
        <Box margin="medium" gap="medium">
          <Breadcrumb
            crumbs={[
              {label: this.state.course.name}
            ]}
          />
          {this.props.account.training &&
          <Box alignSelf="center">
            <TrainingMessage>
              {this.assignmentsExist() &&
              <Paragraph>Here is your course with a few sample assignments. Try editing an assignment.</Paragraph>
              }
              {!this.assignmentsExist() &&
              <Paragraph>Here is your course. Begin by creating and assignment.</Paragraph>
              }
            </TrainingMessage>
          </Box>
          }
          <Box pad="medium" elevation="xlarge" gap="small">
            {/*course title and options*/}
            <Box direction="row" justify="between" align="center">
              <Box direction="row" gap="large" align="center">
                <Heading level="3" margin="none">{this.state.course.name}</Heading>
                {/*students and course code*/}
                <Box direction="row" align="center" gap="small">
                  {/*student count*/}
                  <Button hoverIndicator="light-1" href={"/course/" + this.state.course.id + "/students"}>
                    <Box pad="small" direction="row" align="center" gap="small">
                      <Group/>
                      <Text>{this.state.course.studentCount} student{this.state.course.studentCount === 1 ? '' : 's'} enrolled</Text>
                    </Box>
                  </Button>
                  {/*student grades*/}
                  <Button hoverIndicator="light-1" href={"/course/" + this.state.course.id + "/grades"}>
                    <Box pad="small" direction="row" align="center" gap="small">
                      <Book/>
                      <Text>Grades</Text>
                    </Box>
                  </Button>
                  <Text>Course code <strong>{this.state.course.id}</strong></Text>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                {/*
                we only show this button if assignments already exist
                if assignment don't exist, then we have "Add sample assignments" and "Create assignment" appearing
              */}
                {this.assignmentsExist() &&
                <Button label={"Create assignment"} icon={<Add/>} onClick={this.createAssignment}/>
                }

              </Box>
            </Box>

            {/*assignment list*/}
            <Box pad={{vertical: "small"}} background="light-1" round="small">
              {this.state.assignments && this.state.assignments.length ?
                this.state.assignments.map((current, index, arr) =>
                  <AssignmentEditCard
                    allCourses={this.state.allCourses}
                    appServices={this.props.appServices}
                    course={this.state.course}
                    assignment={current}
                    isFirst={index === 0}
                    isLast={index === arr.length - 1}
                    incrementPosition={this.incrementPosition.bind(this, index)}
                    decrementPosition={this.decrementPosition.bind(this, index)}
                    key={"assignment-id-" + current.id}
                    index={index}
                    insertNewAssignment={this.insertNewAssignment}
                    deleteSelf={this.deleteAssignment.bind(this, index)}
                    openImmediately={current.id === this.props.assignmentID}
                  />)
                :
                <Box pad={{horizontal: "medium", vertical: "small"}} align="center" gap="small">
                  <Paragraph margin="none">There are no assignments in this course.</Paragraph>
                  <Box direction="row" gap="medium">
                    {/*<Button label="Add sample assignments"/>*/}
                    <Button label="Create assignment" onClick={this.createAssignment}/>
                  </Box>
                </Box>
              }
            </Box>
          </Box>
        </Box>
    } else {
      courseBox = <NotFound message="That course was not found!"/>;
    }

    return courseBox;
  }
}

AssignmentEditor.propTypes = {
  account: Models.account.isRequired,
  appServices: Models.appServices.isRequired,
  courseID: PropTypes.number.isRequired
};

AssignmentEditor.defaultProps = {};

export default AssignmentEditor