//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Button, Form, FormField} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';

import Suggest from "./Suggest";
import Models from "../models/appServices";

class ChangeEmail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: this.props.account.username
    };

    this.validateEmail = HyperGrade.validation.validateEmail.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
  }

  componentDidMount() {
  }

  submitEmail() {
    let problem = this.validateEmail();
    if (!problem) {
      this.props.appServices.saveEmail(this.state.email);
    }
  }

  render() {
    return (
      <Box gap="small">
        <Suggest message={"If you change your email address, use it to log in next time."}/>
        <Box width="large">
          <Form onSubmit={this.submitEmail}>
            <FormField placeholder="Email" name="email" type="email" error={this.state.emailError}
                       value={this.state.email}
                       onChange={event => this.setState({email: event.target.value})}
            />
            <Button margin={{vertical: "small"}}
                    primary label={this.props.submitLabel} type="submit"
                    disabled={this.props.account.username === this.state.email}/>
          </Form>
        </Box>
      </Box>
    )
  }
}

ChangeEmail.propTypes = {
  account: Models.account.isRequired,
  appServices: Models.appServices.isRequired,
  submitLabel: PropTypes.string,
};

ChangeEmail.defaultProps = {
  submitLabel: "Update Email"
};

export default ChangeEmail