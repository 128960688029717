//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Button, Form, FormField, Heading, Text, TextInput} from "grommet";
import ModalSkeleton from "./ModalSkeleton";
import HyperGrade from "../hg-config";

//hypergrade

class AssignmentPasswordModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.processPassword = this.processPassword.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
  }

  processPassword(form) {
    HyperGrade.services.submitCode(this.props.assignmentID, form.assignmentPassword).then(server => {
      this.setState({incorrectAssignmentPassword: !server.data.correct});
      if (server.data.correct) {
        this.onSuccess('/question/' + server.data.firstQuestionID + '/submit');
      }
    });
  }

  onSuccess(route) {
    this.props.onSuccess(route);
  }

  render() {
    return (
      <ModalSkeleton onClose={this.props.onClose}>
        <Box align={"center"}>
          <Heading level={"3"}>Enter the password for this assignment</Heading>
          <Box width={"medium"}>
            <Form onSubmit={({value}) => this.processPassword(value)} autoComplete={"off"}>
              <FormField name="assignmentPassword" htmlFor="assignmentPassword" required={true}>
                <TextInput type="password" id="assignmentPassword" name="assignmentPassword"
                           placeholder={"Password"}/>
              </FormField>
              <Box direction="row" gap="medium" align={"center"}>
                <Button type="submit" primary label="Submit"/>
                {this.state.incorrectAssignmentPassword &&
                <Text color={"status-error"}>The password is incorrect.</Text>}
              </Box>
            </Form>
          </Box>
        </Box>
      </ModalSkeleton>
    )
  }
}

AssignmentPasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  assignmentID: PropTypes.any.isRequired
};

AssignmentPasswordModal.defaultProps = {};

export default AssignmentPasswordModal