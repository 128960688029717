//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Text} from "grommet";
import TooltipButton from "./TooltipButton";
import {Cli} from "grommet-icons";

//hypergrade

class CommandLineArguments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }


  render() {

    if (!this.props.command_line_arguments)
      return null;

    return (
      <Box direction={"row"} gap={"small"} align={"center"}>
        <TooltipButton text="This input is given to your program via the command line">
          <Cli/>
        </TooltipButton>
        <Text weight={"bold"}>Command Line arguments: </Text>
        <Box background={"dark-1"} pad={{vertical: "xsmall", horizontal: "small"}} round>
          <Text color={"light-1"} className={"character-by-character"}>
            {this.props.command_line_arguments}
          </Text>
        </Box>
      </Box>
    )
  }
}

CommandLineArguments.propTypes = {
  command_line_arguments: PropTypes.string,
};

CommandLineArguments.defaultProps = {};

export default CommandLineArguments