//react
import React from "react"
//grommet
import {Box, Button, CheckBox, Collapsible, defaultProps, DropButton, Form, FormField, Heading} from "grommet";
import {Clone, Down, Edit, Trash, Up} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import YesNoModal from "./YesNoModal";
import PropTypes from "prop-types";
import Models from "../models/appServices";
import GrommetDraftJS from "./GrommetDraftJS";

class SingleQuestionEditorBasic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      extraCreditChecked: props.question.extracredit === 1,
      callingExtraCreditService: false,
      openEditPoints: false,
      pointValue: props.question.points,
      tentPointValue: props.question.points,
      pointValueErr: null,
      savingPointValue: false,
      openAreYouSure: false,
      beingDeleted: false,
      hasBeenDeleted: false,
      doNotAutomaticallyGrade: props.question.type !== "programming"
    };

    this.setExtraCredit = this.setExtraCredit.bind(this);
    this.openEditPoints = this.openEditPoints.bind(this);
    this.onPointFormSubmit = this.onPointFormSubmit.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.toggleAutoGrade = this.toggleAutoGrade.bind(this);
  }

  openAreYouSureModal(openAreYouSure) {
    this.setState({openAreYouSure});
  }

  deleteQuestion() {
    //close the confirmation modal
    this.openAreYouSureModal(false);
    //will begin a fadeOut
    this.setState({beingDeleted: true});

    //tell the server to delete the question
    HyperGrade.services.deleteQuestion(this.props.question.id);

    //wait for fadeout
    setTimeout(() => {
      this.setState({beingDeleted: false, hasBeenDeleted: true}, () => {
        //wait for collapse
        setTimeout(() => {
          //don't display this question any more
          this.props.hideQuestion(this.props.question.id);
        }, HyperGrade.util.css_time_to_milliseconds(defaultProps.theme.global.animation.duration) / 3);
      });
    }, HyperGrade.util.css_time_to_milliseconds(defaultProps.theme.global.animation.duration))
  }

  openEditPoints(v) {
    this.setState({openEditPoints: v});
  }

  setExtraCredit(checked) {
    this.setState({extraCreditChecked: !this.state.extraCreditChecked, callingExtraCreditService: true});
    HyperGrade.services.toggleExtraCredit(this.props.question.id)
      .then(res => this.setState({callingExtraCreditService: false}));
  }

  onPointFormSubmit(value) {
    //close the dropdown
    this.openEditPoints(false);
    //preserve the original in the off chance that the server rejects the request
    let original = this.state.pointValue;
    //update what the user sees
    this.setState({pointValue: value, tentPointValue: value, pointValueErr: null, savingPointValue: true});
    //tell the server
    HyperGrade.services.setPointValue(this.props.question.id, value)
      .then(result => {
        this.setState({savingPointValue: false});
      })
      .catch(err => { //this should rarely happen -- only when client browser ignores form validation recommendations
        this.openEditPoints(true);
        //revert to where we were, but with an error message
        this.setState({
          pointValue: original,
          tentPointValue: original,
          pointValueErr: err.msg,
        });
      });
  }

  toggleAutoGrade() {
    this.setState({doNotAutomaticallyGrade: !this.state.doNotAutomaticallyGrade});
    HyperGrade.services.toggleNoAutoGrade(this.props.question.id);
  }

  render() {

    let ani = undefined;
    if (this.state.openAreYouSure) {
      ani = "pulse";
    } else if (this.state.beingDeleted || this.state.hasBeenDeleted) {
      ani = ["fadeOut"];
    }

    return (
      <Collapsible open={!this.state.hasBeenDeleted}>
        <Box animation={ani} background="light-2" pad={{horizontal: "medium", top: "small", bottom: "medium"}}>
          <Box direction="row" gap="large" align="center" justify="between">
            <Heading margin="none" level={4}>Question {this.props.index + 1}</Heading>
            <Box>
              <DropButton
                label={"Points " + this.state.pointValue}
                open={this.state.openEditPoints}
                onOpen={this.openEditPoints.bind(this, true)}
                onClose={this.openEditPoints.bind(this, false)}
                dropProps={{align: {top: "bottom"}}}
                dropContent={
                  <Box pad="large" round="medium">
                    <Form onSubmit={data => this.onPointFormSubmit(data.value.pointValue)}>
                      <FormField
                        label="How many points is this question worth?"
                        error={this.state.pointValueErr}
                        name="pointValue"
                        value={this.state.tentPointValue}
                        type="number"
                        min="1"
                        required
                        onChange={event => this.setState({tentPointValue: event.target.value})}
                      />
                      <Box direction="row" justify="between" margin={{top: "medium"}}>
                        <Button type="submit"
                                label={this.state.savingPointValue ? "Saving..." : "Save"} primary/>
                        <Button margin="small"
                                onClick={this.openEditPoints.bind(this, false)}>Cancel</Button>
                      </Box>
                    </Form>
                  </Box>}
              />
            </Box>
            <Box>
              <CheckBox
                checked={this.state.extraCreditChecked}
                label="Extra credit"
                onChange={event => this.setExtraCredit(event.target.checked)}
              />
            </Box>
            <Box direction="row" gap="small">
              <CheckBox checked={this.state.doNotAutomaticallyGrade} label={"Do not automatically grade"}
                        onChange={this.toggleAutoGrade}/>
            </Box>
            <Box direction="row" gap="large">
              <Box direction="row">
                <Box style={this.props.isFirstQuestion ? {visibility: 'hidden'} : undefined}>
                  <Button icon={<Up/>} title="Reorder: Move up"
                          onClick={this.props.isFirstQuestion ? undefined : this.props.decrementQuestionNumber}/>
                </Box>
                <Box style={this.props.isLastQuestion ? {visibility: 'hidden'} : undefined}>
                  <Button icon={<Down/>} title="Reorder: Move down"
                          onClick={this.props.isLastQuestion ? undefined : this.props.incrementQuestionNumber}/>
                </Box>
              </Box>
              <Box direction="row">
                <Button icon={<Edit/>} title="Edit question" href={"/question/" + this.props.question.id + "/edit"}/>
                <Button icon={<Clone/>} title="Duplicate question" onClick={this.props.copyQuestion}/>
                <Button icon={<Trash/>} title="Delete question" onClick={this.openAreYouSureModal.bind(this, true)}/>
              </Box>
            </Box>
          </Box>
          <GrommetDraftJS readOnly={true} questionObj={this.props.question}/>
          {this.state.openAreYouSure && <YesNoModal instructions="Are you sure you want to delete this question?"
                                                    onClickYes={this.deleteQuestion}
                                                    onSelectNo={this.openAreYouSureModal.bind(this, false)}/>}
        </Box>
      </Collapsible>
    )
  }
}

SingleQuestionEditorBasic.propTypes = {
  copyQuestion: PropTypes.func.isRequired,
  appServices: Models.appServices.isRequired,
};

SingleQuestionEditorBasic.defaultProps = {};

export default SingleQuestionEditorBasic