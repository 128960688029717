//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Button, Stack, Text} from "grommet";
import {FlagFill, StatusCritical, StatusGood, StatusWarning} from 'grommet-icons';
//hypergrade
import Submit from "./Submit";
import GradeDetail from "./GradeDetail";

class QuestionStatus extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Box align="center" gap="small">
        <Anchor href={"/assignment/" + this.props.assignment.id}>{this.props.assignment.name}</Anchor>
        <Box direction="row">
          {this.props.questionList.map((q, index) => {

            let numCasesPassed = Submit.getNumCasesPassed(q.runResults);
            let result;
            if (q.type === "file_submission" && q.files.length > 0) {
              result = <StatusGood color={"status-ok"} size="large"/>;
            } else if (numCasesPassed === 0) {
              if (q.files.length) { //if there was an attempt
                result = <StatusWarning color={"status-warning"} size="large"/>;
              } else {
                result = <StatusCritical color={"status-error"} size="large"/>;
              }
            } else if (numCasesPassed === q.test_cases.length) {
              result = <StatusGood color={"status-ok"} size="large"/>;
            } else {
              result = <StatusWarning color={"status-warning"} size="large"/>;
            }

            let isActive = index === this.props.selectedQuestion;
            return (
              <Stack anchor="top-right" key={'QuestionStatus-' + index}>
                <Box background={isActive ? "light-5" : undefined} round pad="xsmall"
                     border={isActive ? {color: 'dark-6'} : undefined}>
                  <Button plain onClick={() => this.props.changeQuestion(index)} focusIndicator={false}>
                    <Box align="center">
                      {result}
                      <Text><b>{index + 1}</b></Text>
                    </Box>
                  </Button>
                </Box>
                {GradeDetail.hasRisks(this.props.riskReport, q.id) && <FlagFill color="status-critical"/>}
              </Stack>
            )
          })}
        </Box>
      </Box>
    )
  }
}

QuestionStatus.propTypes = {
  questionList: PropTypes.array.isRequired,
  assignment: PropTypes.object.isRequired,
  changeQuestion: PropTypes.func.isRequired,
  selectedQuestion: PropTypes.number.isRequired,
  riskReport: PropTypes.any
};

QuestionStatus.defaultProps = {};

export default QuestionStatus