//react
import React from "react"
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
//grommet
import {Box, Button, Header, Text} from "grommet";
import {Book, Home, Logout, UserSettings} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';

//style
import Models from "../models/appServices";
import LogoButton from "./Custom/LogoButton";

class MenuBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.logout = this.logout.bind(this);
    this.leaveStudentView = this.leaveStudentView.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.location.pathname === this.state.redirect) {
      this.setState({redirect: null});
    }
  }

  logout() {
    this.setState({redirect: "/user/logout"});
    this.props.logout();
  }

  leaveStudentView() {
    HyperGrade.services.leaveStudentView().then(() => {
      this.setState({redirect: '/loading'});
    });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    let right, homeLink = "/";
    let isStudent = this.props.account && this.props.account.role === HyperGrade.roles.student;

    if (this.props.account) {

      homeLink = "/home";

      let accountButtonLabel = null;

      if (this.props.account.is_test_student) {
        accountButtonLabel = <Button color={"accent-3"} onClick={this.leaveStudentView} label="Leave student view"/>
      } else if (this.props.account.firstname && this.props.account.firstname) {
        accountButtonLabel = <Text color="dark-5" className="real-name">{this.props.account.firstname + " " +
          this.props.account.lastname}</Text>;
      }

      right =
        <Box as="nav" direction="row" gap="medium" align="center">
          {accountButtonLabel}
          <Button href={homeLink} icon={<Home/>} title="Home"/>
          {isStudent ? <Button href={"/my-grades"} icon={<Book/>} title="Grades"/> : null}
          <Button href="/user/settings" icon={<UserSettings/>} title="Account Settings"/>
          <Button onClick={this.logout} icon={<Logout/>} title="Log out"/>
        </Box>;
    } else {
      right =
        <Box direction='row' align="center">
          <Button label="Register" href="/register" margin="small"/>
          <Button label="Login" href="/login" margin="small"/>
        </Box>;
    }

    return (
      <Header background={"white"} pad={{vertical: "small", horizontal: "medium"}} elevation={"small"}>
        <LogoButton where={homeLink}/>
        {right}
      </Header>
    )
  }
}

MenuBar.propTypes = {
  account: PropTypes.object,
  appServices: Models.appServices.isRequired,
  background: PropTypes.any
};

MenuBar.defaultProps = {
  background: "dark-1"
};

export default MenuBar