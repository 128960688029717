//react
import React from "react"
//grommet
import {Anchor, Box, Button} from "grommet";
//lodash
import PropTypes from "prop-types";

class QuestionSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <Box align="center" gap="small">
        {this.props.showLink &&
        <Anchor margin="none" target="_blank"
                href={"/assignment/" + this.props.assignment.id}>{this.props.assignment.name}</Anchor>
        }
        {this.props.questions.length > 1 &&
        <Box as={"nav"} direction="row" gap="xsmall">
          {this.props.questions.map((question, index) => {

            return (
              <Button key={"question-select-" + question.id} title={"Question " + (index + 1)}
                      href={"/question/" + question.id + "/" + this.props.action}
                      primary={question.id === this.props.selectedQuestionID} //could also be "active"
                      label={index + 1}
              />
            );
          })}
        </Box>
        }
      </Box>
    )
  }
}

QuestionSelect.propTypes = {
  action: PropTypes.string.isRequired,
  selectedQuestionID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  assignment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired).isRequired,
  showLink: PropTypes.bool
};

QuestionSelect.defaultProps = {
  showLink: true
};

export default QuestionSelect