//base of this code is from https://github.com/benshope/react-timezone-picker

//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Select} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';

import zones from './TimeZoneList';

class TimeZonePicker extends React.Component {

  constructor(props) {
    super(props);

    let currentZone = HyperGrade.util.detectMyTimezone();
    let currentCountry = HyperGrade.util.detectMyCountry();

    zones.sort((a, b) => {
      //bring the detected timezone to the top
      if (currentZone && currentZone === a) {
        return -1
      }
      //bring timezones in your country to the top
      if (currentCountry && a.includes(currentCountry) && !b.includes(currentCountry)) {
        return -1
      }
      return 0
    });

    let startingValue = this.props.preselect && zones.indexOf(this.props.preselect) >= 0 ? this.props.preselect : zones[0];

    this.state = {currentZone, currentCountry, zones, value: startingValue};

    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    //provide the starting point to the parent as well just
    // in case we arrive at this page with no timezone set
    this.onChange(this.state.value);
  }

  onChange(value) {
    this.setState({value});
    this.props.onChange(value);
  }

  onSearch(data) {
    data = data.toLowerCase();
    this.setState({
      zones: zones.filter(current => current
        .toLowerCase()
        .replace(/_/g, " ")
        .includes(data))
    });
  }

  render() {
    return (

      <Select options={this.state.zones}
              value={this.state.value}
              onSearch={this.onSearch}
              onChange={({option}) => this.onChange(option)}
      />
    )
  }

}


TimeZonePicker.propTypes = {
  preselect: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

TimeZonePicker.defaultProps = {
  onChange: () => {
  }
};

export default TimeZonePicker