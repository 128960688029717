//react
import React from "react"
//grommet
import {Box} from "grommet";
import {Cycle} from 'grommet-icons';
//hypergrade
import PropTypes from "prop-types";

const spinning = (
  <svg
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
    height="32px"
    fill="#333333"
  >
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28
      A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    let indicator = <Cycle className="spin" size="large"/>;
    indicator = spinning;

    if (this.props.block) {
      return <Box align="center" margin={this.props.margin}>{indicator}</Box>
    } else {
      return indicator;
    }
  }
}

Loading.propTypes = {
  block: PropTypes.bool,
  margin: PropTypes.string
};

Loading.defaultProps = {
  block: true,
  margin: "medium"
};

export default Loading