//react
import React from "react"
//grommet
import {Box, Button, Heading, Layer} from "grommet";
import {Close} from 'grommet-icons';
//hypergrade
import PropTypes from "prop-types";

class YesNoModal extends React.Component {

  render() {
    return (
      <Layer animation="fadeIn" position="top-right" onClickOutside={this.props.onSelectNo}>
        <Box>
          <Box fill direction="row" justify="end">
            <Button icon={<Close/>} onClick={this.props.onSelectNo}/>
          </Box>
          <Box align="center" pad={{horizontal: "medium", bottom: "medium"}} gap="medium">
            <Heading level="3" margin="none">{this.props.instructions}</Heading>
            {this.props.bodyText}
            <Box direction="row" gap="medium" justify="between">
              <Button label="Yes" onClick={this.props.onClickYes} primary/>
              <Button label="No" onClick={this.props.onSelectNo}/>
            </Box>
          </Box>
        </Box>
      </Layer>
    )
  }
}

YesNoModal.propTypes = {
  instructions: PropTypes.string,
  onClickYes: PropTypes.func.isRequired,
  onSelectNo: PropTypes.func.isRequired,
  bodyText: PropTypes.oneOfType([PropTypes.node]),
};

YesNoModal.defaultProps = {
  instructions: "Are you sure?",
};

export default YesNoModal