//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Collapsible, Text} from "grommet";

class CompilationError extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }



  render() {
    return (
      <Collapsible open={!!this.props.message}>
        <Box overflow="auto" alignSelf="center" pad="medium"
             background="light-3"
             elevation="x large"
             margin="small"
             border={{size: "medium"}}
             round
        >
          <Text color="red">
            <pre>{this.props.message}</pre>
          </Text>
        </Box>
      </Collapsible>
    )
  }
}

CompilationError.propTypes = {
  message: PropTypes.string,
};

CompilationError.defaultProps = {};

export default CompilationError