//react
import React from "react"
import PropTypes from "prop-types";

//hypergrade
import LogoButton from "./LogoButton";

class FormFrame extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }


  render() {
    return (
      <div id="form-frame" className={"super-hg"}>
        <div className={"branding-block"}>
          <div className={"logo-container"}>
            <LogoButton/>
          </div>
          <div className={"copy-container"}>
            <div className={"copy"}>
              <h1>Welcome<br/>to<br/>HyperGrade</h1>
              <p>The fundamentals, object-oriented programming, data structures, and more.</p>
            </div>
          </div>
        </div>
        <div className={"form-container"}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

FormFrame.propTypes = {
  example: PropTypes.number,
};

FormFrame.defaultProps = {
  example: 5
};

export default FormFrame