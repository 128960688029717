import React from "react";
import PropTypes from "prop-types";
import {Box, Text} from "grommet/es6";

class Pill extends React.Component {
  render() {

    let bg = "dark-3";
    if (this.props.status === "good") {
      bg = "status-ok";
    } else if (this.props.status === "bad") {
      bg = "status-critical";
    }

    return (
      <Box elevation="large" background={bg} round pad={{vertical: "xsmall", horizontal: "small"}}>
        <Text color="light-1"><b>{this.props.label}</b></Text>
      </Box>
    );
  }
}

Pill.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default Pill;