//react
import React from "react"
//grommet
import {Anchor, Box, Button, CheckBox, Grid, Heading, Paragraph, Text, TextArea, TextInput,} from "grommet";
import {Close, SettingsOption} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
//lodash
import PropTypes from "prop-types";
import YesNoModal from "./YesNoModal";
import Reorder from "./Reorder";
import appServices from "../models/appServices";
import Pill from "./Pill";
import CharacterByCharacterCombined from "./CharacterByCharacterCombined";
import _ from 'lodash';
import ModalSkeleton from "./ModalSkeleton";

class TestCaseEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: [0, 1],
      //starting point for the input fields
      stdin: props.testCase && props.testCase.input ? props.testCase.input : "",
      cla: props.testCase && props.testCase.command_line_arguments ? props.testCase.command_line_arguments : "",
      hide: props.testCase && !!props.testCase.hide,
      userClickedRun: false,
    };

    this.state.lastRunStandardInput = this.state.stdin;
    this.state.lastRunCommandLineArguments = this.state.cla;

    this.saveTestCase = this.saveTestCase.bind(this);
    this.onActive = this.onActive.bind(this);
    this.close = this.close.bind(this);
    this.openTestCaseDeleteConfirmation = this.openTestCaseDeleteConfirmation.bind(this);
    this.closeTestCaseDeleteConfirmation = this.closeTestCaseDeleteConfirmation.bind(this);
    this.caseHasUnsavedWork = this.caseHasUnsavedWork.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isBrandNew = () => !this.props.testCase && !this.state.testCase;
    this.getTestCase = () => this.state.testCase ? this.state.testCase : this.props.testCase;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.testCase, this.props.testCase)) {
      this.setState({testCase: null});
    }
  }

  //updates or creates the case as needed
  saveTestCase() {

    //figure out if we're making a new case or we're updating
    let promise;
    if (this.isBrandNew()) {
      promise = () => HyperGrade.services.createTestCase(this.props.questionID, this.state.stdin, this.state.cla);
    } else {
      promise = () => HyperGrade.services.updateTestCase(this.props.questionID, this.state.stdin, this.state.cla,
        this.getTestCase().id);

    }

    this.props.beginWorking("Running test case.");
    //tell the server to run the code using the given inputs
    promise().then(server => {

      let tc = server.data.codeProject.testCaseList.find(tc => tc.id === server.data.modifiedTestCaseID);

      this.setState({
        userClickedRun: true,
        saved: true,
        testCase: tc,
        lastRunStandardInput: tc.input,
        lastRunCommandLineArguments: tc.commandLineArguments,
        testCaseList: server.data.codeProject.testCaseList
      }, () => {
        if (!this.props.modal) {
          this.props.updateTestCaseList(this.state.testCaseList);
        }
      });

      this.props.endWorking();
    });
  }

  onActive(activeIndex) {
    this.setState({activeIndex});
  }

  caseHasUnsavedWork() {
    let tc = this.getTestCase();
    return tc && (this.state.lastRunStandardInput !== this.state.stdin || this.state.lastRunCommandLineArguments !== this.state.cla);
  }

  close() {
    if (this.caseHasUnsavedWork()) {
      this.setState({showWarning: true});
    } else {
      this.closeModal();
    }
  }

  closeModal() {

    //if we have something new
    if (this.state.testCaseList) {
      this.props.updateTestCaseList(this.state.testCaseList);
    }

    this.props.onEditingComplete();
  }

  openTestCaseDeleteConfirmation() {
    this.setState({showTestCaseDeleteConfirmation: true});
  }

  closeTestCaseDeleteConfirmation() {
    this.setState({showTestCaseDeleteConfirmation: false});
  }

  toggleStudentVisibility() {
    this.props.beginWorking();
    let oldTestCase = this.getTestCase();
    HyperGrade.services.toggleStudentVisibility(oldTestCase.id).then(res => {
      let tc = _.cloneDeep(oldTestCase);
      tc.hide = !tc.hide;
      this.props.updateTestCaseSingle(tc);
      this.setState({hide: tc.hide});
      this.props.endWorking();
    });
  }

  render() {

    let tc = this.getTestCase();

    let pills = [];
    if (tc) {
      if (tc.errGotStuck) {
        pills.push(<Pill key="p-stuck" status={"bad"} label={"Code is hanging - possible infinite loop"}/>);
      } else if (tc.errMaxOutputSize) {
        pills.push(<Pill key="p-max" status={"bad"} label={"Produced too much output"}/>);
      } else if (tc.errTimedOut) {
        pills.push(<Pill key="p-timeout" status={"bad"} label={"Took too long to run"}/>);
      } else if (tc.returnCode === HyperGrade.errCannotContinue) {
        pills.push(<Pill key="p-crash" status={"bad"}
                         label={"Could not run because an earlier test cased took too long"}/>);
      } else if (tc.errRuntimeError) { //the most generic, keep last
        pills.push(<Pill key="p-crash" status={"bad"} label={"Crashed"}/>);
      }
    }

    pills = <Box direction="row" gap="small">{pills}</Box>;

    return (
      <Box>
        <Box pad="small" round="small" elevation={"medium"}>

          <Grid
            columns={{
              count: 3,
              size: "auto"
            }}
            gap="small"
          >
            {/*case number, action button, save status*/}
            <Box direction="row" gap="small" align="center" margin="small">
              <Heading level="4" margin="none">Test Case {this.props.index}</Heading>
              <Button
                disabled={this.props.disabled || (!this.isBrandNew() && tc.returnCode === HyperGrade.errCannotContinue)}
                key="save"
                label="Run" onClick={this.saveTestCase}/>
              {this.state.saved &&
              <Text color={"status-ok"}>{this.isBrandNew() ? "Test case created!" : "Test case updated!"}</Text>
              }
            </Box>

            {/*test case problems*/}
            <Box direction="row" align="center" gap="small">
              {pills}
            </Box>

            {/*delete, reorder*/}
            <Box direction="row">
              <Box align={"start"} basis="1/2">
                {!this.isBrandNew() &&
                <Box direction={"row"}>
                  <Button icon={<SettingsOption/>} onClick={() => this.setState({showOptions: true})}
                          title={"Options"}/>
                  {this.state.hide && <Paragraph>Test case is hidden from students</Paragraph>}
                </Box>
                }
              </Box>
              <Box align="end" justify="center" basis="1/2">
                {!this.props.modal &&
                <Reorder index={this.props.index}
                         max={this.props.totalNumTestCases}
                         onMoveUp={this.props.onMoveUp}
                         onMoveDown={this.props.onMoveDown}/>
                }

                {this.props.modal && <Button icon={<Close/>} onClick={this.close} title="Close"/>}
              </Box>

            </Box>

          </Grid>

          <Box>
            <Grid
              rows={["medium"]}
              columns={["1/4", "3/4"]}
              areas={[["input", "output"]]}>
              <Box background="light-5" gridArea="input" pad="small" gap="small" margin={{horizontal: "xxsmall"}}
                   height="medium">
                <Box gap="small">
                  <Heading level="4" margin="none">Command Line Arguments</Heading>
                  <TextInput
                    value={this.state.cla}
                    onChange={event => this.setState({cla: event.target.value})}
                  />
                </Box>
                <Box gap="small" basis="3/4">
                  <Heading level="4" margin="none">Standard Input</Heading>
                  <TextArea placeholder="This text will be fed to the program during runtime"
                            fill
                            value={this.state.stdin}
                            onChange={event => this.setState({stdin: event.target.value})}
                  />
                </Box>
              </Box>

              <Box background="light-2" gridArea="output" pad="small" overflow={"auto"}
                   height={this.props.outputBoxSize}
                   margin={{horizontal: "xxsmall"}}>
                {tc && tc.output &&
                <CharacterByCharacterCombined
                  teacherOutput={tc.outputMixed ? tc.outputMixed : tc.output}
                  plain/>
                }
              </Box>
            </Grid>
          </Box>
        </Box>
        {this.state.showWarning &&
        <YesNoModal instructions={"You have unsaved work. Really close?"}
                    onClickYes={this.closeModal}
                    onSelectNo={() => this.setState({showWarning: false})}/>
        }
        {
          this.state.showOptions &&
          <ModalSkeleton onClose={() => this.setState({showOptions: false})}>
            <Heading level="3">Test Case Options</Heading>
            <Box gap="medium">
              <Box gap="small" elevation={"small"} pad={"small"}>
                <CheckBox
                  checked={!!this.state.hide}
                  label="Hide test case from student"
                  onChange={(event) => this.toggleStudentVisibility(event.target.checked)}
                />
                <Text color="dark-3">May help prevent students from hardcoding. Use this option sparingly.</Text>
              </Box>

              <Box gap="small" elevation={"small"} pad={"small"}>
                <Text>
                  <Anchor onClick={this.openTestCaseDeleteConfirmation}>Delete test case</Anchor>
                </Text>
              </Box>
            </Box>
          </ModalSkeleton>
        }
        {this.state.showTestCaseDeleteConfirmation &&
        <YesNoModal instructions="Delete this test case?"
                    onClickYes={() => {
                      this.closeTestCaseDeleteConfirmation();
                      this.setState({showOptions: false})
                      this.props.deleteTestCase(tc.id);
                    }}
                    onSelectNo={this.closeTestCaseDeleteConfirmation}/>
        }
      </Box>
    )
  }
}

TestCaseEditor.propTypes = {
  updateTestCaseList: PropTypes.func.isRequired,
  onEditingComplete: PropTypes.func,
  index: PropTypes.number.isRequired,
  totalNumTestCases: PropTypes.number.isRequired,
  deleteTestCase: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,

  // test case
  testCase: PropTypes.shape({
    id: PropTypes.number,
    input: PropTypes.string,
    command_line_arguments: PropTypes.string,
    display: PropTypes.number,
    output: PropTypes.string,
    hide: PropTypes.any,
    errGotStuck: PropTypes.any,
    errMaxOutputSize: PropTypes.any,
    errTimedOut: PropTypes.any,
    errRuntimeError: PropTypes.any,
    returnCode: PropTypes.number,
  }),

  outputBoxSize: PropTypes.string,
  ...appServices,
  disabled: PropTypes.bool
};

TestCaseEditor.defaultProps = {
  index: 1,
  outputBoxSize: "medium",
  disabled: false
};

export default TestCaseEditor