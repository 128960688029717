//react
import React from "react"
import HyperGrade from "../../hg-config";
import Models from "../../models/appServices";

//hypergrade

class FormForgotPassword extends React.Component {

  constructor(props) {
    super(props);

    this.manage = HyperGrade.util.manage.bind(this);
    this.submitPasswordResetForm = this.submitPasswordResetForm.bind(this);

    this.state = {};
  }

  componentDidMount() {
  }

  submitPasswordResetForm(event) {
    event.preventDefault();
    this.props.appServices.beginWorking();
    HyperGrade.services.resetPassword(this.state.email)
      .then(res => {
        this.props.appServices.endWorking();

        if (res.code === "PASSWORD_RESET_NO_USER") {
          this.props.appServices.openGenericModal(
            <div>
              <p>There is no account associated with <b>{this.state.email}</b></p>
              <p>If you believe this is an error, please <a href="/support">send us a
                message</a>.</p>
            </div>, "Error");
        } else if (res.code === "PASSWORD_RESET_OK") {
          this.props.appServices.openGenericModal(
            <div>
              <p>We sent a password reset link to <b>{this.state.email}</b>. Check your junk/spam folder
                if you cannot find the message.</p>
            </div>, "Check your email");
        } else {
          this.props.appServices.showErrorNotification("Unrecognized response from HyperGrade!");
        }
      });
  }

  render() {
    return (
      <div id={"forgot-password-form"} className={"form-frame-theme"}>
        <div className={"lead"}>
          <h1>Reset Password</h1>

          <p><a href={"/register"}>Register</a> or <a href={"/register"}>Login</a></p>
        </div>
        <form method={"post"} onSubmit={this.submitPasswordResetForm}>
          <div className={"form-group"}>
            <label htmlFor={"email"}>Email</label>
            <input type={"email"} name={"email"} id={"email"} {...this.manage('email')} required/>
          </div>

          <div className={"form-group"}>
            <input className={"hg-button-1"} type={"submit"} value={"Sign In"}/>
          </div>
        </form>
        <p>Still having trouble? <a href={"/support"}>Contact us</a>.</p>
      </div>
    )
  }
}

FormForgotPassword.propTypes = {
  appServices: Models.appServices.isRequired,
};

FormForgotPassword.defaultProps = {};

export default FormForgotPassword