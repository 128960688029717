//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box} from "grommet";

//hypergrade

class StopLight extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      true: 'status-ok',
      false: 'status-critical',
      good: 'status-ok',
      bad: 'status-critical',
      warning: 'status-warning',
      unknown: 'status-unknown',
      none: 'undefined'
    };
  }

  componentDidMount() {
  }

  render() {
    return <Box className={"t3"}
                background={this.props.status === "none" ? undefined : this.state[this.props.status]}
                pad="xsmall">{this.props.children}</Box>
  }
}

StopLight.propTypes = {
  status: PropTypes.oneOf(['good', 'bad', 'warning', 'unknown', true, false, "none"])
};

StopLight.defaultProps = {};

export default StopLight