//react
import React from "react"
import PropTypes from 'prop-types';
//grommet
import {Box, Button, Form, FormField, Heading, Layer, TextInput} from "grommet";
import {Close} from 'grommet-icons';


class TextQuestionModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.startingValue ? this.props.startingValue : ""
    }
  }

  render() {
    return (
      <Layer position="center" modal onClickOutside={this.props.onClose}
             onEsc={this.props.onClose}>
        <Box pad="large" width="large">
          <Box direction="row" justify="end">
            <Button icon={<Close/>} onClick={this.props.onClose}/>
          </Box>
          <Form onSubmit={data => this.props.onSubmit(data.value.text)}>
            <Box align="center">
              <Heading margin={{top: "none"}} textAlign="center" level="3">{this.props.instructions}</Heading>
            </Box>
            <FormField>
              <TextInput name="text"
                         type={this.props.inputType}
                         placeholder="Assignment name"
                         required={this.props.inputRequired}
                         value={this.state.value}
                         onChange={event => this.setState({value: event.target.value})}
              />
            </FormField>
            <Box direction="row" justify="between" margin={{top: "medium"}}>
              <Button type="submit" label={this.props.submitLabel} primary/>
              <Button label={this.props.cancelLabel} onClick={this.props.onClose}/>
            </Box>
          </Form>
        </Box>
      </Layer>)
  }
}

TextQuestionModal.propTypes = {
  instructions: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inputRequired: PropTypes.bool,
  startingValue: PropTypes.string,
  inputType: PropTypes.string
};

TextQuestionModal.defaultProps = {
  submitLabel: "Save",
  cancelLabel: "Cancel",
  inputRequired: true,
  inputType: "text"
};

export default TextQuestionModal