//react
import React from "react"
//grommet
import {Box} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';
import Loading from "./Loading";
import StudentGradeBox from "./StudentGradeBox";

class MyGrades extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};
  }

  componentDidMount() {
    HyperGrade.services.getMyGrades().then(server => {
      if (server.success) {
        this.setState({gradeList: server.data, loading: false});
      } else {
        this.props.showErrorNotification("Could not load grades");
      }
    });
  }

  render() {

    if (this.state.loading)
      return <Loading/>;

    return (
      <Box margin={{vertical: "large"}}>
        <Box align="center" gap="medium">
          {this.state.gradeList.map(current =>
            <Box width="xxlarge" elevation="xlarge" pad="medium" key={"student-grade-box-" + current.course.id}>
              <StudentGradeBox course={current.course}
                               courseGrade={current.courseGrade}
                               grades={current.grades}/>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

MyGrades.propTypes = {};

MyGrades.defaultProps = {};

export default MyGrades