//react
import React from "react"
import PropTypes from "prop-types";
//npm
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
//grommet
import {Box, Button, Image, Text} from "grommet";
import {Download} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';

class File extends React.Component {

  constructor(props) {
    super(props);

    this.state = {timestampLocalMoment: HyperGrade.util.convertUTCToLocal(this.props.timestampUTC)};
  }

  componentDidMount() {

  }

  render() {

    let supportedLanguage = HyperGrade.util.getLanguage(this.props.name);
    let file;

    //user may submit file without extension

    //could be a blank file
    if (this.props.content) {
      if (supportedLanguage) {
        file = <SyntaxHighlighter language={supportedLanguage.highlighterStyleName} showLineNumbers>
          {this.props.content}
        </SyntaxHighlighter>
      } else if (this.props.type && this.props.type.startsWith("text")) {
        file = <Box background="light-3" margin={{top: "xsmall"}} pad="small">
          <pre>{this.props.content}</pre>
        </Box>
      } else if (this.props.type && this.props.type.startsWith("image")) {
        file = <Box>
          <Image src={HyperGrade.makeFileDownloadLink(this.props.id)} alt={"Student file"} fit="contain"/>
        </Box>
      } else {
        file =
          <Box align="center" margin="medium"><Text textAlign="center"><strong>Download this file to
            view.</strong></Text></Box>;
      }
    } else {
      file =
        <Box align="center" margin="medium"><Text textAlign="center"><strong>This file is blank!</strong></Text></Box>;
    }

    return (
      <Box gap="xxsmall">
        <Box direction="row" justify="between" align="center">
          <Box direction="row" justify="center" align="center" gap="small">
            <Button icon={<Download/>} label={this.props.name} href={HyperGrade.makeFileDownloadLink(this.props.id)}/>
            <Text>{HyperGrade.util.formatBytes(this.props.size)}</Text>
          </Box>
          <Text>
            Submitted <time><b>{this.state.timestampLocalMoment.format("LLLL")}</b></time>
          </Text>
        </Box>
        {file}
      </Box>
    )
  }
}

File.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  timestampUTC: PropTypes.string.isRequired
};

File.defaultProps = {};

export default File