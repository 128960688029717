//react
import React from "react"
//grommet
import {Box, Heading} from "grommet";

//hypergrade

class RichLabel extends React.Component {
  render() {
    return <Box direction="row" align="center" gap="small">
      {this.props.icon}
      <Heading level="4">
        {this.props.label}
      </Heading>
    </Box>
  }
}

RichLabel.propTypes = {};

RichLabel.defaultProps = {};

export default RichLabel