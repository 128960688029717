//react
import React from "react"
import PropTypes from "prop-types";
import {Redirect, Route} from "react-router-dom";
//grommet
import {Paragraph} from "grommet";

class ProtectedRoute extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    let {children, render, ...rest} = this.props;

    return (
      <Route {...rest} render={(routedProps) => {

        let whatToShow;
        if (this.props.userIsLoggedIn()) {
          if (render) {
            whatToShow = render(routedProps);
          } else if (children) {
            whatToShow = children;
          } else {
            whatToShow = <Paragraph>Route was matched, but nothing to show.</Paragraph>;
          }
        } else {
          whatToShow = <Redirect to={{
            pathname: "/login",
            state: {
              from: routedProps.location //if changing this, then see Login.mightAlreadyBeLoggedIn
            }
          }}/>;
        }

        return whatToShow;

      }}/>
    )
  }
}

ProtectedRoute.propTypes = {
  userIsLoggedIn: PropTypes.func.isRequired
};

ProtectedRoute.defaultProps = {};

export default ProtectedRoute