//react
import React from "react"
import {Redirect} from "react-router-dom";
//lodash
import _ from 'lodash';
//grommet
import {Box, Button, CheckBox, Collapsible, Heading, Paragraph} from "grommet";
import {Add} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import SingleQuestionEditorBasic from "./SingleQuestionEditorBasic";
import TextQuestionModal from "./TextQuestionModal";
import Models from "../models/appServices";

class QuestionSummary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      questions: Array.isArray(props.questions) ? _.cloneDeep(props.questions) : [],
      lateDaysAllowed: !props.assignment.disable_late_days,
      password: props.assignment.password,
      requirePassword: !!props.assignment.use_password,
      openPasswordModal: false
    };

    this.hideQuestion = this.hideQuestion.bind(this);

    this.move = this.move.bind(this);
    this.decrementQuestionNumber = this.decrementQuestionNumber.bind(this);
    this.incrementQuestionNumber = this.incrementQuestionNumber.bind(this);
    this.toggleLateDays = this.toggleLateDays.bind(this);
    this.toggleRequirePassword = this.toggleRequirePassword.bind(this);

    // password modal
    this.setPasswordModal = this.setPasswordModal.bind(this);
    this.showPasswordModal = this.setPasswordModal.bind(this, true);
    this.hidePasswordModal = this.setPasswordModal.bind(this, false);
    this.submitNewPassword = this.submitNewPassword.bind(this);

    this.createQuestion = this.createQuestion.bind(this);
    this.copyQuestion = this.copyQuestion.bind(this);
  }

  componentDidMount() {
    //if some weird situation where the password is required but there's no password
    if (this.state.requirePassword && !this.state.password) {
      this.submitNewPassword("");
    }
  }

  hideQuestion(questionToDeleteID) {
    this.setState({
      questions: this.state.questions.filter(question => question.id !== questionToDeleteID)
    });
  }

  move(questionID, howMuch) {
    let rearrangedQuestions = _.cloneDeep(this.state.questions);
    let currentPosition = rearrangedQuestions.findIndex(current => current.id === questionID);
    let newPosition = currentPosition + howMuch;
    if (currentPosition >= 0 && newPosition >= 0 && newPosition < rearrangedQuestions.length) {
      //swap the two array elements locally
      HyperGrade.util.swap(rearrangedQuestions, currentPosition, newPosition);
      //send new ordering to server
      HyperGrade.services.rearrangeQuestions(rearrangedQuestions.map(question => question.id));
      //show to ordering to user
      this.setState({questions: rearrangedQuestions});
    } else {
      throw Error('HyperGrade: Cannot swap questions indexes ' + currentPosition + ' and ' + newPosition);
    }
  }

  decrementQuestionNumber(questionID) {
    this.move(questionID, -1);
  }

  incrementQuestionNumber(questionID) {
    this.move(questionID, +1);
  }

  copyQuestion(questionID) {
    this.props.appServices.beginWorking();
    HyperGrade.services.copyQuestion(questionID, this.props.assignment.id).then(server => {
      HyperGrade.services.getAssignmentAndQuestions(this.props.assignment.id)
        .then(server => this.setState({questions: server.data.question}));
      this.props.appServices.endWorking();
      this.props.appServices.showSuccessNotification("Question has been copied!");
    });
  }

  toggleLateDays() {
    this.setState({lateDaysAllowed: !this.state.lateDaysAllowed});
    HyperGrade.services.toggleDisableLateDays(this.props.assignment.id);
  }

  toggleRequirePassword() {
    //if we are TRYING to ENABLE the password and the password doesn't exist
    if (!this.state.requirePassword && !this.state.password) {
      this.showPasswordModal();
    } else {
      HyperGrade.services.toggleRequirePassword(this.props.assignment.id);
      this.setState({requirePassword: !this.state.requirePassword});
    }
  }

  setPasswordModal(openPasswordModal) {
    this.setState({openPasswordModal});
  }

  submitNewPassword(password) {
    if (password) {
      HyperGrade.services.enableAssignmentPassword(this.props.assignment.id, password);
      this.setState({requirePassword: true});
    } else {
      HyperGrade.services.changeAssignmentPassword(this.props.assignment.id, password);
      this.setState({requirePassword: false});
    }
    this.setState({password});
    this.hidePasswordModal();
  }

  createQuestion() {
    HyperGrade.services.createQuestion(this.props.assignment.id).then(server => {
      if (server.success) {
        this.setState({redirect: "/question/" + server.data + "/edit"});
      } else {
        this.props.appServices.showErrorNotification("Could not create a question");
      }
    });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    return (
      <Box fill round="small" gap="small">
        <Heading level={3} margin="none">{this.props.assignment.name}</Heading>
        {/*settings panel*/}
        <Box direction="row" justify="between">
          <Box direction="row" gap="medium">
            <CheckBox toggle checked={this.state.lateDaysAllowed} label={"Late days allowed"}
                      onChange={this.toggleLateDays}/>

            <Box direction="row" gap="small">
              <CheckBox toggle checked={this.state.requirePassword}
                        label={"Password required"}
                        onChange={this.toggleRequirePassword}/>
              <Collapsible open={!!this.state.requirePassword && !!this.state.password} direction="horizontal">
                <Box direction="row" gap="small">
                  <Button label={this.state.password} onClick={this.showPasswordModal}/>
                </Box>
              </Collapsible>
            </Box>
          </Box>
          <Box>
            <Button label={"Create question"} icon={<Add/>} onClick={this.createQuestion}/>
          </Box>
        </Box>

        {/*question panel*/}
        <Box gap="small">
          {this.state.questions.map((question, index, arr) =>
            <SingleQuestionEditorBasic question={question}
                                       appServices={this.props.appServices}
                                       copyQuestion={this.copyQuestion.bind(this, question.id)}
                                       hideQuestion={this.hideQuestion}
                                       decrementQuestionNumber={this.decrementQuestionNumber.bind(this, question.id)}
                                       incrementQuestionNumber={this.incrementQuestionNumber.bind(this, question.id)}
                                       isFirstQuestion={index === 0}
                                       isLastQuestion={index === arr.length - 1}
                                       key={question.id}
                                       index={index}/>)
          }
          <Collapsible open={this.state.questions.length === 0}>
            <Paragraph>This assignment doesn't have any questions yet.</Paragraph>
          </Collapsible>
        </Box>
        {this.state.openPasswordModal &&
        <TextQuestionModal
          onClose={this.hidePasswordModal}
          onSubmit={this.submitNewPassword}
          inputRequired={false}
          startingValue={this.state.password}
          instructions={"Enter a password"}/>}
      </Box>
    )
  }
}

QuestionSummary.propTypes = {
  appServices: Models.appServices.isRequired,
};

export default QuestionSummary