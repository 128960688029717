//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Button} from "grommet";
import HyperGrade from "../hg-config";
import {Redirect} from "react-router-dom";
import Models from "../models/appServices";

//hypergrade

class LoginAsStudent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.loginAsTestStudent = this.loginAsTestStudent.bind(this);
  }

  componentDidMount() {
  }

  loginAsTestStudent(e) {

    if (e) {
      e.preventDefault();
    }

    HyperGrade.services.loginAsTestStudent(this.props.courseID).then(response => {
      this.setState({redirect: '/loading'});
    });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    let content = null;
    if (this.props.type === "button") {
      content = <Button label="Login as test student" onClick={this.loginAsTestStudent}/>;
    } else {
      content = <Anchor onClick={this.loginAsTestStudent}>Student View</Anchor>
    }

    return content;
  }
}

LoginAsStudent.propTypes = {
  appServices: Models.appServices.isRequired,
  courseID: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["button", "anchor"]).isRequired,
};

LoginAsStudent.defaultProps = {};

export default LoginAsStudent