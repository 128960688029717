//react
import React from "react"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
//grommet
import {Anchor, Box, Heading, Paragraph} from "grommet";
//hypergrade
import CharacterByCharacterCombined from "./CharacterByCharacterCombined";
import MetaTags from "react-meta-tags";

class NotFound extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <Box className="page-not-found" align="center" justify="center" margin={{vertical: "large"}}>
        <MetaTags>
          <title>Page Not Found - HyperGrade</title>
          <meta name="description" content="The requested page doesn't exist."/>
        </MetaTags>
        <Box align="center" gap="small">
          <Heading level="1" margin="none">404</Heading>
          <Heading level="4" margin="none">Page not found</Heading>
          {this.props.message && <Paragraph color="status-critical">{this.props.message}</Paragraph>}
          <CharacterByCharacterCombined
            teacherOutput={[
              {type: "output", line: "Enter page to visit\n"},
              {type: "input", line: this.props.location.pathname + "\n"},
              {type: "output", line: "404 Page not found!\n"}
            ]}
          />
          <Paragraph>
            <Anchor href={this.props.userIsLoggedIn && this.props.userIsLoggedIn() ? "/home" : "/"}>Go home</Anchor>
          </Paragraph>
        </Box>
      </Box>
    )
  }
}

NotFound.propTypes = {
  message: PropTypes.string,
  userIsLoggedIn: PropTypes.func,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

NotFound.defaultProps = {
  message: ""
};

export default withRouter(NotFound)