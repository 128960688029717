//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Paragraph, Text} from "grommet";

//hypergrade

class Breadcrumb extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    //console.log(window.pathname);
  }

  render() {
    return (
      <Paragraph margin="none">
        <Anchor href="/home">Dashboard</Anchor>
        {this.props.crumbs && this.props.crumbs.map((current, index) => {
          if (current.href) {
            return <Text key={"bc-" + index} weight={"bold"}> / <Anchor
              href={current.href}>{current.label}</Anchor></Text>;
          } else {
            return <Text key={"bc-" + index} weight={"bold"}> / {current.label}</Text>;
          }
        })}
      </Paragraph>
    )
  }
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.any,
};

Breadcrumb.defaultProps = {};

export default Breadcrumb