import React from 'react';

import {Anchor, Box, Button, Collapsible, Form, Paragraph} from "grommet";

import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import HyperGrade from "../hg-config";


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clientSecret: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendPaymentInfo = this.sendPaymentInfo.bind(this);
  }


  componentDidMount() {

  }

  async sendPaymentInfo(clientSecret) {
    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: this.props.account.firstname + ' ' + this.props.account.lastname,
          email: this.props.account.username
        },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      this.setState({errorMessage: result.error.message});
      this.props.appServices.endWorking();
    } else if (result.paymentIntent.status === 'succeeded') {
      HyperGrade.services.usePaymentForKey().then(server => {
        if (server.success) {
          //send the key up, we're done
          this.props.onSuccess(server.data); //will call endWorking
        } else {
          this.props.appServices.showErrorNotification("Payment could not be confirmed. Please contact us.");
          this.props.appServices.endWorking();
        }
      });
    }
  }

  handleSubmit(event) {

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    this.setState({errorMessage: undefined});
    this.props.appServices.beginWorking();

    HyperGrade.services.createPaymentIntent().then(server => {
      if (server.success) {
        this.sendPaymentInfo(server.data);
      } else {
        console.log('Could not create payment intent');
        this.props.appServices.endWorking();
      }
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Box>
          <CardElement options={CARD_ELEMENT_OPTIONS}/>
          <Paragraph>This data will not be stored</Paragraph>
          <Box margin={{vertical: "small"}}>
            <Collapsible open={!!this.state.errorMessage}>
              <Paragraph margin="none" color="status-critical"><b>{this.state.errorMessage}</b></Paragraph>
            </Collapsible>
          </Box>
          <Box direction="row" justify="between" align="center">
            <Button primary disabled={!this.props.stripe} label="Submit Payment" type="submit"/>
            <Paragraph margin="none"><Anchor onClick={this.props.toggle}>Already have a HyperGrade
              key?</Anchor></Paragraph>
          </Box>
        </Box>
      </Form>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props}/>
      )}
    </ElementsConsumer>
  );
}