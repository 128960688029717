//react
import React from "react"
//grommet
import {Accordion, AccordionPanel, Box, Button, defaultProps, Form, FormField, Paragraph, Text} from "grommet";
import {ContactInfo, Lock, MailOption, Trash, UserSettings} from 'grommet-icons';
//lodash
import _ from 'lodash';
//hypergrade
import HyperGrade from '../hg-config';
import ModalSkeleton from "./ModalSkeleton";
import YesNoModal from "./YesNoModal";
import PropTypes from "prop-types";
import Models from "../models/appServices";
import RichLabel from "./RichLabel";

class StudentActionButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      student: _.cloneDeep(this.props.initialStudent),
      updatingEmail: false,
      updatingEmailSuccess: false,
      updatingEmailMessage: undefined,
      updatingName: false,
      updatingNameSuccess: false,
      openRemoveStudentConfirmation: false,
      updatingRemove: false,
      textInputUsername: this.props.initialStudent.username,
      textInputFirstName: this.props.initialStudent.firstname,
      textInputLastName: this.props.initialStudent.lastname,
    };

    this.onOpen = this.setOpen.bind(this, true);
    this.onClose = this.onClose.bind(this, false);

    this.updateEmailSubmit = this.updateEmailSubmit.bind(this);
    this.updateNameSubmit = this.updateNameSubmit.bind(this);

    this.openRemoveStudentConfirmation = this.setRemoveStudentConfirmation.bind(this, true);
    this.closeRemoveStudentConformation = this.setRemoveStudentConfirmation.bind(this, false);

    this.deleteStudent = this.deleteStudent.bind(this);
    this.openPasswordResetConfirmation = this.openPasswordResetConfirmation.bind(this);
    this.sendPasswordResetLink = this.sendPasswordResetLink.bind(this);

    this.updateStudentObject = this.updateStudentObject.bind(this);
  }

  setRemoveStudentConfirmation(openRemoveStudentConfirmation) {
    this.setState({openRemoveStudentConfirmation})
  }

  setOpen(open) {
    this.setState({open});
  }

  onClose() {
    this.setOpen(false);
    this.props.updateStudent(this.props.initialStudent.username, this.state.student);
  }

  updateEmailSubmit() {

    this.setState({updatingEmail: true, updatingEmailSuccess: false, updatingEmailMessage: undefined});

    let res = () => {
      setTimeout(() => {
        this.setState({
          updatingEmail: false,
          updatingEmailSuccess: false,
          updatingEmailMessage: undefined
        });
      }, HyperGrade.util.css_time_to_milliseconds(defaultProps.theme.global.animation.duration) * 2)
    }

    HyperGrade.services.changeEmailForStudent(this.state.student.key, this.state.textInputUsername).then(res => {
      this.setState({
        updatingEmail: false,
        updatingEmailSuccess: res.success
      });

      this.updateStudentObject('username', this.state.textInputUsername);

      this.props.appServices.showSuccessNotification(res.msg);
      res();
    })
      .catch(err => {
        res();
      })
    ;
  }

  updateNameSubmit() {
    this.setState({updatingName: true, updatingNameSuccess: false});
    HyperGrade.services.changeRealNameForStudent(this.state.student.key, this.state.textInputFirstName, this.state.textInputLastName)
      .then(res => {
        this.setState({
          updatingName: false,
          updatingNameSuccess: true,
          student: {
            ...this.state.student,
            firstname: this.state.textInputFirstName,
            lastname: this.state.textInputLastName
          },
        });
        this.props.appServices.showSuccessNotification("The student's name has been updated.");
      });
  }

  deleteStudent() {
    this.closeRemoveStudentConformation();
    this.props.appServices.beginWorking();
    this.setState({updatingRemove: true});
    HyperGrade.services.deleteStudent(this.state.student.key).then(res => {
      this.props.appServices.endWorking();
      this.setState({updatingRemove: false});
      if (res.success) {
        this.setOpen(false);
        this.props.appServices.showSuccessNotification("The student has been removed from this course.");
        this.props.updateStudent(this.props.initialStudent.username, null);
      }
    });
  };

  openPasswordResetConfirmation() {
    this.setState({resetConfirm: true});
  }

  sendPasswordResetLink() {
    this.props.appServices.beginWorking();
    this.setState({resetConfirm: false});
    HyperGrade.services.resetPassword(this.state.student.username).then(server => {
      this.props.appServices.endWorking();
      if (server.success) {
        this.props.appServices.showSuccessNotification("The message has been sent! Ask your student to check their email.");
      } else {
        this.props.appServices.showErrorNotification("The message would not be sent. Contact us directly at " + HyperGrade.supportEmail);
      }
    });
  }

  updateStudentObject(key, value) {
    this.setState({student: {...this.state.student, [key]: value}});
  }

  render() {
    return (
      <Box align="center">
        <Button icon={<UserSettings/>} onClick={this.onOpen} pad="none"/>
        {this.state.open &&
        <ModalSkeleton instructions={this.state.student.firstname + " " + this.state.student.lastname}
                       onClose={this.onClose}>
          <Accordion>
            {/*<AccordionPanel label={<RichLabel icon={<Unlink/>} label={"Transfer student to another course"}/>}>*/}
            {/*  <Box pad="medium" align="center" gap="small">*/}
            {/*    <Text>Not implemented yet.</Text>*/}
            {/*  </Box>*/}
            {/*</AccordionPanel>*/}
            <AccordionPanel label={<RichLabel icon={<MailOption/>} label={"Update student's email address"}/>}>
              <Box pad="medium">
                <Form onSubmit={this.updateEmailSubmit}>
                  <FormField label="email" name="email" type="email"
                             value={this.state.textInputUsername}
                             onChange={event => this.setState({textInputUsername: event.target.value})}
                             required/>
                  <Box direction="row" gap="small" align="center">
                    <Button type="submit" primary label="Update" disabled={this.state.updatingEmail}/>
                    {this.state.updatingEmail && <Text color={"status-unknown"}>Saving...</Text>}
                    {this.state.updatingEmailSuccess && <Text color={"status-ok"}>Saved!</Text>}
                    {this.state.updatingEmailMessage &&
                    <Text color={"status-error"}>{this.state.updatingEmailMessage}</Text>}
                  </Box>
                </Form>
              </Box>
            </AccordionPanel>
            <AccordionPanel label={<RichLabel icon={<ContactInfo/>} label={"Update student's name"}/>}>
              <Box pad="medium">
                <Form onSubmit={({value}) => this.updateNameSubmit(value)}>
                  <FormField label="First name" name="firstname" type="text"
                             value={this.state.textInputFirstName}
                             onChange={event => this.setState({textInputFirstName: event.target.value})}
                             required/>

                  <FormField label="Last name" name="lastname" type="text"
                             value={this.state.textInputLastName}
                             onChange={event => this.setState({textInputLastName: event.target.value})}
                             required/>

                  <Box direction="row" gap="small" align="center">
                    <Button type="submit" primary label="Update" disabled={this.state.updatingName}/>
                    {this.state.updatingName && <Text color={"status-unknown"}>Saving...</Text>}
                    {this.state.updatingNameSuccess && <Text color={"status-ok"}>Saved!</Text>}
                  </Box>
                </Form>
              </Box>
            </AccordionPanel>
            <AccordionPanel label={<RichLabel icon={<Lock/>} label={"Reset password"}/>}>
              <Box pad="medium" align="center" gap="small">
                <Button label={"Send password reset link"} onClick={this.openPasswordResetConfirmation}/>
                <Text textAlign="center">An email with instructions will be sent to <Text
                  color="accent-2">{this.state.student.username}</Text>.</Text>
              </Box>
            </AccordionPanel>
            <AccordionPanel label={<RichLabel icon={<Trash/>} label={"Remove student from course"}/>}>
              <Box pad="medium" align="center" gap="small">
                <Button disabled={this.state.updatingRemove} onClick={this.openRemoveStudentConfirmation}
                        label={"Remove student from this course"}/>
                <Text textAlign="center">
                  <Text
                    color="accent-2">{this.state.student.firstname + " " + this.state.student.lastname}</Text>'s work,
                  grades, and presence in your class will be deleted.
                  This student will remain enrolled in other courses.
                </Text>
              </Box>
            </AccordionPanel>
          </Accordion>
          {this.state.openRemoveStudentConfirmation &&
          <YesNoModal onClickYes={this.deleteStudent} onSelectNo={this.closeRemoveStudentConformation}/>
          }
          {this.state.resetConfirm &&
          <YesNoModal onClickYes={this.sendPasswordResetLink}
                      onSelectNo={() => this.setState({resetConfirm: false})}
                      bodyText={<Paragraph>Are you sure you want to send a password reset link
                        to <b>{this.state.student.username}</b>?</Paragraph>}
          />
          }
        </ModalSkeleton>
        }

      </Box>
    )
  }
}

StudentActionButton.propTypes = {
  appServices: Models.appServices.isRequired,
  initialStudent: PropTypes.object.isRequired
};

export default StudentActionButton