//react
import React from "react"
import PropTypes from "prop-types";
//util
import Pluralize from "react-pluralize";
import _ from "lodash";
//grommet
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Form,
  Grid,
  Heading,
  Layer,
  Paragraph,
  Stack,
  Tab,
  Tabs,
  Text,
  TextArea,
  TextInput
} from "grommet";
import {AddCircle, CaretNext, CaretPrevious, FastForward, Rewind, SubtractCircle, Undo} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import Loading from "./Loading";
import File from "./File";
import QuestionText from "./QuestionText";
import Submit from "./Submit";
import QuestionStatus from "./QuestionStatus";

import RiskReportFileCompare from "./RiskReportFileCompare";
import CharacterByCharacterCombined from "./CharacterByCharacterCombined";
import Breadcrumb from "./Breadcrumb";


class GradeDetail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showingQuestion: this.props.whichQuestion, activeIndexes: [2],
      riskReport: [],
      activeTab: 0
    };

    this.changeQuestion = this.changeQuestion.bind(this);


    this.incrementPoints = () => this.setPointsAwarded(this.state.pointsAwarded + 1);
    this.decrementPoints = () => this.setPointsAwarded(this.state.pointsAwarded - 1);
    this.scoreIsValid = (v) => v >= 0 && v < 1000;
    this.textInputIsValid = () => this.scoreIsValid(this.state.pointsAwarded);
    this.setPointsAwarded = this.setPointsAwarded.bind(this);
    this.updateFeedback = this.updateFeedback.bind(this);

    this.approve = this.approve.bind(this);
    this.undo = this.undo.bind(this);
    this.approved = () => !this.state.recommendedGrades.needsApproval;
    this.feedbackPanelIsOpen = () => this.state.activeIndexes && this.state.activeIndexes.includes(0);

    this.saveComment = this.saveComment.bind(this);
    this.setupRiskReport = this.setupRiskReport.bind(this);

    this.hasRisks = this.hasRisks.bind(this);
    this.setupStudentPositions = this.setupStudentPositions.bind(this);
    this.entireAssignmentIsExtraCredit = this.entireAssignmentIsExtraCredit.bind(this);
  }

  changeQuestion(index) {
    this.setState({showingQuestion: index})
  }

  setupStudentPositions(studentList) {

    let currentStudentIndex = studentList.findIndex(student => student.key === this.props.enrollmentKey);
    let previousStudentIndex = currentStudentIndex - 1;
    let nextStudentIndex = currentStudentIndex + 1;

    this.setState({
      positionLabel: (currentStudentIndex + 1) + " of " + studentList.length,
      firstStudentKey: previousStudentIndex >= 1 ? studentList[0].key : null,
      previousStudentKey: previousStudentIndex >= 0 ? studentList[previousStudentIndex].key : null,
      nextStudentKey: nextStudentIndex < studentList.length ? studentList[nextStudentIndex].key : null,
      lastStudentKey: nextStudentIndex < studentList.length - 1 ? studentList[studentList.length - 1].key : null,
    });
  }

  componentDidMount() {
    //get answer to first question and assignment data
    //this is an attempt at optimization: we get the first question only, then after load, we get the rest
    HyperGrade.services.getStudentWork(
      this.props.enrollmentKey,
      this.props.assignmentID,
      true,
      this.state.showingQuestion
    ).then(res => {
      if (res.success) {
        this.setupStudentPositions(res.data.studentList);
        let recPts = res.data.recommendedGrades.recommended_points;
        //handles the case where the teacher made a comment but didn't approve the grade yet
        let textInputValue = Number.parseFloat(
          res.data.grade && res.data.grade.points !== null ?
            res.data.grade.points : (recPts ? recPts : 0));

        this.setState({
          ...res.data, loading: false,
          pointsAwarded: textInputValue,
          pointsAwardedOriginal: textInputValue,
          feedback: res.data.grade.comment
        }, () => {

          if (Array.isArray(this.state.questionIDs)) {
            this.setupRiskReport(this.state.questionIDs.join(","));
          }

          if (this.state.questionIDs.length > 1) {

            let otherQuestions = this.state.questionIDs.map((val, index) => index);
            otherQuestions.shift(); //get every other question (we don't want question 0 again);
            let strOtherQuestions = otherQuestions.join(",");

            HyperGrade.services.getStudentWork(
              this.props.enrollmentKey,
              this.props.assignmentID,
              false,
              strOtherQuestions
            ).then(moreQuestionsResponse => {
              let fullQuestionList = _.cloneDeep(this.state.questionList).concat(moreQuestionsResponse.data.questionList);
              this.setState({questionList: fullQuestionList});
            });
          } else {

          }

        });
      } else {
        console.log("HyperGrade: Could not get student work");
      }
    });
  }

  setupRiskReport(questionIDs) {
    HyperGrade.services.generateRiskReport(this.props.enrollmentKey, questionIDs).then(server => {
      if (server.success) {
        // let updatedReport = this.state.riskReport ? _.cloneDeep(this.state.riskReport) : [];
        // updatedReport.push({questionID: server.data});
        // this.setState({riskReport: updatedReport});
        this.setState({riskReport: server.data});
      } else {
        this.props.showErrorNotification("Could not generate plagiarism report");
      }
    });
  }

  saveComment() {

    let process = (msg) => {
      this.setState({feedbackSaving: true});
      HyperGrade.services.saveComment(this.state.student.key, this.state.assignment.id, this.state.feedback).then((server) => {
        if (server.success) {
          this.props.showSuccessNotification(msg ? msg : "Comment has been saved");
          //ensure we have a consistent state
          let grade = this.state.grade;
          if (grade) {
            grade.comment = this.state.feedback;
          } else {
            grade = {comment: this.state.feedback};
          }
          this.setState({grade, activeIndexes: null});
        } else {
          this.props.showErrorNotification("Comment was not saved");
        }
        this.setState({feedbackSaving: false});
      });
    };

    if (this.approved()) {
      process();
    } else {
      this.approve(false).then(process.bind(this, "Score has been approved and the comment has been saved."));
    }
  }

  updateFeedback(feedback) {
    this.setState({feedback});
  }

  setPointsAwarded(v) {
    v = v ? v : 0;
    if (v >= 0 && v < 1000) {
      this.setState({pointsAwarded: v});
    }
  }

  approve(showNotification = true) {
    this.setState({updatingScore: true});
    return HyperGrade.services.approve(this.state.student.key,
      this.state.assignment.id,
      this.state.pointsAwarded).then(res => {
      let update = _.cloneDeep(this.state.recommendedGrades);
      update.needsApproval = false;
      //update the grade locally
      let grade = this.state.grade;
      if (grade) {
        grade.points = this.state.pointsAwarded;
      } else {
        grade = {points: this.state.pointsAwarded};
      }
      this.setState({
        recommendedGrades: update,
        updatingScore: false,
        pointsAwardedOriginal: this.state.pointsAwarded,
        grade
      });
      if (showNotification) {
        this.props.showSuccessNotification("Grade has been updated");
      }
    });
  }

  undo() {
    HyperGrade.services.deleteScore(this.state.student.key, this.state.assignment.id).then(res => {
      let update = _.cloneDeep(this.state.recommendedGrades);
      update.needsApproval = true;
      this.setPointsAwarded(update.recommended_points);
      this.setState({recommendedGrades: update});
    });
  }

  static hasRisks(riskReport, questionID) {
    //the == is intential because Object keys will return an array of strings, but questionID is a string
    return riskReport && Object.keys(riskReport).findIndex(key => key === questionID) >= 0;
  }

  hasRisks(questionID) {
    return GradeDetail.hasRisks(this.state.riskReport, questionID);
  }

  entireAssignmentIsExtraCredit() {
    return !(this.state.assignment.assignment_total_points > 0);
  }

  render() {

    if (this.state.loading) {
      return <Loading/>;
    }

    let workObj = this.state.questionList[this.state.showingQuestion];

    let panelProps = {pad: {horizontal: "medium", vertical: "small"}};

    let casesPassed = Submit.getNumCasesPassed(workObj.runResults);
    let totalCases = workObj.test_cases.length;

    let result;

    // the english sounds weird with 0, 1, and 2
    if (casesPassed === totalCases && totalCases > 2) {
      result = <Text>All <Pluralize singular={"test case"} count={totalCases}/> passed!</Text>;
    } else {
      result = <Text>{casesPassed} of <Pluralize singular={"test case"} count={totalCases}/> passed</Text>;
    }

    let borderColor;
    if (this.state.updatingScore) {
      borderColor = "status-unknown";
    } else if (this.approved()) {
      borderColor = "status-ok";
    } else {
      borderColor = "status-error";
    }
    let border = [{side: "top", size: "large", color: borderColor}];

    let riskReportForQuestion = null;
    if (this.state.riskReport && this.state.riskReport[workObj.id]) {
      riskReportForQuestion = this.state.riskReport[workObj.id];
    }

    let gradePercentage;
    if (this.entireAssignmentIsExtraCredit()) {
      gradePercentage = "100%"
    } else {
      gradePercentage = (this.state.pointsAwarded / this.state.assignment.assignment_total_points * 100).toFixed(1) + "%";
    }

    return (
      <Box>
        <Box pad="medium" elevation="large">
          <Breadcrumb
            crumbs={[
              {label: this.state.course.name, href: "/course/" + this.state.course.id + "/assignment-editor"},
              {label: "Grades", href: "/course/" + this.state.course.id + "/grades"},
            ]}
          />
        </Box>
        <Stack anchor="top-right">
          <Box border={border} pad="small" elevation="large" className="approval-control-panel">
            <Grid columns={["1/3", "1/3", "1/3"]}>
              {/*left box*/}
              <Box justify="center">
                <QuestionStatus assignment={this.state.assignment} questionList={this.state.questionList}
                                changeQuestion={this.changeQuestion} selectedQuestion={this.state.showingQuestion}
                                riskReport={this.state.riskReport}
                />
              </Box>
              {/*middle box*/}
              <Box align="center" justify="center">
                <Heading level="3"
                         margin="none">{this.state.student.firstname + " " + this.state.student.lastname}</Heading>
                <Box direction="row">
                  <Box width="xxsmall">
                    {this.state.firstStudentKey &&
                    <Button icon={<Rewind/>} title={"First student"}
                            href={"/grade/" + this.state.firstStudentKey + "/" + this.state.assignment.id}/>
                    }
                  </Box>
                  <Box width="xxsmall">
                    {this.state.previousStudentKey &&
                    <Button icon={<CaretPrevious/>} title="Previous student"
                            href={"/grade/" + this.state.previousStudentKey + "/" + this.state.assignment.id}/>
                    }
                  </Box>
                  <Box align="center" justify="center" width="xsmall">
                    <Text>{this.state.positionLabel}</Text>
                  </Box>
                  <Box width="xxsmall">
                    {this.state.nextStudentKey &&
                    <Button icon={<CaretNext/>} title="Next student"
                            href={"/grade/" + this.state.nextStudentKey + "/" + this.state.assignment.id}/>
                    }
                  </Box>
                  <Box width="xxsmall">
                    {this.state.lastStudentKey &&
                    <Button icon={<FastForward/>} title="Last student"
                            href={"/grade/" + this.state.lastStudentKey + "/" + this.state.assignment.id}/>
                    }
                  </Box>
                </Box>
              </Box>
              {/*right box*/}
              <Box direction="row" justify="center" align="center" gap="medium">

                {/*approval box*/}
                <Box justify="center" align="center" gap="small">
                  <Box align="center">
                    <Box direction="row" align="center" margin={{bottom: "xxsmall"}}>
                      <Button icon={<SubtractCircle/>} onClick={this.decrementPoints}/>
                      <Box width="xsmall"
                           border={[
                             {
                               side: "bottom",
                               size: "medium",
                               color: this.textInputIsValid() ? undefined : "status-critical"
                             },
                             {side: "top", size: "medium", color: "transparent"}
                           ]}
                           pad={{horizontal: "small"}}>
                        <TextInput
                          id="points-awarded-input"
                          plain
                          placeholder={this.state.pointsAwarded === "" ? this.state.recommendedGrades.recommended_points : undefined}
                          value={this.state.pointsAwarded}
                          onFocus={(event) => event.target.select()}
                          onChange={(event) => this.setPointsAwarded(event.target.value)}
                        />
                      </Box>
                      <Button icon={<AddCircle/>} onClick={this.incrementPoints}/>
                    </Box>
                    <Heading margin="none" level="4">
                      {this.entireAssignmentIsExtraCredit() ? "Extra Credit" : this.state.assignment.assignment_total_points}
                    </Heading>
                  </Box>
                  <Box direction="row">
                    <Button primary label={this.approved() ? "Update" : "Approve"}
                            onClick={this.approve}
                            disabled={
                              !this.textInputIsValid()
                              || this.state.updatingScore
                              || (this.approved() && this.state.pointsAwarded === this.state.pointsAwardedOriginal)
                            }
                    />
                  </Box>
                </Box>
                {/*grade percentage*/}
                <Box justify="center" align="center" gap="small">
                  <Heading margin="none">{gradePercentage}</Heading>
                </Box>
              </Box>
            </Grid>
          </Box>
          {this.approved() &&
          <Box title="Undo approval" margin={{vertical: "xsmall"}} animation="fadeIn">
            <Button icon={<Undo/>} onClick={this.undo}/>
          </Box>
          }
        </Stack>

        <Box direction="row" gap="medium" align="center" justify="center" margin={{top: "medium", bottom: "small"}}>
          <Heading level={2} margin="none">Question {this.state.showingQuestion + 1}</Heading>
          {result}
        </Box>
        {workObj.files.length === 0 &&
        <Box align="center">No files were submitted for this question.</Box>
        }
        {workObj.files.length > 0 &&
        <Tabs activeIndex={this.state.activeTab} onActive={activeTab => this.setState({activeTab})}>
          <Tab title="Files">
            <Box {...panelProps}>
              {workObj.files.map(file => <File key={'GradeDetail-file-' + file.id} {...file} />)}
            </Box>
          </Tab>
          <Tab title="Output">
            <Box {...panelProps} gap="large">
              {workObj.type === "file_submission" &&

              <Paragraph textAlign={"center"}>This question was not automatically graded so there is no
                output.</Paragraph>
              }
              {workObj.type !== "file_submission" && workObj.test_cases.map((testCase, index) => {

                let studentAttempt = workObj.runResults.find(current => current.id === testCase.id);


                if (!studentAttempt) {
                  return <Box>
                    <Heading level="4">{"Test Case " + (index + 1)}</Heading>
                    <Paragraph margin="none">No output for this test case.</Paragraph>
                  </Box>;
                } else {

                  let teacherOutput = testCase.outputMixed ? testCase.outputMixed : testCase.output;
                  let studentOutput = studentAttempt.outputMixed ? studentAttempt.outputMixed : studentAttempt.output;

                  return (
                    <CharacterByCharacterCombined
                      key={"submit-tc-" + testCase.id}
                      header={"Test Case " + (index + 1)}
                      teacherOutput={teacherOutput}
                      studentOutput={studentOutput}
                      passed={studentAttempt.matches > 0}
                      plain={studentAttempt.matches > 0}
                      command_line_arguments={testCase.command_line_arguments}
                    />
                  );
                }
              })
              }
            </Box>
          </Tab>
          <Tab title="Question">
            <Box {...panelProps}>
              <QuestionText points={workObj.points} questionObj={workObj}/>
            </Box>
          </Tab>
          <Tab title="Plagiarism">
            <Box {...panelProps}>
              {
                <RiskReportFileCompare studentFiles={workObj.files} student={this.state.student}
                                       riskReport={riskReportForQuestion}/>
              }
            </Box>
          </Tab>
        </Tabs>
        }

        <Layer modal={false} position="bottom-right" margin={{right: "large"}}>
          <Box border={this.feedbackPanelIsOpen() ? undefined : {color: "accent-1"}}>
            <Accordion activeIndex={this.state.activeIndexes}
                       onActive={(activeIndexes => this.setState({activeIndexes}))}>
              <AccordionPanel label="Comment">
                <Form onSubmit={this.saveComment}>
                  <Box pad={{vertical: "small", horizontal: "small"}}>
                    <Box gap="small">
                      <Box width="large" height="small" border={{color: "dark-3"}}>
                        <TextArea
                          fill={true}
                          plain
                          name="feedback"
                          size="large"
                          placeholder="Feedback for student"
                          value={this.state.feedback ? this.state.feedback : ""}
                          onChange={event => this.updateFeedback(event.target.value)}
                        />
                      </Box>
                      <Box direction="row" justify="end">
                        <Button type="submit"
                                label={this.approved() ? "Save" : "Approve grade and save comment"}
                                primary disabled={this.state.feedbackSaving}/>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </AccordionPanel>
            </Accordion>
          </Box>
        </Layer>
      </Box>
    )
  }
}

GradeDetail.propTypes = {
  enrollmentKey: PropTypes.string.isRequired,
  assignmentID: PropTypes.number.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  showSuccessNotification: PropTypes.func.isRequired,
};

GradeDetail.defaultProps = {
  whichQuestion: 0
};

export default GradeDetail