//react
import React from "react"
import PropTypes from "prop-types";
import Models from "../../models/appServices";
import classNames from "classnames";

import {ReactComponent as ReactLogo} from '../../images/logo.svg'
import HyperGrade from "../../hg-config";
import {Close, Menu} from "grommet-icons";
import LogoButton from "./LogoButton";

class MenuBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      flyoutActive: false
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.openFlyout = this.openFlyout.bind(this);
  }

  componentDidMount() {
    HyperGrade.util.registerForScrollEvents(this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    HyperGrade.util.unregisterForScrollEvents(this.handleScroll)
  }

  handleScroll() {
    let scrollTop = HyperGrade.util.getScrollPosition();
    if (this.state.scrolled !== (scrollTop > 0)) {
      this.setState({scrolled: scrollTop > 0});
    }
  }

  openFlyout() {
    this.setState({flyoutActive: true});
  }

  render() {

    return (
      <div>
        <nav className={classNames(
          'main-nav',
          'desktop',
          {'fixed': this.props.fixed},
          {'scrolled': this.state.scrolled}
        )}>
          <div className={"nav-inner"}>
            <div className={"left"}>
              <a href={"/"}>
                <ReactLogo className={"logo"}/>
              </a>
            </div>

            <div className={"right"}>
              <ul>
                <li><a href={"/register"} className={"hg-button-2"}>Register</a></li>
                <li><a href={"/login"} className={"hg-button-1"}>Login</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className={classNames(
          'main-nav',
          'mobile',
          {'fixed': this.props.fixed},
          {'scrolled': this.state.scrolled},
        )}>
          <div className={"nav-inner"}>
            <div className={"left"}>
              <a href={"/"}>
                <ReactLogo className={"logo"}/>
              </a>
            </div>

            <div className={"right"}>
              <button onClick={this.openFlyout}><Menu/></button>
            </div>
          </div>
          <div className={
            classNames(
              "flyout",
              {'active': this.state.flyoutActive}
            )
          }>
            <div className={"flyout-inner"}>
              <div className={"close-container"}>
                <LogoButton />
                <button onClick={() => this.setState({flyoutActive: false})}><Close/></button>
              </div>
              <div className={"actions"}>
                <ul className={"links"}>
                  <li><a href={"/support"}>Support</a></li>
                  <li><a href={"/privacy"}>Privacy Policy</a></li>
                </ul>
              </div>
              <hr/>
              <div className={"ctas"}>
                <a href={"/register"} className={"hg-button-1"}>Register</a>
                <a href={"/login"} className={"hg-button-2"}>Login</a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

MenuBar.propTypes = {
  appServices: Models.appServices.isRequired,
  fixed: PropTypes.bool
};

MenuBar.defaultProps = {
  fixed: false
};

export default MenuBar