//react
import React from "react"
import {ReactComponent as ReactLogo} from "../../images/logo.svg";
import PropTypes from "prop-types";

//hypergrade

class LogoButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }


  render() {
    return (
      <a href={this.props.where}>
        <ReactLogo className={"logo"}/>
      </a>
    )
  }
}

LogoButton.propTypes = {
  where: PropTypes.string
};

LogoButton.defaultProps = {
  where: "/"
};

export default LogoButton