//react
import React from "react"
import {Redirect} from "react-router-dom";
import HyperGrade from "../../hg-config";
import Loading from "../Loading";
import PropTypes from "prop-types";


//hypergrade

class FormLogin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };

    this.manage = HyperGrade.util.manage.bind(this);

    this.getPageThatSentUsHere = () => this.props.location && this.props.location.state && this.props.location.state.from;
    this.inSilentMode = () => (this.props.username && this.props.password) || this.props.accessCode || this.props.account;

    this.loginWithAccessCode = this.loginWithAccessCode.bind(this);
    this.loginCallback = this.loginCallback.bind(this);
    this.postRegisterAction = HyperGrade.postRegisterAction.bind(this);

    this.submitLoginForm = this.submitLoginForm.bind(this);
    this.loginWithAccessCode = this.loginWithAccessCode.bind(this);

    this.hideBadCredentialsMessage = () => this.setState({badCredentials: false});
    this.showBadCredentialsMessage = () => this.setState({badCredentials: true});

  }

  componentDidMount() {

    if (this.props.notification) {
      this.props.showSuccessNotification(this.props.notification, HyperGrade.notificationAutoCloseTimeout * 3);
    }

    if (this.inSilentMode()) {
      if (this.props.accessCode) {
        this.loginWithAccessCode(this.props.accessCode);
      } else if (this.props.account && !this.getPageThatSentUsHere()) { //if we're already logged in, just go home
        this.setState({redirect: "/home"});
      } else {
        this.submitLoginForm(this.props.username, this.props.password);
      }
    }
  }

  loginWithAccessCode(accessCode) {
    HyperGrade.services.loginWithAccessCode(accessCode).then(this.loginCallback);
  }

  submitLoginForm(username, password) {
    HyperGrade.services.login(username, password).then(this.loginCallback);
  }

  loginCallback(res) {

    //if successfully logged in
    if (res.data) {
      let userObj = res.data;

      //"proceed" will happen after timezone check
      let proceed = () => {
        this.props.endWorking();
        if (this.props.onSuccess) {
          this.props.onSuccess(userObj);
        } else if (this.getPageThatSentUsHere()) {
          console.log(userObj);
          this.props.updateUserInfo(userObj);
          let {state, ...rest} = this.props.location.state.from;
          this.setState({redirect: rest});
        } else {
          this.postRegisterAction(userObj);
        }
      };

      //if we don't have a timezone yet
      if (!userObj.timezone) {
        //try to detect one
        userObj.timezone = HyperGrade.util.detectMyTimezone();
        if (userObj.timezone) {
          //tell HyperGrade
          HyperGrade.services.setTimezone(userObj.timezone).then(proceed);
        } else {
          proceed();
        }
      } else {
        proceed();
      }

    } else { //incorrect username/password
      this.props.updateUserInfo(); //clear user data (set object to undefined
      //if we're in silent mode and the login failed, then we have a really weird problem
      //ex: forgot password -> successful password reset -> autologin with accepted password -> fail
      if (this.inSilentMode()) {
        this.setState({redirect: "/login"});
      } else {
        this.props.showErrorNotification("Incorrect email or password");
      }
    }


  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.inSilentMode()) {
      return <Loading/>;
    }

    return (
      <div id={"login-form"} className={"form-frame-theme"}>
        <div className={"lead"}>
          <h1>Sign In</h1>
          <p>New user? <a href={"/register"}>Create an account</a></p>
        </div>
        <form method={"post"} onSubmit={(e) => {
          e.preventDefault();
          this.submitLoginForm(this.state.email, this.state.password)
        }}>
          <div className={"form-group"}>
            <label htmlFor={"email"}>Email</label>
            <input type={"email"} name={"email"} id={"email"} {...this.manage('email')} required/>

          </div>

          <div className={"form-group"}>
            <label>Password</label>
            <input type={"password"} id={"password"} name={"password"} {...this.manage('password')} required/>
            <p className={"helper-text"}><a href={"/forgot-password"}>Forgot Password?</a></p>
          </div>

          <div className={"form-group"}>
            <input className={"hg-button-1"} type={"submit"} value={"Sign In"}/>
          </div>
        </form>
        <p>Have a question? <a href={"/support"}>Contact us</a>.</p>
      </div>
    )
  }
}

FormLogin.propTypes = {
  updateUserInfo: PropTypes.func.isRequired
};

FormLogin.defaultProps = {

};

export default FormLogin