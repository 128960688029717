//react
import React from "react"
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
//grommet
import {Box, Button} from "grommet";
import {Add} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';

import * as moment from "moment";
import Models from "../models/appServices";

class CreateAssignmentButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.createAssignment = this.createAssignment.bind(this);
  }

  componentDidMount() {
  }

  createAssignment() {
    this.props.appServices.beginWorking("Creating the assignment");
    HyperGrade.services.createAssignment(this.props.courseID).then(server => {
      if (server.success) {
        let newAssignment = server.data;

        //in quick mode, we automatically set a due date, make a question, then move to the editor
        if (this.props.quick) {
          let dueDate = moment().add(7, 'd');
          HyperGrade.services.changeDueDate(newAssignment.id, dueDate).then(dueDateResponse => {
            HyperGrade.services.createQuestion(newAssignment.id).then(questionResponse => {
              this.setState({redirect: '/question/' + questionResponse.data + '/edit'});
            });
          });
        }
      } else {
        this.props.appServices.showErrorNotification("Could not create the assignment");
      }
      this.props.appServices.endWorking();
    });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    return (
      <Box animation={this.props.quick ? "pulse" : undefined}>
        <Button primary={this.props.quick} label={"Create assignment"} icon={<Add/>} onClick={this.createAssignment}/>
      </Box>
    )
  }
}

CreateAssignmentButton.propTypes = {
  courseID: PropTypes.number.isRequired,
  quick: PropTypes.bool,
  appServices: Models.appServices.isRequired,
};

CreateAssignmentButton.defaultProps = {
  quick: false
};

export default CreateAssignmentButton