import PropTypes from "prop-types";

let Models = {
  account: PropTypes.any,
  appServices: PropTypes.shape({
    showSuccessNotification: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    beginWorking: PropTypes.func.isRequired,
    endWorking: PropTypes.func.isRequired,
    openGenericModal: PropTypes.func.isRequired,
    updateUserInfo: PropTypes.func.isRequired,
    saveEmail: PropTypes.func.isRequired,
    saveRealName: PropTypes.func.isRequired,
    saveTimeZone: PropTypes.func.isRequired,
  })
};

export default Models;