//react
import React from "react"
import {Redirect} from "react-router-dom";
//grommet
import {Box, Heading, Tab, Tabs} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';

import Loading from "./Loading";
import Course from "./Course";
import ModalSkeleton from "./ModalSkeleton";
import AssignmentProgressCard from "./AssignmentProgressCard";

class StudentHome extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      lateDayResultModal: null
    };

    this.useLateDays = this.useLateDays.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    HyperGrade.services.getMyCourses().then(res => {
      //if the student is in some courses
      if (res.data && res.data.length) {
        this.setState({courses: res.data, loading: false});
      } else {
        this.setState({redirect: "/enroll"});
      }
    });
  }

  useLateDays(key, assignmentID, howMany) {
    return HyperGrade.services.useLateDays(key, assignmentID, howMany).then(res => {

      //the course calls it's own refresh

      this.setState({
        lateDayResultModal: {
          key,
          lateDaysRemaining: res.data.lateDaysRemaining,
          assignment: res.data.assignment
        }
      });
    });
  }

  render() {

    //if the student isn't enrolled in any courses, then they will be redirected
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    if (this.state.loading) {
      return <Loading/>;
    }

    let lateDayResult = null;

    if (this.state.lateDayResultModal) {
      lateDayResult = (
        <ModalSkeleton onClose={() => this.setState({lateDayResultModal: null})}>
          <Box align="center">
            <Heading level={"3"} textAlign={"center"}>The assignment has been unlocked.</Heading>
          </Box>
          <AssignmentProgressCard
            assignment={this.state.lateDayResultModal.assignment}
            lateDaysRemaining={this.state.lateDayResultModal.lateDaysRemaining}
            useLateDays={this.useLateDays.bind(this, this.state.lateDayResultModal.key)}
          />

        </ModalSkeleton>
      );
    }

    let courseList;

    if (this.state.courses.length === 1) {
      courseList = this.state.courses.map(course => <Course enrolledCourse={course}
                                                            useLateDays={this.useLateDays.bind(this, course.key)}
                                                            key={"enrolled-course-" + course.id}/>);
    } else if (this.state.courses.length > 1) {
      courseList =
        <Box margin="medium">
          <Tabs>
            {this.state.courses.map(course =>
              <Tab title={course.name} key={"tab-course-" + course.id}>
                <Course key={"enrolled-course-" + course.id}
                        enrolledCourse={course}
                        useLateDays={this.useLateDays.bind(this, course.key)}/>
              </Tab>
            )}
          </Tabs>
        </Box>
      ;
    }

    return <Box>{courseList}{lateDayResult}</Box>;

  }
}

StudentHome.propTypes = {};

StudentHome.defaultProps = {};

export default StudentHome