//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Heading, Paragraph, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import {Notes} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';

class StudentGradeBox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.nothingHasBeenGraded = () => this.props.grades.length === 0;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render() {

    let rowNum = 0, cellPad = "small";

    let body, overall;
    if (this.nothingHasBeenGraded()) {
      body =
        <Paragraph textAlign="center" color="neutral-3">Grades haven't been posted yet. Check back later.</Paragraph>
    } else {
      overall = <Text>Overall: <b>{this.props.courseGrade.percent}</b></Text>;

      body = <Table>
        <TableHeader>
          <TableRow>
            <TableCell pad={cellPad} scope="col" border="bottom">Assignment</TableCell>
            <TableCell pad={cellPad} scope="col" border="bottom">Due Date</TableCell>
            <TableCell pad={cellPad} scope="col" border="bottom" align="center">Points Earned</TableCell>
            <TableCell pad={cellPad} scope="col" border="bottom" align="center">Assignment Total</TableCell>
            <TableCell pad={cellPad} scope="col" border="bottom" align="center">Percent</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {this.props.grades.map(grade => {
            let rows = [];

            let localDueDate = HyperGrade.util.convertUTCToLocal(grade.due_dateUTC);

            rows.push(
              <TableRow key={rowNum++}>
                <TableCell pad={cellPad}>{grade.name}</TableCell>
                <TableCell pad={cellPad}>
                  {localDueDate.format("LLLL")} ({localDueDate.fromNow()})
                </TableCell>
                <TableCell pad={cellPad} scope="row" align="center">{grade.points}</TableCell>
                <TableCell pad={cellPad} align="center">{grade.assignment_total_points}</TableCell>
                <TableCell pad={cellPad}
                           align="center">{(grade.points / grade.assignment_total_points * 100).toFixed(1)}%</TableCell>
              </TableRow>
            );

            if (grade.comment) {
              rows.push(
                <TableRow key={rowNum++}>
                  <TableCell pad={{horizontal: "medium"}} colSpan={5}>
                    <Box direction="row" gap="small" margin={{horizontal: "small"}}>
                      <Notes/>
                      <Box background="light-6" pad="small">
                        <pre>{grade.comment}</pre>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            }

            return rows;
          })}

          <TableRow>
            <TableCell border="top" colSpan={2} align="right">Overall</TableCell>
            <TableCell border="top" align="center"><strong>{this.props.courseGrade.course_points}</strong></TableCell>
            <TableCell border="top"
                       align="center"><strong>{this.props.courseGrade.total_course_points}</strong></TableCell>
            <TableCell border="top" align="center"><strong>{this.props.courseGrade.percent}</strong></TableCell>
          </TableRow>

        </TableBody>

      </Table>
    }

    return (
      <Box gap="medium">
        <Box direction="row" justify="between">
          <Heading level="3" margin="none">{this.props.course.name}</Heading>
          {overall}
        </Box>
        {body}
      </Box>
    )
  }
}

StudentGradeBox.propTypes = {
  course: PropTypes.shape({
    key: PropTypes.string,
    used_by: PropTypes.string,
    id: PropTypes.number,
    late_days: PropTypes.number,
    name: PropTypes.string.isRequired,
  }),
  grades: PropTypes.arrayOf(PropTypes.shape({
    assignment_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.number,
    assignment_total_points: PropTypes.number, //todo
    comment: PropTypes.string,
    approved: PropTypes.bool.isRequired,
    due_dateUTC: PropTypes.string
  })),
  courseGrade: PropTypes.shape({
    course_points: PropTypes.number,
    total_course_points: PropTypes.number,
    percent: PropTypes.string,
    course_name: PropTypes.string,
  }).isRequired
};

StudentGradeBox.defaultProps = {};

export default StudentGradeBox