//react
import React from "react"

import HyperGrade from "../../hg-config";
import Models from "../../models/appServices";
import PropTypes from "prop-types";

class ContactUs extends React.Component {

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.appServices.beginWorking();
    HyperGrade.services.sendSupportEmail(e.target.name.value, e.target.email.value, e.target.text.value).then(server => {
      this.props.appServices.endWorking();
      if (server.success) {
        document.getElementById('contact-us-form').reset();
        this.props.appServices.openSuccessModal("Your message has been sent! We will respond shortly.");
      } else {
        this.props.appServices.openFailureModal("Your message could not be sent. Please contact us directly at " + HyperGrade.supportEmail);
      }
    });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} id={"contact-us-form"}>
        <div className={"form-group"}>
          <label htmlFor={"name"}>Name</label>
          <input type={"text"} required name={"name"} placeholder={"Name"} />
        </div>
        <div className={"form-group"}>
          <label htmlFor={"email"}>Email</label>
          <input type={"email"} required name={"email"} placeholder={"E-mail"}/>
        </div>
        <div className={"form-group"}>
          <textarea name={"text"} required placeholder={"How can we help"}/>
        </div>
        <div className={"form-group"}>
          <input type={"submit"} value={"Submit"}/>
        </div>
      </form>
    )
  }
}

ContactUs.propTypes = {
  appServices: Models.appServices.isRequired,
  account: PropTypes.object
};

ContactUs.defaultProps = {};

export default ContactUs