//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Heading, Paragraph, Table, TableBody, TableCell, TableHeader, TableRow} from "grommet";
//hypergrade
import ModalSkeleton from "./ModalSkeleton";

import 'react-gh-like-diff/dist/css/diff2html.min.css';

import File from "./File";


class RiskReportFileCompare extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showDiff: false
    };

    this.setShowDiff = (showDiff) => this.setState({showDiff});
    this.showDiff = this.showDiff.bind(this);
  }

  componentDidMount() {
  }

  showDiff(currentFile, otherFile) {
    this.setShowDiff(true);
    this.setState({currentFile, otherFile}, () => {

    });
  }

  render() {

    if (!this.props.riskReport) {
      return <Box key={'no-issues'} align="center">
        <Paragraph>No issues detected.</Paragraph>
      </Box>;
    }

    let compareWidth = "large";

    return (
      <Box>
        {this.props.studentFiles.map(studentFile => {

          let report = this.props.riskReport[studentFile.id];

          return (
            <Box key={'issues-' + studentFile.id} align="center" gap="small">
              <Paragraph color="neutral-1" margin="none" fill={true}>Similarity doesn't necessarily indicate
                cheating.</Paragraph>
              <Paragraph margin="none" fill={true}><b>{this.props.student.firstname}</b>'s file <b>{studentFile.name}</b> is similar
                to</Paragraph>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col" border="bottom">
                      Filename
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      Similarity
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      Student
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      Compare
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {report.map(entry =>
                    <TableRow key={"other-file-id" + entry.otherFile.id}>
                      <TableCell scope="row">{entry.otherFile.name}</TableCell>
                      <TableCell>{Math.round(entry.similarity) + '%'}</TableCell>
                      <TableCell>{entry.otherFile.firstname + " " + entry.otherFile.lastname}</TableCell>
                      <TableCell><Anchor onClick={() => this.showDiff(studentFile, entry.otherFile)}>Show
                        difference</Anchor></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          );
        })}

        {this.state.showDiff &&
        <ModalSkeleton onClose={this.setShowDiff.bind(this, false)} full={true}>
          <Box justify="center" wrap={true} direction="row" gap="large" fill>
            <Box width={compareWidth}>
              <Heading
                level="3">{this.props.student.firstname + " " + this.props.student.lastname + "'s file (current student)"}</Heading>
              <File {...this.state.currentFile}/>
            </Box>
            <Box width={compareWidth}>
              <Heading
                level="3">{this.state.otherFile.firstname + " " + this.state.otherFile.lastname + "'s file (other student)"}</Heading>
              <File {...this.state.otherFile}/>
            </Box>
          </Box>
        </ModalSkeleton>
        }

      </Box>
    )
  }
}

RiskReportFileCompare.propTypes = {
  questionID: PropTypes.number,

};

RiskReportFileCompare.defaultProps = {};

export default RiskReportFileCompare