//react
import React from "react"
//grommet
import {Box, Button, Heading, Paragraph} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';

import Loading from "./Loading";
import {Add} from "grommet-icons";
import Models from "../models/appServices";
import CourseOverview from "./CourseOverview";

class CourseList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.onCreateCourse = this.onCreateCourse.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    return HyperGrade.services.getCoursesOverview().then((response) => {
      this.setState({accountInfo: response.data});
    });
  }

  onCreateCourse() {
    HyperGrade.services.createCourse().then(result => {
      this.props.appServices.beginWorking("Creating the course...");
      this.refresh().then(() => {
        this.props.appServices.endWorking();
      });
    });
  }

  render() {

    if (!this.state.accountInfo) {
      return <Loading/>;
    }

    let label = "Create a course";

    let createCourseButton = <Button label={label} icon={<Add/>} onClick={this.onCreateCourse}/>;

    let helper = null;
    if (this.state.accountInfo.courses.length === 0) {

      helper =
        <Box align="center" gap="medium">
          <Heading level="2" margin="none">Welcome to HyperGrade.</Heading>
          <Box width="large">
            <Paragraph textAlign="center" margin="none">Let's begin automating your assignments.
              Below, we've created a sample course that you can experiment with.
              The assignments in this course are a mixture of <b>{HyperGrade.supportedLanguagesString}</b>.
            </Paragraph>
          </Box>
        </Box>;
    }

    return (
      <Box margin="medium" className="course-list" gap="medium">
        {helper}
        <Box gap="medium" align="center">
          {createCourseButton}
          {this.state.accountInfo.courses.map(course => <CourseOverview course={course}
                                                                        key={'co-' + course.id}
                                                                        appServices={this.props.appServices}
                                                                        refresh={this.refresh}/>)}
        </Box>
      </Box>
    )
  }
}

CourseList.propTypes = {
  account: Models.account.isRequired,
  appServices: Models.appServices.isRequired
};

CourseList.defaultProps = {};

export default CourseList