//react
import React from "react"
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
//npm
import Pluralize from 'react-pluralize'
import _ from 'lodash';
//grommet
import {Anchor, Box, Button, Collapsible, Grid, Heading, Layer, Paragraph} from "grommet";
import {Add, NewWindow, Refresh} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';

import TestCaseEditor from "./TestCaseEditor";
import Loading from "./Loading";
import GrommetDropzone from "./GrommetDropzone";
import CompilationError from "./CompilationError";
import QuestionSelect from "./QuestionSelect";
import StopLight from "./StopLight";
import GrommetDraftJS from "./GrommetDraftJS";
import FileTable from "./FileTable";
import Breadcrumb from "./Breadcrumb";
import TrainingMessage from "./TrainingMessage";

import sampleFiles from '../hg-sample-files';
import HyperGradeAlert from "./HyperGradeAlert";
import TooltipButton from "./TooltipButton";
import ModalSkeleton from "./ModalSkeleton";

class QuestionEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.onDrop = this.onDrop.bind(this);

    this.updateStudentOutput = this.updateStudentOutput.bind(this);
    this.compileAndRunAll = this.compileAndRunAll.bind(this);
    this.daisyChain = this.daisyChain.bind(this);

    this.refresh = this.refresh.bind(this);
    this.makeAnotherQuestion = this.makeAnotherQuestion.bind(this);
    this.getNumberOfTestCases = this.getNumberOfTestCases.bind(this);
    this.updateTestCaseList = this.updateTestCaseList.bind(this);
    this.updateTestCaseSingle = this.updateTestCaseSingle.bind(this);
    this.deleteTestCase = this.deleteTestCase.bind(this);
    this.moveTestCase = this.moveTestCase.bind(this);
    this.moveTestCaseUp = this.moveTestCase.bind(this, -1);
    this.moveTestCaseDown = this.moveTestCase.bind(this, +1);
    this.canMakeNewTestCase = this.canMakeNewTestCase.bind(this);
    this.saveQuestionText = this.saveQuestionText.bind(this);
    this.updateFileList = this.updateFileList.bind(this);
    this.questionIsReady = this.questionIsReady.bind(this);
    this.determineNextStep = this.determineNextStep.bind(this);
    this.questionIsPristine = this.questionIsPristine.bind(this);
    this.openTestCaseModal = this.openTestCaseModal.bind(this);
    this.noTestCases = () => this.state.test_cases.length === 0;
    this.closeStudentUpdateCompleteModal = this.closeStudentUpdateCompleteModal.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //if a new question has been created
    if (prevProps.questionID !== this.props.questionID) {
      this.refresh();
    }
  }

  closeStudentUpdateCompleteModal() {
    this.props.endWorking();
    this.setState({showStudentWorkUpdateCompleteModal: false});
  }

  compileAndRunAll() {
    this.props.beginWorking(HyperGrade.workingMessage1);
    let car = HyperGrade.compileAndRunAll.bind(this, false, false);
    return car()
      .then(() => {
        if (!this.state.compilationError) {
          return this.updateStudentOutput().then(() => this.refresh(false));
        } else {
          this.props.endWorking();
          return this.refresh();
        }
      });
  }

  updateStudentOutput() {
    return HyperGrade.services.getStudentsThatHaveAttempted(this.props.questionID).then(res => this.daisyChain(res.data));
  }

  daisyChain(arr, index = 0) {
    if (index < arr.length) {
      this.props.beginWorking("Re-running student work submission " + (index + 1) + " of " + arr.length);
      return HyperGrade.services.compileAndRunAll(this.props.questionID, arr[index].key).then(() => this.daisyChain(arr, index + 1));
    } else {
      //if we actually did something
      if (arr.length) {
        this.setState({showStudentWorkUpdateCompleteModal: true});
      } else {
        this.props.endWorking();
      }
      return Promise.resolve();
    }
  }

  saveQuestionText(contentState) {
    return HyperGrade.services.updateQuestionText(this.props.questionID, contentState)
      .then(server => {
          if (server.success) {
            this.setState({editorStatus: 'All changes saved.'});
          } else {
            this.props.showErrorNotification("Could not save the question text");
          }
        }
      )
  }

  questionIsPristine() {
    return this.state.files.length === 0 && this.state.test_cases.length === 0;
  }

  determineNextStep() {

    // if (this.questionIsPristine()) {
    //   return false;
    // }

    if (!HyperGrade.util.arrContainsSupportedCodeExtension(this.state.files)) {
      return false; //in this step, the dropzone gets big and has directions
      //return <Paragraph>{"Upload a " + HyperGrade.supportedLanguagesString2 + " file."}</Paragraph>
    } else if (this.state.compilationError) {
      return <Paragraph>Fix the compilation error below</Paragraph>;
    } else if (this.state.test_cases.length === 0) {
      return <Paragraph>Next up, <Anchor onClick={this.openTestCaseModal}>create a test case</Anchor></Paragraph>;
    }
  }

  openTestCaseModal() {
    this.setState({addTestCaseModal: true});
  }

  questionIsReady() {
    return HyperGrade.util.arrContainsSupportedCodeExtension(this.state.files)
      && this.state.test_cases.length > 0
      && !this.state.compilationError
      && this.state.test_cases.findIndex(tc => tc.returnCode !== 0) === -1
      ;
  }

  refresh(loading = true) {
    this.setState({loading, redirect: false, showEditor: false});
    return HyperGrade.services.getQuestionComponents(this.props.questionID).then(server => {
      if (server.success) {
        //we received all the questions, so we get the one we're currently editing
        let questionBeingEdited = server.data.questions.find(current => current.id === this.props.questionID);
        //save
        this.setState({
          ...server.data,
          compilationError: questionBeingEdited.compileResult,
          questionBeingEdited,
          editorStatus: ''
        });

        let contentState = GrommetDraftJS.decodeContentState(questionBeingEdited);

        //if we have some question text from the server, then show the editor
        this.setState({contentState, showEditor: !!contentState});
      } else {
        this.props.showErrorNotification("Could not load the question parts.");
      }
      this.setState({loading: false});
    });
  }

  updateFileList(files, rebuild = true) {
    this.setState({files});
    if (rebuild)
      this.compileAndRunAll(); //calls endWorking
  }

  //the web service calls are done in TestCaseEditor
  //this method just updates the DOM
  updateTestCaseList(test_cases) {
    this.setState({test_cases});
  }

  //the web service calls are done in TestCaseEditor
  //this method just updates the DOM
  updateTestCaseSingle(testCaseDatabaseObj) {
    //update the test
    let updatedTestCases = _.cloneDeep(this.state.test_cases);
    let testCaseIndex = updatedTestCases.findIndex(current => current.id === testCaseDatabaseObj.id);
    //if it's an existing case
    if (testCaseIndex >= 0) {
      updatedTestCases[testCaseIndex] = testCaseDatabaseObj;
    } else { //if it's a new case
      updatedTestCases.push(testCaseDatabaseObj);
    }

    this.setState({test_cases: updatedTestCases});
  }

  moveTestCase(delta, testCaseID) {

    let updatedTestCases = HyperGrade.util.moveByID(
      testCaseID,
      _.cloneDeep(this.state.test_cases),
      delta);

    this.setState({test_cases: updatedTestCases});

    HyperGrade.services.rearrangeTestCases(updatedTestCases.map(current => current.id)).then(res => this.compileAndRunAll());
  }

  /*
  acceptedFiles looks like
  [
    {
      lastModified: 1592348011515
      lastModifiedDate: Tue Jun 16 2020 15:53:31 GMT-0700 (Pacific Daylight Time) {}
      name: "basic.py"
      path: "basic.py"
      size: 204
      type: "text/plain"
      webkitRelativePath: ""
    },
    //more file objects
  ]
  */

  onDrop(acceptedFiles) {
    this.props.beginWorking(HyperGrade.workingMessage1);
    HyperGrade.services.storeFileForQuestion(this.props.questionID, acceptedFiles).then(server => {
      if (server.success) {
        let updatedFiles = _.cloneDeep(this.state.files);
        server.data.forEach(current => {
          let index = updatedFiles.findIndex(file => file.name === current.name);
          let newFile = _.cloneDeep(current);
          newFile.isNewOrUpdated = true;
          if (index >= 0) {
            updatedFiles[index] = newFile;
          } else {
            updatedFiles.push(newFile);
          }
        });
        this.setState({files: updatedFiles});
        //compile will call "endWorking"
        this.compileAndRunAll();
      } else {
        this.props.endWorking();
        this.props.showErrorNotification("File upload failed.");
      }
    });
  }

  getNumberOfTestCases() {
    return Array.isArray(this.state.test_cases) ? this.state.test_cases.length : 0;
  }

  closeAddTestCaseModal = () => {
    this.setState({addTestCaseModal: false});
  };

  deleteTestCase(id) {
    this.props.beginWorking();
    this.closeAddTestCaseModal();
    HyperGrade.services.deleteTestCase(id).then(res => this.compileAndRunAll()).then(() => {

    });
  }

  makeAnotherQuestion() {
    HyperGrade.services.createQuestion(this.state.assignment.id).then(server => {
      if (server.success) {
        this.setState({redirect: '/question/' + server.data + '/edit'});
      } else {
        this.props.showErrorNotification("Could not create a question for this assignment.");
      }
    });
  }

  canMakeNewTestCase() {
    return !this.state.compilationError && this.state.files.length > 0;
  }

  //lang can be: cpp, cs, java, py
  makeSampleQuestion(lang) {
    this.setState({loading: true});
    let promises = [];

    //upload the files
    sampleFiles[lang].files.forEach(file =>
      promises.push(HyperGrade.services.storeFileForQuestionFromString(this.props.questionID, file.name, file.content))
    );

    //afterwards, upload the test cases
    Promise.all(promises).then(() => {

      let morePromises = []
      //inefficient: each called to createTestCase will compile and run the code
      sampleFiles[lang].testCases.forEach(testCase =>
        morePromises.push(HyperGrade.services.createTestCase(this.props.questionID, testCase.stdin, testCase.cla))
      );

      Promise.all(morePromises).then(this.refresh);
    });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    if (this.state.loading) {
      return <Loading/>;
    }

    let nextStep = this.determineNextStep();

    let cannotAddNewTestCase = !this.questionIsPristine() && !this.questionIsReady() && this.state.test_cases.length > 0;

    let addTestCaseButton = <Button icon={<Add/>} className="t3" label="Add test case"
                                    primary={this.noTestCases()}
                                    disabled={cannotAddNewTestCase}
                                    onClick={() => {
                                      this.setState({addTestCaseModal: true});
                                    }}/>;

    let addTestCase =
      <Box direction="row" align={"center"} gap={"small"}>
        <Box animation={this.noTestCases() ? "pulse" : undefined}>
          {addTestCaseButton}
        </Box>
        {cannotAddNewTestCase && <Paragraph color={"status-error"}>Fix errors before adding a new test case</Paragraph>}
      </Box>;

    let helpfulMessage = "Upload a " + HyperGrade.supportedLanguagesString2 + " file. If your code needs other files, upload those too.";

    let stopLightStatus = this.questionIsPristine() ? "none" : this.questionIsReady();

    return (
      <Box>
        {this.state.studentsThatHaveAttempted.length > 0 &&
          <HyperGradeAlert>
            <Paragraph>Students have attempted this question. If changes are made, each student's code will be re-run.
              That may take a few minutes.</Paragraph>
          </HyperGradeAlert>
        }
        <Box direction="row" flex>
          <StopLight status={stopLightStatus}/>
          <Box flex>
            <Box direction="row" align="center" gap={"xsmall"} margin={{horizontal: "medium", vertical: "small"}}>
              <Breadcrumb crumbs={[
                {
                  label: this.state.course.name,
                  href: "/course/" + this.state.assignment.course_id + "/assignment-editor"
                },
                {
                  label: this.state.assignment.name,
                  href: "/course/" + this.state.assignment.course_id + "/assignment-editor/" + this.state.assignment.id
                },
                {
                  label: "Question "
                }
              ]}/>
              <QuestionSelect action="edit"
                              showLink={false}
                              selectedQuestionID={this.props.questionID}
                              assignment={this.state.assignment}
                              questions={this.state.questions}/>
            </Box>
            <Box margin="medium" gap="small">

              <Box>
                <Collapsible open={!!nextStep}>
                  <TrainingMessage>
                    {nextStep}
                  </TrainingMessage>
                </Collapsible>

                <Box direction="row" align="end" justify="between">
                  <Heading level="2" margin="none">Edit Question</Heading>

                  <Box align="center" direction={"row"} gap={"small"}>
                    {this.questionIsReady() &&
                      <Box animation="pulse">
                        <Button target="_blank" href={"/assignment/" + this.state.assignment.id} icon={<NewWindow/>}
                                primary
                                label={"View as student"}/>
                      </Box>
                    }
                    {this.questionIsReady() &&
                      <Button icon={<Add/>} label={"Make another question"} onClick={this.makeAnotherQuestion}/>}
                  </Box>
                </Box>

              </Box>

              <GrommetDropzone onDrop={this.onDrop}
                               height={this.state.files.length === 0 ? "medium" : "small"}
                               message={helpfulMessage}
              />

              {/*for completely empty questions (not exactly the definition of pristine*/}
              {this.questionIsPristine() &&
                <Box align="center">
                  <Heading level="3">
                    Or, start off with an example
                  </Heading>
                  <Grid columns={{count: 4, size: "auto"}} gap="small">
                    <Button label="C++" onClick={this.makeSampleQuestion.bind(this, 'cpp')}/>
                    <Button label="C#" onClick={this.makeSampleQuestion.bind(this, 'cs')}/>
                    <Button label="Java" onClick={this.makeSampleQuestion.bind(this, 'java')}/>
                    <Button label="Python" onClick={this.makeSampleQuestion.bind(this, 'py')}/>
                  </Grid>
                </Box>
              }


              <Collapsible open={!!this.state.compilationError}>
                <CompilationError message={this.state.compilationError}/>
              </Collapsible>

              <FileTable files={this.state.files}
                         role={"teacher"}
                         updateFileList={this.updateFileList}
                         {...this.props} //provide notifications
              />

              <Collapsible open={!this.state.showEditor && this.canMakeNewTestCase()}>
                <Box direction="row">
                  <Button icon={<Add/>}
                          label={"Add question text"}
                          onClick={() => this.setState({showEditor: true})}/>
                </Box>
              </Collapsible>

              <Collapsible open={this.state.showEditor}>
                <Box gap="small">
                  <Box direction="row" gap="small" align="end">
                    <Heading level="3" margin="none">Question Text</Heading>
                    <Paragraph color="dark-2" margin="none">{this.state.editorStatus}</Paragraph>
                  </Box>
                  <GrommetDraftJS startingContentState={this.state.contentState}
                                  save={this.saveQuestionText}
                                  updateStatus={editorStatus => this.setState({editorStatus})}
                                  placeholder={"Enter question text"}
                  />
                </Box>
              </Collapsible>

              {this.canMakeNewTestCase() && addTestCase}

              {/*all the test cases*/}
              <Collapsible open={this.state.test_cases.length > 0}>
                <Box margin={{vertical: "medium"}}>
                  <Box direction="row" gap="small" align="end" margin={{bottom: "small"}}>
                    <Heading level="3" margin="none">Test Cases</Heading>
                    <Paragraph color="dark-2" margin="none">
                      <Pluralize singular={'test case'} count={this.state.test_cases.length}/>
                    </Paragraph>
                  </Box>
                  <Box gap="large">
                    {this.state.test_cases.map((testCase, index) =>
                      <TestCaseEditor key={"TestCaseEditor" + testCase.id}
                                      disabled={!!this.state.compilationError}
                                      questionID={this.props.questionID}
                                      onMoveUp={this.moveTestCaseUp.bind(this, testCase.id)}
                                      onMoveDown={this.moveTestCaseDown.bind(this, testCase.id)}
                                      index={index + 1}
                                      totalNumTestCases={this.getNumberOfTestCases()}
                                      deleteTestCase={this.deleteTestCase}
                                      updateTestCaseList={this.updateTestCaseList}
                                      updateTestCaseSingle={this.updateTestCaseSingle}
                                      beginWorking={this.props.beginWorking}
                                      endWorking={this.props.endWorking}
                                      testCase={testCase}
                                      showSuccessNotification={this.props.showSuccessNotification}
                                      showErrorNotification={this.props.showErrorNotification}
                      />
                    )}
                  </Box>
                </Box>
              </Collapsible>

              {this.canMakeNewTestCase() && this.state.test_cases.length > 0 && addTestCase}
            </Box>

            {this.questionIsReady() &&
              <Box direction="row" justify="end" margin={{horizontal: "small"}} pad="small">
                <TooltipButton onClick={this.compileAndRunAll} icon={<Refresh color={"light-6"}/>}
                               text={"Re-compile and run all"}/>
              </Box>
            }

          </Box>
          <StopLight status={stopLightStatus}/>
        </Box>
        {this.state.showStudentWorkUpdateCompleteModal &&
          <ModalSkeleton onClose={this.closeStudentUpdateCompleteModal}>
            <Box gap="medium" align="center">
              <Heading level="3" color="status-ok" margin="none">Success!</Heading>
              <Paragraph margin="none">All student work has been updated.</Paragraph>
              <Button label="OK" onClick={this.closeStudentUpdateCompleteModal}/>
            </Box>
          </ModalSkeleton>
        }
        {this.state.addTestCaseModal &&
          <Layer modal
                 padding="small"
                 position="center"
                 animation="fadeIn"
                 onEsc={this.closeAddTestCaseModal}
                 onClickOutside={this.closeAddTestCaseModal}>
            <Box width="xxlarge">
              <TestCaseEditor index={this.getNumberOfTestCases() + 1}
                              totalNumTestCases={this.getNumberOfTestCases()}
                              beginWorking={this.props.beginWorking}
                              endWorking={this.props.endWorking}
                              questionID={this.props.questionID}
                              deleteTestCase={this.deleteTestCase}
                              onEditingComplete={this.closeAddTestCaseModal}
                              updateTestCaseList={this.updateTestCaseList}
                              updateTestCaseSingle={this.updateTestCaseSingle}
                              outputBoxSize="medium"
                              modal={true}
                              showSuccessNotification={this.props.showSuccessNotification}
                              showErrorNotification={this.props.showErrorNotification}
              />
            </Box>
          </Layer>
        }
      </Box>
    )
  }
}

//note: all props are being passed to FileTable
QuestionEditor.propTypes = {
  questionID: PropTypes.number.isRequired
};

export default QuestionEditor