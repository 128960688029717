//react
import React from "react"
//grommet
import {Box, Button, Grid, Paragraph, TextInput} from "grommet";
import {Add, Subtract} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import ModalSkeleton from "./ModalSkeleton";

//lodash

class LateDays extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            initialLateDayCount: props.student.late_days,
            setLateDayValueModal: false
        };

        this.onClickUp = this.onClickUp.bind(this);
        this.onClickDown = this.onClickDown.bind(this);
        this.setNumLateDays = this.setNumLateDays.bind(this);
        this.problemWithNumLateDays = this.problemWithNumLateDays.bind(this);

        this.notifyParent = this.notifyParent.bind(this);
    }

    notifyParent(value, absolute = false) {
        let intOriginalLateDays = parseInt(this.props.student.late_days, 10);
        let intValue = parseInt(value, 10);
        let late_days = parseInt((absolute ? 0 : intOriginalLateDays) + intValue);
        this.props.updateStudent(this.props.student.username, {...this.props.student, late_days});
    }

    problemWithNumLateDays() {
        let showAbsolutePointValueError = this.state.absolutePointValue < 0 || this.state.absolutePointValue > 365;
        this.setState({showAbsolutePointValueError});
        return showAbsolutePointValueError;
    }

    setNumLateDays() {
        if (!this.problemWithNumLateDays()) {
            this.props.appServices.beginWorking();
            HyperGrade.services.setLateDaysForStudent(this.props.student.key, this.state.absolutePointValue).then(() => {
                this.notifyParent(this.state.absolutePointValue, true);
                this.setState({setLateDayValueModal: false});
                this.props.appServices.endWorking();
            })
        }
    }

    onClickUp() {
        this.notifyParent(+1);
        HyperGrade.services.incrementLateDayForStudent(this.props.student.key);
    }

    onClickDown() {
        this.notifyParent(-1);
        HyperGrade.services.decrementLateDayForStudent(this.props.student.key);
    }

    render() {
        return (
            <Grid columns={{count: 3, size: "auto"}}>
                <Box align="end">
                    <Button disabled={this.props.student.late_days === 0} icon={<Subtract/>}
                            onClick={this.onClickDown}/>
                </Box>
                <Box align="center" justify="center">
                    <Button onClick={() => {
                        this.setState({setLateDayValueModal: true, absolutePointValue: this.props.student.late_days, showAbsolutePointValueError: false})
                    }} label={"" + this.props.student.late_days}/>
                </Box>
                <Box>
                    <Button icon={<Add/>} onClick={this.onClickUp}/>
                </Box>
                {
                    this.state.setLateDayValueModal &&
                    <ModalSkeleton onClose={() => this.setState({setLateDayValueModal: false})}
                                   instructions={"Set late days for " + this.props.student.firstname + " " + this.props.student.lastname}>
                        <Box align="center" justify={"center"} gap={"small"}>
                            <Box width={"xsmall"}>
                                <TextInput
                                    style={{textAlign: "center"}}
                                    value={this.state.absolutePointValue}
                                    autoFocus={true}
                                    onChange={(event) => {
                                        this.setState({absolutePointValue: event.target.value}, () => {
                                            this.problemWithNumLateDays();
                                        });
                                    }}
                                    onFocus={(event) => event.target.select()}
                                />
                            </Box>
                            <Box width={"xsmall"}>
                                <Button primary={true} label={"Save"} onClick={this.setNumLateDays} disabled={this.state.showAbsolutePointValueError}/>
                            </Box>
                            {this.state.showAbsolutePointValueError &&
                                <Paragraph color={"status-critical"}>Must be a positive, whole number.</Paragraph>
                            }
                        </Box>

                    </ModalSkeleton>
                }
            </Grid>
        )
    }
}

export default LateDays