//react
import React from "react"
import PropTypes from "prop-types";
//npm
import {
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  RichUtils
} from 'draft-js';
import {Map} from 'immutable';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _ from "lodash";
//grommet
import {Anchor, Box, Button} from "grommet";
import {
  BlockQuote,
  Bold,
  Code,
  Italic,
  Link,
  Monospace,
  OrderedList,
  StrikeThrough,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  Underline,
  Unlink,
  UnorderedList
} from 'grommet-icons';

import '../../node_modules/draft-js/dist/Draft.css'

import TextQuestionModal from "./TextQuestionModal";
import HyperGrade from "../hg-config";

const styleMap = {
  'STRIKETHROUGH': {
    textDecoration: 'line-through',
  },
  'MONOSPACE': {
    fontFamily: '"Courier New", Courier, monospace'
  },
};

function generateBlockRenderMap() {
  let rmap = {};
  DefaultDraftBlockRenderMap.forEach((v, k) => {
    rmap[k + '-left'] = _.cloneDeep(v);
    rmap[k + '-left'].wrapper = <Box align="start"/>;

    rmap[k + '-center'] = _.cloneDeep(v);
    rmap[k + '-center'].wrapper = <Box align="center"/>;

    rmap[k + '-right'] = _.cloneDeep(v);
    rmap[k + '-right'].wrapper = <Box align="end"/>;
  });

  return DefaultDraftBlockRenderMap.merge(Map(rmap));
}

class GrommetDraftJS extends React.Component {

  static decodeContentState(questionObj, defaultText = HyperGrade.defaultText) {
    let contentState = null;
    if (questionObj.draftContentState) {
      contentState = convertFromRaw(questionObj.draftContentState);
    } else if (questionObj.text || defaultText) {
      let blocksFromHTML = convertFromHTML(questionObj.text ? questionObj.text : defaultText);
      contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
    }
    return contentState;
  }

  constructor(props) {
    super(props);

    //contentState and editorState are not the same thing
    //contentState must be converted to editorstate
    //contentState -> decode -> createWithContent -> editorState

    let startingContentState;

    if (props.startingContentState) {
      startingContentState = props.startingContentState;
    } else if (props.questionObj) {
      startingContentState = GrommetDraftJS.decodeContentState(props.questionObj);
    }

    //if we're given some pre-existing content, make the editor with that content
    let editorState = startingContentState ?
      EditorState.createWithContent(startingContentState, GrommetDraftJS.createDecorator())
      : EditorState.createEmpty(GrommetDraftJS.createDecorator());

    this.state = {
      askUserForURL: false,
      editorState
    };

    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.basicStyleClick = this.basicStyleClick.bind(this);
    this.toggleBlockType = this.toggleBlockType.bind(this);
    this.toggleLink = this.toggleLink.bind(this);
    this.confirmLink = this.confirmLink.bind(this);
    this.removeLink = this.removeLink.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getCurrentBlockType = this.getCurrentBlockType.bind(this);
    this.setAlignment = this.setAlignment.bind(this);

    if (this.props.save) {
      this.saveDraftStateDebounce = AwesomeDebouncePromise(this.props.save, 1000);
    }

    this.renderMap = generateBlockRenderMap();
  }

  async onChange(editorState) {
    this.setState({editorState});
    //if the parent wants some text status
    if (this.props.updateStatus) {
      this.props.updateStatus('Saving...');
    }
    //if the parent wants to save the content
    if (this.saveDraftStateDebounce) {
      await this.saveDraftStateDebounce(convertToRaw(editorState.getCurrentContent()));
    }
  }

  static createDecorator() {
    return new CompositeDecorator([
      {
        strategy: function (contentBlock, callback, contentState) {
          contentBlock.findEntityRanges(
            (character) => {
              const entityKey = character.getEntity();
              return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
              );
            },
            callback
          );
        },

        component: function (props) {
          const {url} = props.contentState.getEntity(props.entityKey).getData();
          return <Anchor href={url}>{props.children}</Anchor>;
        },
      },
    ]);
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  basicStyleClick(style) {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  }

  static blockToCSS(contentBlock) {
    const typeParts = GrommetDraftJS.splitBlockName(contentBlock.getType());

    if (typeParts.base === 'blockquote') {
      return 'blockquote';
    }

  }

  toggleBlockType(newBlockType) {

    let oldBlockParts = GrommetDraftJS.splitBlockName(this.getCurrentBlockType());

    //we're toggling this block type off
    if (newBlockType === oldBlockParts.base) {
      //go to unstyled but keep the direction
      newBlockType = 'unstyled';
    }

    if (oldBlockParts.direction) {
      newBlockType += "-" + oldBlockParts.direction;
    }

    this.onChange(RichUtils.toggleBlockType(this.state.editorState, newBlockType));
  }

  toggleLink() {
    this.setState({askUserForURL: true});
  }

  removeLink() {
    const selection = this.state.editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.onChange(RichUtils.toggleLink(this.state.editorState, selection, null));
    }
  }

  confirmLink(url) {
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {url});
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(this.state.editorState, {currentContent: contentStateWithEntity});
    this.onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));

    this.setState({askUserForURL: false});
  }

  getCurrentBlockType() {
    const selection = this.state.editorState.getSelection();
    return this.state.editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
  }

  static splitBlockName(blockType) {
    //determine if it already has a direction appended to it
    let blockHasDirection = ['left', 'center', 'right'].reduce((pre, curr) => pre || blockType.endsWith(curr), false);
    let currentDir = "";
    //if there's a direction then drop it
    if (blockHasDirection) {
      let prefixPosition = blockType.lastIndexOf('-');
      currentDir = blockType.substring(prefixPosition + 1);
      blockType = blockType.substring(0, prefixPosition)
    }
    return {
      direction: currentDir,
      base: blockType
    };
  }

  //dir: LEFT, CENTER, RIGHT
  setAlignment(dir) {

    let blockParts = GrommetDraftJS.splitBlockName(this.getCurrentBlockType());

    let updatedBlockType = blockParts.base;
    //if this is not a toggle-off
    if (blockParts.direction !== dir)
      updatedBlockType += "-" + dir;

    //tell draftjs about the new block
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, updatedBlockType));
  }

  render() {

    // construct the inline styles
    const currentStyle = this.state.editorState.getCurrentInlineStyle();
    let activeColor = "accent-2";

    let inlineButtons = [
      {type: 'CODE', icon: <Code/>},
      {type: 'BOLD', icon: <Bold/>},
      {type: 'ITALIC', icon: <Italic/>},
      {type: 'UNDERLINE', icon: <Underline/>},
      {type: 'STRIKETHROUGH', icon: <StrikeThrough/>},
      {type: 'MONOSPACE', icon: <Monospace/>},
    ];
    //end inline style construction

    // construct the block buttons
    const blockType = this.getCurrentBlockType();

    let blockButtons = [
      {type: 'unstyled', label: 'Plain'},
      //{type: 'paragraph', label: 'P'}, //not working (no p tag)
      {type: 'header-one', label: 'H1'},
      {type: 'header-two', label: 'H2'},
      {type: 'header-three', label: 'H3'},
      {type: 'header-four', label: 'H4'},
      {type: 'header-five', label: 'H5'},
      {type: 'header-six', label: 'H6'},
      {type: 'blockquote', icon: <BlockQuote/>},
      {type: 'unordered-list-item', icon: <UnorderedList/>},
      {type: 'ordered-list-item', icon: <OrderedList/>},
    ];
    //end block button construction

    //alignment buttons
    let alignmentButton = [
      {type: 'left', icon: <TextAlignLeft/>},
      {type: 'center', icon: <TextAlignCenter/>},
      {type: 'right', icon: <TextAlignRight/>},
    ];

    let editor = <Editor
      editorState={this.state.editorState}
      handleKeyCommand={this.handleKeyCommand}
      readOnly={this.props.readOnly}
      onChange={this.onChange}
      placeholder={this.props.placeholder}
      blockStyleFn={GrommetDraftJS.blockToCSS}
      blockRenderMap={this.renderMap}
      customStyleMap={styleMap}
    />;

    if (this.props.readOnly)
      return <Box className="grommet-draftjs">{editor}</Box>;

    return (
      <Box border>
        <Box direction="row" border="bottom" align="center" background="light-3" gap="medium"
             pad={{horizontal: "small"}}>

          <Box direction="row" gap="small">
            {blockButtons.map(curr => {

                let isActive = blockType.startsWith(curr.type);
                let icon = isActive && curr.icon ? React.cloneElement(curr.icon, {color: activeColor}) : curr.icon;

                return <Button onClick={this.toggleBlockType.bind(this, curr.type)}
                               key={"rte-" + curr.type}
                               label={curr.label}
                               color={isActive ? activeColor : undefined}
                               plain
                               icon={icon}
                />
              }
            )}
          </Box>

          <Box direction="row">
            {alignmentButton.map(curr => {
              let isActive = blockType.endsWith(curr.type);
              let icon = isActive && curr.icon ? React.cloneElement(curr.icon, {color: activeColor}) : curr.icon;

              return <Button key={"rte-" + curr.type} onClick={this.setAlignment.bind(this, (curr.type))} icon={icon}/>
            })}
          </Box>

          <Box direction="row">
            <Button onClick={this.toggleLink.bind(this)} icon={<Link/>} title="Insert a link"/>
            <Button onClick={this.removeLink} icon={<Unlink/>} title="Remove a link"/>
          </Box>

          <Box direction="row">
            {inlineButtons.map(curr => {

                let isActive = currentStyle.has(curr.type);
                let activeColor = "accent-2";
                let icon = isActive && curr.icon ? React.cloneElement(curr.icon, {color: activeColor}) : curr.icon;

                return <Button onClick={this.basicStyleClick.bind(this, curr.type)}
                               label={curr.label}
                               key={"rte-" + curr.type}
                               color={isActive ? activeColor : undefined}
                               icon={icon}
                />
              }
            )}
          </Box>
        </Box>
        <Box pad="small" overflow={"auto"} height={{min: "260px"}} className="grommet-draftjs">
          {editor}
        </Box>
        {this.state.askUserForURL &&
        <TextQuestionModal onClose={() => this.setState({askUserForURL: false})}
                           onSubmit={(url) => this.confirmLink(url)}
                           type="url"
                           instructions={"Enter a URL"}/>
        }
      </Box>
    )
  }
}

GrommetDraftJS.propTypes = {
  updateStatus: PropTypes.func,
  save: PropTypes.func,
  startingContentState: PropTypes.any,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  questionObj: PropTypes.any
};

GrommetDraftJS.defaultProps = {
  readOnly: false,
  placeholder: "Enter text"
};

export default GrommetDraftJS