//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Button, Form, FormField, Paragraph, TextInput} from "grommet";
import {Mail} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import ModalSkeleton from "./ModalSkeleton";
import ChangeEmail from "./ChangeEmail";
import Models from "../models/appServices";
import Loading from "./Loading";
import RegistrationFlowHeading from "./RegistrationFlowHeading";

class ConfirmEmailForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };

    this.submit = this.submit.bind(this);
    this.sendNewCode = this.sendNewCode.bind(this);
  }

  componentDidMount() {
    HyperGrade.services.needsEmailConfirmation()
      .then(server => {
        if (server.code === "EMAIL_OK") {
          this.props.onSuccess();
        } else {
          this.setState({loading: false});
        }
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this will happen if the user updates their email address
    if (prevProps.account.username !== this.props.account.username) {
      this.sendNewCode();
      this.setState({problem: false});
    }
  }

  submit(data) {
    this.props.appServices.beginWorking();
    HyperGrade.services.submitEmailConfirmationPin(data.emailConfirmPin).then(server => {
      if (server.success) {
        this.props.onSuccess();
      } else {
        this.props.appServices.showErrorNotification("The code is incorrect. Please verify and try again.");
      }
      this.props.appServices.endWorking();
    });
  }

  sendNewCode() {
    this.props.appServices.beginWorking();
    HyperGrade.services.sendEmailConfirmationMessage().then(server => {
      if (server.success) {
        this.props.appServices.showSuccessNotification("A new code has been sent to " + this.props.account.username);
        this.setState({disableNewCodeButton: true});
        setTimeout(() => {
          this.setState({disableNewCodeButton: false});
        }, 120000);
      } else {
        this.props.appServices.showErrorNotification("Could not send a new code. Contact us.");
      }
      this.props.appServices.endWorking();
    });
  }

  render() {

    if (this.state.loading) {
      return <Loading/>;
    }

    return (
      <Box width="large" alignSelf="center" animation="fadeIn">
        <RegistrationFlowHeading icon={<Mail color="accent-4"/>} label={"Email Confirmation"}/>
        <Box background="light-3" pad="medium" round border={{color: "accent-4"}} elevation="large">
          <Form onSubmit={({value}) => this.submit(value)}>
            <FormField name="emailConfirmPin" htmlfor="emailConfirmPin" required
                       label={<Paragraph margin="none">Enter the code we sent
                         to <b>{this.props.account.username}</b></Paragraph>}>
              <TextInput id="emailConfirmPin" name="emailConfirmPin"/>
            </FormField>
            <Box direction="row" justify="between" align="center">
              <Button type="submit" primary label="Submit"/>
              <Paragraph margin="none">
                <Anchor onClick={this.sendNewCode} disabled={this.state.disableNewCodeButton}>Send new
                  code</Anchor> &middot; <Anchor
                onClick={() => this.setState({problem: true})}>Update email address</Anchor>
              </Paragraph>
            </Box>
          </Form>
        </Box>
        {this.state.problem &&
        <ModalSkeleton onClose={() => this.setState({problem: false})}>
          <ChangeEmail account={this.props.account}
                       appServices={this.props.appServices}
                       submitLabel={"Update email and send new code"}
          />
        </ModalSkeleton>
        }
      </Box>
    )
  }
}

ConfirmEmailForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  account: PropTypes.any.isRequired,
  appServices: Models.appServices.isRequired,
};

ConfirmEmailForm.defaultProps = {};

export default ConfirmEmailForm