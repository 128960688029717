//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Heading} from "grommet";
//hypergrade
import Pluralize from "react-pluralize";
import GrommetDraftJS from "./GrommetDraftJS";

class QuestionText extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  render() {
    return (
      <Box margin={{bottom: "small"}} background="light-3" pad="small">
        <Heading level="4" margin="none">
          <Pluralize singular={'point'} count={this.props.points}/>
        </Heading>

        <GrommetDraftJS readOnly={true} questionObj={this.props.questionObj}/>
      </Box>
    )
  }
}

QuestionText.propTypes = {
  points: PropTypes.number.isRequired,
  questionObj: PropTypes.any
};

QuestionText.defaultProps = {};

export default QuestionText