//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Button, Collapsible, DataTable, Paragraph, RadioButton, Text} from "grommet";
import {Drawer, Fireball, Hide, Run, Trash, View} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import YesNoModal from "./YesNoModal";
import _ from "lodash";
import ModalSkeleton from "./ModalSkeleton";
import File from "./File";

class FileTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.getColumns = this.getColumns.bind(this);
    this.deleteFileConfirmation = this.deleteFileConfirmation.bind(this);
    this.closeDeleteFileConfirmation = this.closeDeleteFileConfirmation.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.radioSelect = this.radioSelect.bind(this);
    this.setFileVisibility = this.setFileVisibility.bind(this);
    this.viewFile = this.viewFile.bind(this);
  }

  componentDidMount() {
  }

  radioSelect(event, fileID) {
    this.props.beginWorking();
    if (event.target.checked) {
      HyperGrade.services.setEntryPoint(fileID).then(server => {
        if (server.success) {
          let updatedFileList = _.cloneDeep(this.props.files);
          updatedFileList.forEach(
            file => file.entry_point = file.id === fileID
          );
          this.props.updateFileList(updatedFileList);
        } else {
          this.props.showErrorNotification("Could not set this file as the entry point.");
          this.props.endWorking();
        }
      });
    }
  }

  setFileVisibility(fileID, visibility) {
    this.props.beginWorking();
    HyperGrade.services.setFileVisibility(fileID, visibility).then(server => {
      if (server.success) {
        let updatedFileList = _.cloneDeep(this.props.files);
        //update just the file that has changed
        updatedFileList.forEach(file => file.visible_to_student = file.id === fileID ? visibility : file.visible_to_student);
        this.props.updateFileList(updatedFileList, false);
      } else {
        this.props.showErrorNotification("Could not set file visibility");
      }
      this.props.endWorking();
    });
  }

  deleteFile(fileID) {
    this.props.beginWorking(HyperGrade.workingMessage1);
    this.closeDeleteFileConfirmation();
    HyperGrade.services.deleteFile(fileID, this.props.questionID).then(res => {
      //remove the file visually from the table
      let updatedFileList = this.props.files.filter(file => file.id !== fileID);
      this.props.updateFileList(updatedFileList);
    });
  }

  deleteFileConfirmation(fileID) {
    this.setState({
        pendingDeleteConfirm: true,
        pendingDeleteFile: fileID
      }
    );
  }

  closeDeleteFileConfirmation() {
    this.setState({
        pendingDeleteConfirm: false,
        pendingDeleteFile: undefined
      }
    );
  }

  viewFile(id) {
    this.props.beginWorking();
    HyperGrade.services.getTextFile(id).then(res => {
      this.setState({viewFile: res.data});
      this.props.endWorking();
    });
  }

  getColumns() {
    let fileColumns = [
      {
        property: 'name',
        header: <Text>Filename</Text>,
        primary: true,
        render: row =>
          <Box direction="row" align="center" title="This file is provided by the instructor" gap="xsmall">
            {row.inherited ? <Fireball/> : undefined}
            <Anchor href={HyperGrade.makeFileDownloadLink(row.id)}>
              {row.name}
            </Anchor>
            <Box direction="row" align="center">
              {/*<Button icon={<Download/>} title="Download"/>*/}
              <Button icon={<Drawer/>} onClick={() => this.viewFile(row.id)} title="View"/>
            </Box>
          </Box>
      },
      {
        property: 'size',
        header: <Text>Size</Text>,
        render: row =>
          <Text>{HyperGrade.util.formatBytes(row.size)}</Text>
      },
      {
        property: 'delete',
        header: <Box align="end">Delete</Box>,
        render: row =>
          <Box align="end" pad="xsmall">
            {/*student can't delete file provided by instructors*/}
            <Button plain style={row.inherited ? {visibility: "hidden"} : undefined}
                    icon={<Trash/>}
                    title={"Delete file"}
                    onClick={row.inherited ? undefined : this.deleteFileConfirmation.bind(this, row.id)}/>
          </Box>
      },
    ];

    let inheritedEntryPoint = this.props.files.findIndex(file => file.inherited && file.entry_point) >= 0;
    let numFilesNotInherited = HyperGrade.util.getNumCodeFiles(this.props.files);

    if (inheritedEntryPoint || numFilesNotInherited > 1) {
      fileColumns.splice(2, 0, {
        property: 'run_this_file',
        header: <Box align="center">File to run</Box>,
        render: row => {

          //a teacher provided file with an entry_point overrides the student
          if (row.inherited) {
            if (row.entry_point) {
              return <Box align="center" title="When you submit code, this file will run">
                <Run/>
              </Box>
            } else {
              return null;
            }
          } else if (!inheritedEntryPoint) {
            return <Box align="center">
              {HyperGrade.util.isSupportedCodeExtension(row.name) &&
              <RadioButton checked={!!row.entry_point}
                           name="runnable" //required, but not used
                           onChange={event => {
                             this.radioSelect(event, row.id)
                           }}/>
              }
            </Box>
          }
        }
      });
    }

    if (this.props.role === "teacher") {
      fileColumns.splice(fileColumns.length - 1, 0, {
        property: 'visible_to_student',
        header: <Box align="center">What Happens</Box>,
        render: row =>
          <Box align="center">
            {HyperGrade.util.isSupportedCodeExtension(row.name) &&
            <Button label={row.visible_to_student ? "Visible to student" : "Hidden from student"}
                    icon={row.visible_to_student ? <View/> : <Hide/>}
                    width={"medium"}
                    onClick={() => this.setFileVisibility(row.id, !row.visible_to_student)}
            />
            }
            {!HyperGrade.util.isSupportedCodeExtension(row.name) &&
            <Text>Will be placed in same directory as student's code</Text>
            }
          </Box>

      },);

    }

    return fileColumns;
  }

  render() {

    let filenameToConfirm = null;
    if (this.state.pendingDeleteConfirm) {
      let file = this.props.files.find(file => file.id === this.state.pendingDeleteFile);
      filenameToConfirm = file.name;
    }

    return (
      <Collapsible open={Array.isArray(this.props.files) && this.props.files.length > 0}>
        <DataTable data={this.props.files}
                   step={1000}
                   columns={this.getColumns()}
        />
        {this.state.pendingDeleteConfirm &&
        <YesNoModal onClickYes={this.deleteFile.bind(this, this.state.pendingDeleteFile)}
                    onSelectNo={this.closeDeleteFileConfirmation}
                    bodyText={<Paragraph textAlign="center" margin="none">Selecting 'Yes' will
                      delete <b>{filenameToConfirm}</b>. The code will be recompiled and all test cases
                      will be re-run.</Paragraph>}
        />}
        {this.state.viewFile &&
        <ModalSkeleton onClose={() => this.setState({viewFile: null})} width="xxlarge">
          <File {...this.state.viewFile} />
        </ModalSkeleton>
        }
      </Collapsible>
    )
  }
}

FileTable.propTypes = {
  files: PropTypes.array.isRequired,
  role: PropTypes.oneOf(["teacher", "student"]).isRequired,
  updateFileList: PropTypes.func.isRequired,
  beginWorking: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
};

FileTable.defaultProps = {};

export default FileTable