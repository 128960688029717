//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Grommet, Heading, Paragraph} from "grommet";
import {Wifi} from 'grommet-icons';
//hypergrade
import TallBox from "./Tallbox";

class ErrorScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Grommet theme={this.props.theme} full id="hypergrade-grommet-root">
        <TallBox>
          <Box justify="center" align="center" gap="medium" margin="xlarge">
            <Heading level="2" margin="none">Could not connect to HyperGrade</Heading>
            <Paragraph fill margin="none">{this.props.message}</Paragraph>
            <Wifi size="large" color="accent-4"/>
          </Box>
        </TallBox>
      </Grommet>
    )
  }
}

ErrorScreen.propTypes = {
  message: PropTypes.string.isRequired,
  theme: PropTypes.any.isRequired
};

ErrorScreen.defaultProps = {
  message: "A server error occurred. Please try refreshing."
};

export default ErrorScreen