//react
import React from "react"
//grommet
import {Box, Heading, Paragraph} from "grommet";
import PropTypes from "prop-types";
import Models from "../models/appServices";
import ConfirmEmailForm from "./ConfirmEmailForm";
import ConfirmCourseCodeForm from "./ConfirmCourseCodeForm";
import ConfirmPayment from "./ConfirmPayment";

//hypergrade

class Enroll extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      step: null
    };

    this.goToCourseCodeForm = this.goToCourseCodeForm.bind(this);
  }

  componentDidMount() {

  }

  goToCourseCodeForm(courseCodeError = null) {
    this.setState({step: "COURSE_CODE", courseCodeError});
  }

  render() {

    return (
      <Box align="center" margin={{bottom: "large"}}>
        {!this.props.plain &&
        <Box align="center" width="large" gap="medium" margin={{vertical: "medium"}}>
          <Heading margin="none">Welcome to HyperGrade</Heading>
          <Paragraph fill margin="none">HyperGrade is a tool that helps you manage and test your computer programming
            homework.</Paragraph>
        </Box>
        }

        <Box width="medium" direction="row" justify="between" border="bottom" pad="small">
          <Paragraph margin="none" color={!this.state.step ? "dark-1" : "dark-6"}>
            <strong>Step 1</strong>
          </Paragraph>
          <Paragraph margin="none" color={this.state.step === "COURSE_CODE" ? "dark-1" : "dark-6"}>
            <strong>Step 2</strong>
          </Paragraph>
          <Paragraph margin="none" color={this.state.step === "PAYMENT" ? "dark-1" : "dark-6"}>
            <strong>Step 3</strong>
          </Paragraph>
        </Box>

        {/*step 1*/}
        {!this.state.step &&
        <ConfirmEmailForm
          appServices={this.props.appServices}
          account={this.props.account}
          onSuccess={this.goToCourseCodeForm}
        />}
        {/*step 2*/}
        {this.state.step === "COURSE_CODE" &&
        <ConfirmCourseCodeForm
          courseID={this.props.courseID} //the component will validate this (could be null)
          courseCodeError={this.state.courseCodeError} //step 3 may have pushed us back to step 2 with an error
          appServices={this.props.appServices}
          account={this.props.account}
          onSuccess={(courseCode) => this.setState({step: "PAYMENT", courseCode})}
        />}
        {this.state.step === "PAYMENT" &&
        <ConfirmPayment
          courseID={this.state.courseCode} //from step 2
          appServices={this.props.appServices}
          account={this.props.account}
          goToCourseCodeForm={this.goToCourseCodeForm}
        />
        }
      </Box>
    );
  }
}

Enroll.propTypes = {
  //optional: this will bypass step 2 if the code is valid
  courseID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  account: PropTypes.object.isRequired,
  appServices: Models.appServices.isRequired,
  plain: PropTypes.bool
};

Enroll.defaultProps = {
  plain: false
};

export default Enroll