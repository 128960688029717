//react
import React from "react"
import PropTypes from 'prop-types';
//grommet
import {Box, Button, Heading, Layer, Stack} from "grommet";
import {Close} from 'grommet-icons';

class ModalSkeleton extends React.Component {

  render() {

    let instructionHeader;
    if (this.props.instructions) {
      instructionHeader =
        <Box align="center">
          <Heading margin={{top: "none"}} textAlign="center" level="3">{this.props.instructions}</Heading>
        </Box>;
    }

    return (
      <Layer position="center" animation="fadeIn" modal onClickOutside={this.props.onClose}
             onEsc={this.props.onClose} style={{overflow: "auto"}}
             full={this.props.full}
             margin="medium"
      >
        <Stack anchor="top-right" fill={this.props.full}>
          <Box pad={this.props.pad} width={this.props.width ? this.props.width : undefined} fill={this.props.full}>
            {instructionHeader}
            <Box fill={this.props.full}>
              {this.props.children}
            </Box>
          </Box>
          <Box>
            <Button icon={<Close/>} onClick={this.props.onClose}/>
          </Box>
        </Stack>
      </Layer>)
  }
}

ModalSkeleton.propTypes = {
  instructions: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  full: PropTypes.bool,
  width: PropTypes.string,
  pad: PropTypes.string
};

ModalSkeleton.defaultProps = {
  width: "large",
  pad: "large",
  full: false
};

export default ModalSkeleton