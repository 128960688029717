import React from "react"

class HGFooter extends React.Component {
  
  render() {
    return (
      <footer className={"site-footer"}>
        <div className={"inner-footer"}>
          <div className={"left"}>
            Copyright 2024 HyperGrade, LLC <span className={"separator"}>&middot;</span><br/> All Right Reserved
          </div>
          <div className={"right"}>
            <ul>
              <li>
                <a href={"/support"}>Support</a>
              </li>
              <li>
                <a href={"/privacy"}>Privacy</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

HGFooter.propTypes = {

};

HGFooter.defaultProps = {};

export default HGFooter