//react
import React from "react"
//grommet
import {Anchor, Box, Button, Form, FormField, Grid, Heading, Menu, Paragraph, Stack, Text} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';


import Pluralize from "react-pluralize";
import {Clone, ContactInfo, Cycle, Detach, Erase, Trash} from "grommet-icons";
import Models from "../models/appServices";
import CreateAssignmentButton from "./CreateAssignmentButton";
import PropTypes from "prop-types";
import ModalSkeleton from "./ModalSkeleton";
import LoginAsStudent from "./LoginAsStudent";
import YesNoModal from "./YesNoModal";

class Card extends React.Component {

  static propTypes = {
    href: PropTypes.string.isRequired,
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    alert: PropTypes.node
  }

  render() {
    return (
      <Box hoverIndicator="light-6" round width="small">
        <Stack anchor="top-right">
          <Box pad="small" align="center" justify="center">
            <Button href={this.props.href}><Box className="stat" align="center">{this.props.stat}</Box></Button>
            <Anchor href={this.props.href}>{this.props.children}</Anchor>
          </Box>
          {this.props.alert &&
          <Box margin={{right: "medium", top: "xsmall"}} background="accent-4" round elevation="medium"
               justify="center"
               pad={{horizontal: 'xsmall', vertical: "xxsmall"}}>
            {this.props.alert}
          </Box>
          }
        </Stack>
      </Box>
    )
  }


}

class CourseOverview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseName: this.props.course.name,
      originalCourseName: this.props.course.name
    };

    this.cloneCourse = this.cloneCourse.bind(this);
    this.clearDueDates = this.clearDueDates.bind(this);
    this.clearAllStudents = this.clearAllStudents.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.closeRecycleCourseModal = () => this.setState({recycleCourse: false});
    this.updateCourseName = this.updateCourseName.bind(this);
  }

  componentDidMount() {

  }

  deleteCourse() {
    this.setState({openCourseDeleteConfirm: false})
    HyperGrade.services.deleteCourse(this.props.course.id).then(server => {
      this.props.appServices.showSuccessNotification("The course has been deleted!");
      this.closeRecycleCourseModal();
      this.props.refresh();
    });
  }

  clearAllStudents() {
    this.setState({openStudentClearConfirm: false})
    HyperGrade.services.clearAllStudents(this.props.course.id).then(server => {
      this.props.refresh();
      this.props.appServices.showSuccessNotification("Students have been removed!");
    });
  }

  clearDueDates() {
    this.setState({openDueDateConfirm: false})
    HyperGrade.services.clearAllDueDates(this.props.course.id).then(server => {
      this.props.appServices.showSuccessNotification("Due dates have been cleared!");
    });
  }

  cloneCourse() {
    this.props.appServices.beginWorking();
    HyperGrade.services.copyCourse(this.props.course.id).then(server => {
      this.closeRecycleCourseModal();
      this.props.appServices.endWorking();
      this.props.appServices.showSuccessNotification("The course has been duplicated!");
      this.props.refresh();
    })
  }

  updateCourseName() {
    this.setState({updatingName: true});
    HyperGrade.services.updateCourseName(this.props.course.id, this.state.courseName).then(res => {
      this.setState({originalCourseName: this.state.courseName, updatingName: false});
      this.props.appServices.showSuccessNotification("The course name has been updated!");
      this.props.refresh();
    });
  }

  render() {

    let course = this.props.course;

    let freshCourse = course.classStats.numAssignments === 0 && course.classStats.numStudents === 0;

    let updateWarningButton = null;

    return (
      <Stack anchor="top-right" key={"course-listing-" + course.id}>
        <Box width="large" elevation="xlarge" pad="medium" align={"center"}
             background="light-2" round border={{color: "dark-2"}}>

          <Heading level="3" margin="none">{course.name}</Heading>

          <Box>
            {course.classStats.numAssignments > 0 &&
            <Text className={"course-code"}>Course code: {course.id} &middot; <LoginAsStudent
              appServices={this.props.appServices} courseID={course.id} type={"anchor"}/></Text>
            }
          </Box>

          {freshCourse &&
          <Box pad={{vertical: "medium"}}>
            <CreateAssignmentButton courseID={course.id} appServices={this.props.appServices} quick={true}/>
          </Box>
          }

          {!freshCourse &&
          <Grid columns={{count: 3, size: 'auto'}} rows="auto" gap="small">
            <Card href={"/course/" + course.id + "/assignment-editor"}
                  stat={course.classStats.numAssignments}
                  alert={updateWarningButton}>
              <Pluralize singular={'Assignment'} showCount={false} count={course.classStats.numAssignments}/>
            </Card>
            <Card href={"/course/" + course.id + "/students"} stat={course.classStats.numStudents}>
              <Pluralize singular={'Student'} showCount={false} count={course.classStats.numStudents}/>
            </Card>
            <Card href={"/course/" + course.id + "/grades"}
                  stat={course.classStats.classGradePercent ? course.classStats.classGradePercent + "%" : "-"}>
              Average
            </Card>
          </Grid>
          }

        </Box>
        <Box margin={{horizontal: "medium", vertical: "none"}}>
          <Menu size={"small"}
                label="Settings" margin={"small"}
                items={[
                  {
                    label: <Box alignSelf="center" margin={{right: "small"}}>Rename course</Box>,
                    icon: <Box margin="small"><ContactInfo/></Box>,
                    onClick: () => this.setState({renameCourseModal: true})
                  },
                  {
                    label: <Box alignSelf="center" margin={{right: "small"}}>Clone course</Box>,
                    icon: <Box margin="small"><Clone/></Box>,
                    onClick: () => this.cloneCourse()
                  },
                  {
                    label: <Box alignSelf="center" margin={{right: "small"}}>Recycle options...</Box>,
                    icon: <Box margin="small"><Cycle/></Box>,
                    onClick: () => this.setState({recycleCourse: true})
                  }
                ]}
          />
        </Box>

        {this.state.renameCourseModal &&
        <ModalSkeleton onClose={() => this.setState({renameCourseModal: false})} instructions={"Rename Course"}>
          <Form onSubmit={({value}) => this.updateCourseName(value)}>
            <FormField label="Course Name" name="courseName" type="text"
                       value={this.state.courseName}
                       onChange={event => this.setState({courseName: event.target.value})}
                       required/>

            <Box direction="row" gap="small" align="center">
              <Button type="submit" primary label="Update"
                      disabled={this.state.updatingName
                      || (this.state.courseName === this.state.originalCourseName)}
              />
            </Box>
          </Form>
        </ModalSkeleton>
        }

        {this.state.recycleCourse &&
        <ModalSkeleton onClose={() => this.setState({recycleCourse: false})}
                       instructions={this.props.course.name + " Recycle Options"}>
          <Box gap={"large"} align={"center"} margin={{top: "small"}}>

            <Box gap={"small"}>
              <Box direction={"row"} justify={"center"}>
                <Button label={"Remove all students"} icon={<Detach/>}
                        onClick={() => this.setState({openStudentClearConfirm: true})}/>
              </Box>
              <Paragraph margin="none" textAlign={"center"}>Removes all students and all student work from this course.
                This does not delete students' HyperGrade accounts.</Paragraph>
            </Box>

            <Box gap={"small"}>
              <Box direction={"row"} justify={"center"}>
                <Button label={"Clear all due dates"} icon={<Erase/>}
                        onClick={() => this.setState({openDueDateConfirm: true})}/>
              </Box>
              <Paragraph margin="none" textAlign={"center"}>Make all assignments unavailable to
                students.</Paragraph>
            </Box>

            <Box gap={"small"}>
              <Box direction={"row"} justify={"center"}>
                <Button label={"Delete course"} color={"status-critical"} icon={<Trash/>}
                        onClick={() => this.setState({openCourseDeleteConfirm: true})}/>
              </Box>
              <Paragraph margin="none" textAlign={"center"}>Deletes all assignments and associated files from this
                course. All students and all student work
                will be removed.</Paragraph>
            </Box>
          </Box>
          {this.state.openStudentClearConfirm &&
          <YesNoModal onClickYes={this.clearAllStudents}
                      onSelectNo={() => this.setState({openStudentClearConfirm: false})}/>
          }
          {this.state.openDueDateConfirm &&
          <YesNoModal onClickYes={this.clearDueDates}
                      onSelectNo={() => this.setState({openDueDateConfirm: false})}/>
          }
          {this.state.openCourseDeleteConfirm &&
          <YesNoModal onClickYes={this.deleteCourse}
                      onSelectNo={() => this.setState({openCourseDeleteConfirm: false})}/>
          }
        </ModalSkeleton>
        }

      </Stack>)
  }
}

CourseOverview.propTypes = {
  course: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  appServices: Models.appServices.isRequired,
};

CourseOverview.defaultProps = {};

export default CourseOverview