//react
import React from "react"
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
//grommet
import {Anchor, Box, Button, Collapsible, Form, FormField, Heading, Paragraph, TextInput} from "grommet";
import {License} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import Models from "../models/appServices";

import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {loadStripe} from '@stripe/stripe-js';
import RegistrationFlowHeading from "./RegistrationFlowHeading";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(HyperGrade.stripePublishableKey);

class ConfirmPayment extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showCreditCard: true,
      key: "",
      loading: true
    };

    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    HyperGrade.services.getKeyCost().then(({data}) => {
      this.setState({...data, free: !(data.cost > 0), loading: false});
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  toggle() {
    this.setState({showCreditCard: !this.state.showCreditCard});
  }

  onSuccess(key) {
    this.setState({key}, () => this.submit());
  }

  submit() {
    this.setState({keyError: null, enrollError: null});

    let {key} = this.state;

    if (!key && !this.state.free) {
      this.setState({keyError: "Enter your HyperGrade key"});
    } else {
      this.props.appServices.beginWorking();
      HyperGrade.services.enroll(this.props.courseID, key).then(res => {
        this.props.appServices.endWorking();
        if (res.success) {
          this.setState({redirect: "/home"});
        } else {
          if (res.code === "BAD_KEY") {
            this.setState({keyError: "Invalid key"});
          } else if (res.code === "BAD_COURSE_ID") {
            this.props.goToCourseCodeForm("The course code is not valid.");
          } else {
            this.props.appServices.showErrorNotification("There was an error enrolling. Please contact us.");
          }
        }
      });
    }
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.state.loading)
      return <Box/>;

    let headingCopy = <Heading textAlign="center" margin="none" level="1">${this.state.cost}</Heading>;
    if (this.state.discount) {
      headingCopy = <Box justify={"center"}>
        <Heading textAlign="center" margin="none" level="1">
          <span><del>${HyperGrade.keyCost}</del> ${this.state.cost}</span></Heading>
        <Paragraph textAlign={"center"} color={"accent-2"}><strong>You received a discount thanks to your
          school!</strong></Paragraph>
      </Box>;
    }

    return (
      <Box width="large" alignSelf="center" animation="fadeIn">
        <RegistrationFlowHeading icon={<License color="accent-2"/>} label={"Access"}/>
        <Collapsible open={this.state.showCreditCard}>
          <Box background="light-3" pad="medium" round border={{color: "accent-2"}} elevation="large" gap="medium">
            {headingCopy}
            {!this.state.free &&
            <Elements stripe={stripePromise}>
              <CheckoutForm account={this.props.account}
                            toggle={this.toggle}
                            onSuccess={this.onSuccess}
                            appServices={this.props.appServices}/>
            </Elements>
            }
            {!this.state.free &&
            <Paragraph fill margin="none">
              HyperGrade costs ${this.state.cost} per course. There are no refunds even if
              the course is dropped. We have no access to your card information. Payments are
              processed through Stripe.
            </Paragraph>
            }
            {this.state.free &&
            <Box align={"center"}>
              <span>
              <Button label={"Continue"} onClick={this.submit}/>
              </span>
            </Box>
            }
          </Box>
        </Collapsible>

        <Collapsible open={!this.state.showCreditCard}>
          <Box background="light-3" pad="medium" round border={{color: "accent-2"}} elevation="large" gap="medium">
            <Form onSubmit={this.submit}>
              <FormField
                name="key"
                label="HyperGrade Key"
                error={this.state.keyError}
              >
                <TextInput placeholder={"2QDV-C2KJ-7WCS-NQW"} value={this.state.key}
                           onChange={event => this.setState({key: event.target.value})}/>
              </FormField>
              <Box direction="row" align="center" justify="between">
                <Button type="submit" primary label="Enroll"/>
                <Paragraph margin="none"><Anchor onClick={this.toggle}>Need to purchase a key?</Anchor></Paragraph>
              </Box>
            </Form>
          </Box>
        </Collapsible>

      </Box>
    )
  }
}

ConfirmPayment.propTypes = {
  courseID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  account: PropTypes.object,
  appServices: Models.appServices.isRequired,
  goToCourseCodeForm: PropTypes.func.isRequired
};

ConfirmPayment.defaultProps = {};

export default ConfirmPayment