//react
import React from "react"
import {Redirect} from "react-router-dom";
//grommet
import {Anchor, Box, Button, Form, FormField, Heading, Image, Layer, Paragraph, Text, TextInput} from "grommet";
import {Close, FormClose, StatusCritical} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import logo from "../images/HyperGradeLogo.png";
import PropTypes from "prop-types";
import ModalSkeleton from "./ModalSkeleton";
import Loading from "./Loading";

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordMode: false,
      badCredentials: false,
      stillHavingTrouble: false
    };
    //various modals
    this.openForgotPasswordModal = this.openForgotPasswordModal.bind(this);
    this.closeForgotPasswordModal = this.closeForgotPasswordModal.bind(this);

    this.openStillHavingTroubleModal = this.openStillHavingTroubleModal.bind(this);
    this.closeStillHavingTroubleModal = this.closeStillHavingTroubleModal.bind(this);

    this.openBadCredentialsMessage = this.openBadCredentialsMessage.bind(this);
    this.closeBadCredentialsMessage = this.closeBadCredentialsMessage.bind(this);

    //form submissions
    this.submitLoginForm = this.submitLoginForm.bind(this);
    this.submitPasswordResetForm = this.submitPasswordResetForm.bind(this);

    this.getPageThatSentUsHere = () => this.props.location && this.props.location.state && this.props.location.state.from;
    this.inSilentMode = () => (this.props.username && this.props.password) || this.props.accessCode || this.props.account;

    this.loginWithAccessCode = this.loginWithAccessCode.bind(this);
    this.loginCallback = this.loginCallback.bind(this);
    this.postRegisterAction = HyperGrade.postRegisterAction.bind(this);
  }

  componentDidMount() {

    if (this.props.notification) {
      this.props.showSuccessNotification(this.props.notification, HyperGrade.notificationAutoCloseTimeout * 3);
    }

    if (this.inSilentMode()) {
      if (this.props.accessCode) {
        this.loginWithAccessCode(this.props.accessCode);
      } else if (this.props.account && !this.getPageThatSentUsHere()) { //if we're already logged in, just go home
        this.setState({redirect: "/home"});
      } else {
        this.submitLoginForm(this.props.username, this.props.password);
      }
    }
  }

  loginWithAccessCode(accessCode) {
    this.closeBadCredentialsMessage();
    HyperGrade.services.loginWithAccessCode(accessCode).then(this.loginCallback);
  }

  submitLoginForm(username, password) {
    this.closeBadCredentialsMessage();
    HyperGrade.services.login(username, password).then(this.loginCallback);
  }

  loginCallback(res) {
    //if successfully logged in
    if (res.data) {

      let userObj = res.data;

      //"proceed" will happen after timezone check
      let proceed = () => {
        this.props.endWorking();
        if (this.props.onSuccess) {
          this.props.onSuccess(userObj);
        } else if (this.getPageThatSentUsHere()) {
          this.props.updateUserInfo(userObj);
          let {state, ...rest} = this.props.location.state.from;
          this.setState({redirect: rest});
        } else {
          this.postRegisterAction(userObj);
        }
      };

      //if we don't have a timezone yet
      if (!userObj.timezone) {
        //try to detect one
        userObj.timezone = HyperGrade.util.detectMyTimezone();
        if (userObj.timezone) {
          //tell HyperGrade
          HyperGrade.services.setTimezone(userObj.timezone).then(proceed);
        } else {
          proceed();
        }
      } else {
        proceed();
      }

    } else { //incorrect username/password
      this.props.updateUserInfo(); //clear user data (set object to undefined
      //if we're in silent mode and the login failed, then we have a really weird problem
      //ex: forgot password -> successful password reset -> autologin with accepted password -> fail
      if (this.inSilentMode()) {
        this.setState({redirect: "/login"});
      } else {
        this.openBadCredentialsMessage();
      }
    }


  }

  submitPasswordResetForm(event) {
    this.props.beginWorking();
    HyperGrade.services.resetPassword(event.value.email)
      .then(res => {
        this.props.endWorking();

        if (res.code === "PASSWORD_RESET_NO_USER") {
          this.props.openGenericModal(
            <Box gap="small">
              <Text>There is no account associated with <b>{event.value.email}</b></Text>
              <Text>If you believe this is an error, please <Anchor href="/support">send us a
                message.</Anchor></Text>
            </Box>, "Contact Us");
        } else if (res.code === "PASSWORD_RESET_OK") {
          this.props.openGenericModal(<Box gap="small">
            <Text>We sent a password reset link to <b>{event.value.email}</b>. Check your junk/spam folder
              if you cannot find the message.</Text>
          </Box>, "Check your email", this.closeForgotPasswordModal);
        } else {
          this.props.showErrorNotification("Unrecognized response from HyperGrade!");
        }
      });
  }

  closeForgotPasswordModal() {
    this.setState({forgotPasswordMode: false});
  }

  openForgotPasswordModal() {
    this.setState({forgotPasswordMode: true});
  }

  closeBadCredentialsMessage() {
    this.setState({badCredentials: false});
  }

  openBadCredentialsMessage() {
    this.setState({badCredentials: true});
  }

  openStillHavingTroubleModal() {
    this.setState({stillHavingTrouble: true});
  }

  closeStillHavingTroubleModal() {
    this.closeForgotPasswordModal();
    this.setState({stillHavingTrouble: false});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.inSilentMode()) {
      return <Loading/>;
    }

    return (
      <Box fill align="center" justify="center" className="login">
        {this.props.children}
        <Box width="large">
          <Box fill elevation={!this.props.plain ? "xlarge" : undefined}
               pad={this.props.plain ? undefined : "large"} gap="small">
            {this.props.children}
            <Box height="xsmall">
              <Image fit="contain" a11yTitle="White HyperGrade Logo" src={logo}/>
            </Box>
            <Form onSubmit={(event) => this.submitLoginForm(event.value.email, event.value.password)}>
              <FormField label="Email">
                <TextInput type="email" name="email" autoFocus required/>
              </FormField>
              <FormField label="Password">
                <TextInput type="password" name="password" required/>
              </FormField>
              <Box direction="row" justify="between" align="center" margin={{top: "medium"}}>
                <Button type="submit" label="Sign In" primary/>
                <Button onClick={this.openForgotPasswordModal}>Forgot password?</Button>
              </Box>
            </Form>
            {this.props.msg}
          </Box>
          {!this.props.plain &&
          <Box direction="row" gap="small" justify="center" margin={{vertical: "large"}}>
            <Anchor href="/">Home</Anchor>
            ·
            <Anchor href="/register">Create an account</Anchor>
            ·
            <Anchor href="/support">Contact Us</Anchor>
          </Box>
          }
        </Box>
        {this.state.forgotPasswordMode && (
          <ModalSkeleton onClose={this.closeForgotPasswordModal}>
            <Box pad="small">
              <Form onSubmit={this.submitPasswordResetForm}>
                <Box align="center">
                  <Heading margin={{top: "none"}} textAlign="center" level="3">Enter your email. We'll send reset
                    instructions.</Heading>
                </Box>
                <FormField name="email" type="email" required/>
                <Box direction="row" justify="between" margin={{top: "medium"}}>
                  <Button type="submit" label="Send Reset Link" primary/>
                  <Button margin="small" onClick={this.openStillHavingTroubleModal}>Still having trouble?</Button>
                </Box>
              </Form>
            </Box>
          </ModalSkeleton>
        )}
        {this.state.badCredentials && (
          <Layer
            position="bottom"
            modal={false}
            margin={{vertical: "medium", horizontal: "small"}}
            onEsc={this.closeBadCredentialsMessage}
            responsive={false}
            plain
          >
            <Box
              align="center"
              direction="row"
              gap="small"
              justify="between"
              round="medium"
              elevation="medium"
              pad={{vertical: "xsmall", horizontal: "small"}}
              background="status-critical"
            >
              <Box align="center" direction="row" gap="xsmall">
                <StatusCritical/>
                <Text>Incorrect email or password</Text>
              </Box>
              <Button icon={<FormClose/>} onClick={this.closeBadCredentialsMessage} plain/>
            </Box>
          </Layer>
        )}
        {this.state.stillHavingTrouble &&
        <Layer position="center" modal onClickOutside={this.closeStillHavingTroubleModal}
               onEsc={this.closeStillHavingTroubleModal}>
          <Box pad="large" width="large">
            <Box direction="row" justify="end">
              <Button icon={<Close/>} onClick={this.closeStillHavingTroubleModal}/>
            </Box>
            <Box align="center">
              <Heading margin={{top: "none"}} level="3">
                If you still can't login
              </Heading>
            </Box>
            <Paragraph fill>
              Contact your instructor. They will be able to look up the email address
              you used to register. This situation often occurs because of a typo
              in your email address.
            </Paragraph>
            <Paragraph>
              Or, <Anchor href="/support">send us an email</Anchor>.
            </Paragraph>
          </Box>
        </Layer>
        }
      </Box>
    )
  }
}

Login.propTypes = {
  plain: PropTypes.bool,
  onSuccess: PropTypes.func,
  updateUserInfo: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  showSuccessNotification: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  beginWorking: PropTypes.func.isRequired,
  endWorking: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  msg: PropTypes.object
};

Login.defaultProps = {
  plain: false,
};

export default Login