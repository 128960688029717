//react
import React from "react"
//grommet
import {Accordion, AccordionPanel, Box, Button, Form, FormField, Heading,} from "grommet";
import {SettingsOption} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import TimeZonePicker from "./TimeZonePicker";
import Suggest from "./Suggest";
import Enroll from "./Enroll";
import PasswordChangeForm from "./PasswordChangeForm";
import ChangeEmail from "./ChangeEmail";
import Models from "../models/appServices";

class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedTimeZone: this.props.account.timezone,
      firstName: this.props.account.firstname ? this.props.account.firstname : "",
      lastName: this.props.account.lastname ? this.props.account.lastname : "",
      email: this.props.account.username,
      activeIndexes: []
    };

    this.selectTimezone = (selectedTimeZone) => this.setState({selectedTimeZone});
    this.submitName = this.submitName.bind(this);

    this.validateRealName = HyperGrade.validation.validateRealName.bind(this);
    this.passwordChangeSuccessCallback = this.passwordChangeSuccessCallback.bind(this);
  }

  componentDidMount() {

  }

  submitName() {
    let problem = this.validateRealName();
    if (!problem) {
      this.props.appServices.saveRealName(this.state.firstName, this.state.lastName);
    }
  }

  passwordChangeSuccessCallback() {
    this.props.appServices.showSuccessNotification("Your password has been updated.");
    this.setState({activeIndexes: this.state.activeIndexes.filter(current => current !== 0)});
  }

  render() {

    let boxPad = {horizontal: "medium", vertical: "medium"};

    return (
      <Box align="center" margin="large">
        <Box width="large" gap="small">
          <Box direction="row" gap="medium" align="center">
            <SettingsOption className="spin-slow" size="large"/>
            <Heading margin="none">User Settings</Heading>
          </Box>

          <Accordion multiple={true} activeIndex={this.state.activeIndexes}
                     onActive={activeIndexes => this.setState({activeIndexes})}>

            {/*password*/}
            <AccordionPanel label="Password">
              <Box pad={boxPad} gap="small">
                <Box width="medium">
                  <PasswordChangeForm beginWorking={this.props.appServices.beginWorking}
                                      endWorking={this.props.appServices.endWorking}
                                      showSuccessNotification={this.props.appServices.showSuccessNotification}
                                      showErrorNotification={this.props.appServices.showErrorNotification}
                                      successCallback={this.passwordChangeSuccessCallback}
                  />
                </Box>
              </Box>
            </AccordionPanel>

            {/*real name update*/}
            <AccordionPanel label="Real Name">
              <Box pad={boxPad} gap="medium">
                <Box gap="medium">
                  {this.props.account.role === "student" &&
                  <Suggest message={"Your correct name is important because your teacher uses it for grading"}/>}
                  <Box width="medium">
                    <Form onSubmit={this.submitName}>
                      <FormField placeholder="First Name" name="firstName" error={this.state.firstNameError}
                                 value={this.state.firstName}
                                 onChange={event => this.setState({firstName: event.target.value})}/>
                      <FormField placeholder="Last Name" name="lastName" error={this.state.lastNameError}
                                 value={this.state.lastName}
                                 onChange={event => this.setState({lastName: event.target.value})}/>
                      <Button margin={{vertical: "small"}}
                              primary label="Update Name" type="submit"
                              disabled={this.props.account.firstname === this.state.firstName && this.props.account.lastname === this.state.lastName}
                      />
                    </Form>
                  </Box>
                </Box>
              </Box>
            </AccordionPanel>

            {/*email address*/}
            <AccordionPanel label="Email/Username">
              <Box pad={boxPad}>
                <ChangeEmail account={this.props.account} appServices={this.props.appServices}/>
              </Box>
            </AccordionPanel>

            {/*timezone select*/}
            <AccordionPanel label="Timezone">
              <Box pad={boxPad} gap="medium">
                <Suggest message={"Change to show the due dates in your preferred timezone"}/>
                <Box width="medium">
                  <Form>
                    <FormField>
                      <TimeZonePicker preselect={this.props.account.timezone} onChange={this.selectTimezone}/>
                    </FormField>
                    <Button margin={{vertical: "small"}}
                            disabled={this.props.account.timezone && this.props.account.timezone === this.state.selectedTimeZone}
                            type="submit" primary label="Save"
                            onClick={() => this.props.appServices.saveTimeZone(this.state.selectedTimeZone)}/>
                  </Form>
                </Box>
              </Box>
            </AccordionPanel>

            {this.props.account.role === "student" &&
            <AccordionPanel label="Add Course">
              <Box pad={{vertical: "small"}}>
                <Enroll appServices={this.props.appServices} account={this.props.account} plain/>
              </Box>
            </AccordionPanel>
            }

          </Accordion>

        </Box>
      </Box>
    )
  }
}

Settings.propTypes = {
  appServices: Models.appServices.isRequired,
  account: Models.account.isRequired
};

Settings.defaultProps = {};

export default Settings