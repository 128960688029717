const sampleFiles = {
  template: {
    testCases: [
      {stdin: '', cla: ''},
    ],
    files: [
      {
        name: '',
        content: ''
      },
    ]
  },

  py: {
    testCases: [
      {stdin: "50\n60\n"},
      {stdin: "-3\n40\n"},
      {stdin: "5\n92\n"},
    ],
    files: [
      {
        name: 'Adder.py',
        content: 'print("Hello from Python!")\n' +
          'n1 = input("Enter a number: ")\n' +
          'print("You entered: " + n1)\n' +
          'n2 = input("Enter a number: ")\n' +
          'print("You entered: " + n2)\n' +
          'sum = float(n1) + float(n2)\n' +
          'print(\'The sum of {0} and {1} is {2}\'.format(n1, n2, sum))'
      },
    ]
  },

  cs: {
    testCases: [
      {stdin: "50\n60\n"},
      {stdin: "-3\n40\n"},
      {stdin: "5\n92\n"},
    ],
    files: [
      {
        name: 'Program.cs',
        content: "using System;\n" +
          "\n" +
          "namespace MyApplication\n" +
          "{\n" +
          "  class Program\n" +
          "  {\n" +
          "    static void Main(string[] args)\n" +
          "    {\n" +
          "      Console.WriteLine(\"Hello from C#!\");\n" +
          "      Console.WriteLine(\"Enter first num\");\n" +
          "      int x = Convert.ToInt32(Console.ReadLine());\n" +
          "      Console.WriteLine(\"Enter second num\");\n" +
          "      int y = Convert.ToInt32(Console.ReadLine());\n" +
          "      int sum = Other.add(x, y);\n" +
          "      Console.WriteLine(\"The sum is \" + sum); // Print the sum of x + y\n" +
          "    }\n" +
          "  }\n" +
          "}\n"
      },
      {
        name: 'Other.cs',
        content: "using System;\n" +
          "\n" +
          "namespace MyApplication\n" +
          "{\n" +
          "  class Other\n" +
          "  {\n" +
          "    public static int add(int x, int y) {\n" +
          "    return x + y;\n" +
          "    }\n" +
          "  }\n" +
          "}\n"
      },
    ]
  },

  cpp: {
    testCases: [
      {stdin: "50\n60\n"},
      {stdin: "-3\n40\n"},
      {stdin: "5\n92\n"},
    ],
    files: [
      {
        name: 'Adder.cpp',
        content: "#include <iostream>\n" +
          "using namespace std;\n" +
          "\n" +
          "int main() {\n" +
          "\n" +
          "\tint num1, num2, total;\n" +
          "\n" +
          "\tcout << \"Hello from C++!\" << endl;\n" +
          "\n" +
          "\tcout << \"Enter first number\" << endl;\n" +
          "\tcin >> num1;\n" +
          "\n" +
          "\tcout << \"Enter second number\" << endl;\n" +
          "\tcin >> num2;\n" +
          "\n" +
          "\ttotal = num1 + num2;\n" +
          "\n" +
          "\tcout << \"The total is \" << total << endl;\n" +
          "\tcout << \"Goodbye!\" << endl;\n" +
          "\n" +
          "\treturn 0;\n" +
          "}"
      },
    ]
  },

  java: {
    testCases: [
      {stdin: "50\n60\n"},
      {stdin: "-3\n40\n"},
      {stdin: "5\n92\n"},
    ],
    files: [
      {
        name: "Adder.java",
        content: "import java.util.Scanner;\n" +
          "\n" +
          "public class Adder {\n" +
          "    public static void main(String[] args) {\n" +
          "        Scanner keyboard = new Scanner(System.in);\n" +
          "\n" +
          "        System.out.println(\"Enter first number\");\n" +
          "        int x = keyboard.nextInt();\n" +
          "        System.out.println(\"Enter second number\");\n" +
          "        int y = keyboard.nextInt();\n" +
          "\n" +
          "        System.out.println(\"Sum: \" + (x + y));\n" +
          "\n" +
          "    }\n" +
          "}"
      }
    ]
  },
}

export default sampleFiles;

