import React from "react"

import {Box, Button, DataTable, Heading, Paragraph, Text} from "grommet";
import {Add, Clipboard, Subtract} from 'grommet-icons';

import _ from 'lodash';

import HyperGrade from '../hg-config';
import LateDays from "./LateDays";
import LastActiveTime from "./LastActiveTime";
import StudentActionButton from "./StudentActionButton";
import Loading from "./Loading";
import PropTypes from "prop-types";
import Breadcrumb from "./Breadcrumb";
import Models from "../models/appServices";
import {Redirect} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoginAsStudent from "./LoginAsStudent";
import MetaTags from "react-meta-tags";

class StudentList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.updateStudent = this.updateStudent.bind(this);
    this.addLateDayForAllStudents = this.addLateDayForAllStudents.bind(this);
    this.subtractLateDayFromAllStudents = this.subtractLateDayFromAllStudents.bind(this);
    this.changeAllLateDays = this.changeAllLateDays.bind(this);
  }

  componentDidMount() {
    let promises = [];
    promises.push(HyperGrade.services.getStudentList(this.props.courseID));
    promises.push(HyperGrade.services.getCourse(this.props.courseID));

    Promise.all(promises).then(values => {
      this.setState({students: values[0].data, course: values[1].data, loading: false});
    })
  }

  updateStudent(username, studentObj) {
    let updatedStudentList = _.cloneDeep(this.state.students);
    let index = updatedStudentList.findIndex(current => current.username === username);
    if (index >= 0) {
      if (studentObj) { //property update
        updatedStudentList[index] = studentObj;
      } else { //deleting the student
        updatedStudentList.splice(index, 1);
      }
    }

    this.setState({students: updatedStudentList});
  }

  changeAllLateDays(delta) {
    let updatedStudentList = this.state.students.map(current => {
      current.late_days += delta;
      if (current.late_days < 0) current.late_days = 0;
      return current;
    });

    this.setState({students: updatedStudentList});
  }

  addLateDayForAllStudents() {
    this.changeAllLateDays(+1);
    HyperGrade.services.incrementLateDayForCourse(this.props.courseID);
  }

  subtractLateDayFromAllStudents() {
    this.changeAllLateDays(-1);
    HyperGrade.services.decrementLateDayForCourse(this.props.courseID);
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.state.loading) {
      return <Loading/>;
    }

    let studentPanel = null;
    if (Array.isArray(this.state.students) && this.state.students.length) {

      studentPanel = <DataTable sortable={true}
                                step={1000}
                                pin={true}
                                columns={[
                                  {
                                    property: 'firstname',
                                    header: <Text>First Name</Text>,
                                    sortable: true,
                                    search: true
                                  },
                                  {
                                    property: 'lastname',
                                    header: <Text>Last Name</Text>,
                                    sortable: true,
                                    search: true
                                  },
                                  {
                                    property: 'username',
                                    header: <Text>Email</Text>,
                                    sortable: true,
                                    primary: true,
                                    search: true
                                  },
                                  {
                                    property: 'last_activity_time',
                                    header: <Text>Last Activity Time</Text>,
                                    sortable: true,
                                    render: (studentObj) => <LastActiveTime key={"last_activity_time-" + studentObj.key} student={studentObj}/>
                                  },
                                  {
                                    property: 'late_days',
                                    header: <Box direction="row" justify="center" align="center">
                                      <Button onClick={this.subtractLateDayFromAllStudents} icon={<Subtract/>}/>
                                      Late Days
                                      <Button onClick={this.addLateDayForAllStudents} icon={<Add/>}/>
                                    </Box>,
                                    sortable: false,
                                    search: false,
                                    aggregate: "avg",
                                    render: (studentObj) => <LateDays courseID={this.props.courseID}
                                                                      key={"late_days-" + studentObj.key}
                                                                      updateStudent={this.updateStudent}
                                                                      appServices={this.props.appServices}
                                                                      student={studentObj}/>
                                  },
                                  {
                                    property: 'key',
                                    header: <Box align="center">Action</Box>,
                                    sortable: false,
                                    search: false,
                                    render: (studentObj) => <StudentActionButton updateStudent={this.updateStudent}
                                                                                 initialStudent={studentObj}
                                                                                 key={"action-" + studentObj.key}
                                                                                 appServices={this.props.appServices}

                                    />
                                  }

                                ]}
                                data={this.state.students}
                                primaryKey="key"
      />;
    } else {

      let enrollLink = window.location.origin + "/register/" + this.props.courseID;

      studentPanel =
        <Box margin="medium" align="center">
          <Paragraph margin="none">Send students this link to enroll in this course</Paragraph>


          <Box direction="row" align="center" gap="small" pad="small">
            <Box>
              <Paragraph
                margin="none"><strong>{enrollLink}</strong></Paragraph>
            </Box>
            <CopyToClipboard text={enrollLink}
                             onCopy={() => this.props.appServices.showSuccessNotification("Link has been copied!")}>
              <Box background={"light-3"} round>
                <Button icon={<Clipboard/>} plain={false} title={"Copy to clipboard"}/>
              </Box>
            </CopyToClipboard>
          </Box>


        </Box>

    }

    return (
      <Box margin="medium" gap="medium" className={"student-list"}>
        <MetaTags>
          <title>Student List for {this.state.course.name} - HyperGrade</title>
          <meta name="description" content="View and manage all students in your course."/>
        </MetaTags>
        <Breadcrumb crumbs={[
          {label: this.state.course.name, href: "/course/" + this.props.courseID + "/assignment-editor"},
          {label: "Students"}
        ]}/>
        <Box pad="medium" elevation="large">
          <Box direction="row" justify="between" align="center">
            <Box direction="row" gap="large" align="end">
              <Heading level="3" margin="none">{this.state.course.name}</Heading>
              <Text
                color="dark-6">{this.state.students.length + " student" + (this.state.students.length !== 1 ? "s" : "")}</Text>
              <Text color="dark-6">Course code {this.props.courseID}</Text>
            </Box>
            <LoginAsStudent appServices={this.props.appServices} courseID={this.props.courseID} type={"button"}/>
          </Box>
          {studentPanel}
        </Box>
      </Box>
    )
  }
}

StudentList.propTypes = {
  appServices: Models.appServices.isRequired,
  courseID: PropTypes.number.isRequired
};

export default StudentList