//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Button, Calendar, CheckBox, Collapsible, Paragraph, Select} from "grommet";
import {Schedule} from 'grommet-icons';
//moment
import Moment from 'moment';
import HyperGrade from "../hg-config";
import ModalSkeleton from "./ModalSkeleton";

//import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';

class HGDateTime extends React.Component {

  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onCancel = this.onCancel.bind(this);

    let preselect;

    if (this.props.initialMoment) {

      let numberHour = Number(this.props.initialMoment.hours());
      let numberMinute = Number(this.props.initialMoment.minutes());

      //set the period
      preselect = {
        selectedPeriod: numberHour < 12 ? "am" : "pm"
      }

      //set the hours
      if (numberHour === 0) {
        preselect.selectedHour = 12;
      } else if (numberHour > 12) {
        preselect.selectedHour = numberHour - 12;
      }

      preselect.selectedHour = "" + preselect.selectedHour; //hour must be a string

      //set the minutes
      preselect.selectedMinute = HyperGrade.minuteOptions.find(current => {
        return numberMinute <= Number(current);
      });

    } else {
      preselect = {
        selectedHour: "11",
        selectedMinute: "59",
        selectedPeriod: "pm"
      };
    }

    this.state = {
      moment: this.props.initialMoment ? this.props.initialMoment.clone() : null,
      checked: !this.props.initialMoment,
      ...preselect
    };
  }

  onChange(value) {
    this.setState({moment: new Moment(value)});
  }

  openModal() {
    this.setState({
      modal: true,
      moment: this.state.moment ? this.state.moment : new Moment().add(7, 'days').endOf('day')
    });
  }

  onSave() {
    let updatedMoment = this.state.moment.clone();
    if (this.state.checked) {
      updatedMoment.endOf('day');
    } else {
      let militaryHour = Number(this.state.selectedHour);
      if (this.state.selectedPeriod === "pm") {
        if (militaryHour !== 12) {
          militaryHour += 12;
        }
      } else if (militaryHour === 12) { //user must have select AM
        militaryHour = 0;
      }
      updatedMoment.hours(militaryHour);
      updatedMoment.minutes(Number(this.state.selectedMinute));
    }
    this.setState({
      moment: updatedMoment,
      modal: false
    });
    this.props.onSave(updatedMoment);
  }

  onClear(e) {
    e.preventDefault();
    this.onCancel();
    this.props.onClear();
  }

  onCancel() {
    this.setState({
      moment: null, //will be reset when modal re-opens
      modal: false
    });
  }

  render() {

    let bottomMargin = {bottom: "medium"};

    let modal = null;
    if (this.state.modal) {
      modal = <ModalSkeleton onClose={() => {
        this.setState({modal: false})
      }}>
        <Box align="center" fill={true}>

          <Paragraph>Select due date for <strong>{this.props.assignmentName}</strong></Paragraph>

          {/*date select*/}
          <Box margin={bottomMargin}>
            <Calendar
              size="medium"
              daysOfWeek={true}
              date={this.state.moment.toISOString()}
              onSelect={(date) => this.setState({moment: new Moment(date)})}
            />
          </Box>

          {/*use default time*/}
          <Box margin={bottomMargin}>
            <CheckBox
              checked={this.state.checked}
              label="Due at end of day"
              onChange={(event) => this.setState({checked: event.target.checked})}
            />
          </Box>

          {/*time selection*/}
          <Collapsible open={!this.state.checked}>
            <Box direction="row" align="center" justify="center" margin={bottomMargin}>
              <Select
                options={HyperGrade.hourOptions}
                value={this.state.selectedHour}
                onChange={({option}) => this.setState({selectedHour: option})}
              />
              <Select
                options={HyperGrade.minuteOptions}
                value={this.state.selectedMinute}
                onChange={({option}) => this.setState({selectedMinute: option})}
              />
              <Select
                options={["am", "pm"]}
                value={this.state.selectedPeriod}
                onChange={({option}) => this.setState({selectedPeriod: option})}
              />
            </Box>
          </Collapsible>
          {/*end time selection*/}

          <Box direction="row" align="center" gap="medium">
            <Box direction="row" align="center" gap="small">
              <Button primary label={"Save"} onClick={this.onSave}/>
              <Button label={"Cancel"} onClick={this.onCancel}/>
            </Box>
            <Anchor onClick={this.onClear}>Make assignment unavailable</Anchor>
          </Box>

        </Box>
      </ModalSkeleton>;
    }

    let content = null;
    if (this.props.initialMoment) {
      content = (
        <Box>
          <Button plain icon={<Schedule/>} reverse onClick={this.openModal}
                  label={this.props.initialMoment.format("llll") + " — " + this.props.initialMoment.fromNow()}/>
          {modal}
        </Box>
      );
    } else {
      content = (
        <Box>
          <Button onClick={this.openModal}
                  reverse
                  label={"Assignment is unavailable to students"}
                  color="dark-5"
                  icon={<Schedule/>}
                  plain/>
          {modal}
        </Box>
      )
    }

    return content;
  }
}

var momentPropTypes = require('react-moment-proptypes');

HGDateTime.propTypes = {
  initialMoment: momentPropTypes.momentObj,
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  assignmentName: PropTypes.string.isRequired
};

HGDateTime.defaultProps = {};

export default HGDateTime