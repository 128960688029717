//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Button, Form, FormField} from "grommet";
import {Code} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import Models from "../models/appServices";
import RegistrationFlowHeading from "./RegistrationFlowHeading";
import {Redirect} from "react-router-dom";

class ConfirmCourseCodeForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseID: this.props.courseID ? this.props.courseID : "",
      courseIDError: this.props.courseCodeError,
      loading: true
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    //if we're coming in with a course ID and not coming in with an error
    if (this.props.courseID && !this.props.courseCodeError) {
      //just submit the given code
      this.submit(this.props.courseID);
    } else {
      this.setState({loading: false});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  submit(courseID) {
    this.props.appServices.beginWorking();

    HyperGrade.services.getMyCourses().then(server => {
      let alreadyEnrolled = server.data.findIndex(course => Number(course.id) === Number(courseID)) >= 0;
      if (alreadyEnrolled) {
        this.props.appServices.endWorking();
        this.setState({redirect: "/home"});
      } else {
        HyperGrade.services.isCourseCodeValid(courseID)
          .then(server => {
            this.props.appServices.endWorking();
            if (server.success) {
              this.props.onSuccess(courseID);
            } else {
              this.setState({
                loading: false, //form may be instantly submitted if a prop is provided so turn off loading if there's an error
                courseIDError: "That course ID is invalid. Verify the code and try again."
              });
            }
          });
      }
    });


  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.state.loading)
      return null; //empty loading screen to make experience a bit more seamless when doing auto submit

    return (
      <Box width="large" alignSelf="center" animation="fadeIn">
        <RegistrationFlowHeading icon={<Code color="accent-3"/>} label="Course Code"/>
        <Box background="light-3" pad="medium" round border={{color: "accent-3"}} elevation="large">
          <Form onSubmit={() => this.submit(this.state.courseID)}>
            <FormField
              name="courseID"
              label="Enter the course Code (from your teacher)"
              error={this.state.courseIDError}
              type="text"
              placeholder="12732" value={this.state.courseID}
              onChange={event => this.setState({courseID: event.target.value})}
              required
            />
            <Box direction="row" justify="between" align="center">
              <Button type="submit" primary label="Submit"/>
            </Box>
          </Form>
        </Box>
      </Box>
    )
  }
}

ConfirmCourseCodeForm.propTypes = {
  courseID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func.isRequired,
  account: PropTypes.any.isRequired,
  appServices: Models.appServices.isRequired,
  courseCodeError: PropTypes.string
};

ConfirmCourseCodeForm.defaultProps = {};

export default ConfirmCourseCodeForm