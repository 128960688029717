//react
import React from "react"
import PropTypes from "prop-types";
//npm
import Pluralize from 'react-pluralize'
//grommet
import {Anchor, Box, Button, Heading, Layer, Nav, Paragraph, Sidebar, Text} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';
import Loading from "./Loading";
//moment
import Moment from 'react-moment';
import GrommetDraftJS from "./GrommetDraftJS";
import CharacterByCharacterCombined from "./CharacterByCharacterCombined";
import {Download, LinkTop} from "grommet-icons";
import MetaTags from "react-meta-tags";
import AssignmentPasswordModal from "./AssignmentPasswordModal";
import {Redirect} from "react-router-dom";
import CommandLineArguments from "./CommandLineArguments";

class Assignment extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      topRef: React.createRef()
    };

    this.noQuestions = this.noQuestions.bind(this);
    this.moreThanOneQuestion = this.moreThanOneQuestion.bind(this);
    this.dueDateExists = this.dueDateExists.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  refresh() {
    this.setState({loading: true, showPasswordModal: false, assignmentID: null});
    HyperGrade.services.getAssignmentAndQuestions(this.props.assignmentID).then(server => {
      if (server.data.problem === 'PASSWORD_REQUIRED') {
        this.setState({showPasswordModal: true, assignmentID: server.data.assignmentID});
      } else {
        server.data.question.forEach(question => {
          question.ref = React.createRef();
        });

        this.setState({...server.data});

        //preload the test case outputs
        HyperGrade.services.getTestCases(this.props.assignmentID).then(server2 => {
          this.setState({test_cases: server2.data});
        });
      }
      this.setState({loading: false});
    });
  }

  componentDidMount() {
    this.refresh();
  }

  //it wouldn't exist if teacher is reviewing the assignment and it's not assigned yet
  dueDateExists() {
    return this.state.assignment.due_date && this.state.assignment.due_date !== '0000-00-00 00:00:00';
  }

  noQuestions() {
    return this.state.question.length === 0;
  }

  moreThanOneQuestion() {
    return this.state.question.length > 1;
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.state.loading) {
      return <Loading/>;
    }

    if (this.state.showPasswordModal) {
      return <AssignmentPasswordModal
        onClose={() => this.setState({redirect: '/home'})}
        onSuccess={this.refresh}
        assignmentID={this.state.assignmentID}
      />
    }

    let sidebarSize = "xsmall";

    return (
      <Box ref={this.state.topRef} direction="row">
        <MetaTags>
          <title>{this.state.assignment.name} - HyperGrade</title>
          <meta name="description" content={"Hyper Grade assignment: " + this.state.assignment.name}/>
        </MetaTags>

        {/*spacer to ensure the content is moved over*/}
        {this.moreThanOneQuestion() && <Box width={sidebarSize} background="neutral-1"/>}

        {this.moreThanOneQuestion() &&
        <Layer position="left" full="vertical" modal={false} animation="none">
          <Sidebar background="brand" align="center" width={sidebarSize}
                   header={
                     <Text>Jump</Text>
                   }
                   footer={
                     <Button plain onClick={() => {
                       this.state.topRef.current.scrollIntoView({
                         behavior: 'smooth',
                         block: 'start',
                       });
                     }} icon={<LinkTop/>}/>
                   }
          >
            <Nav gap="small">
              {this.state.question.map((question, index) =>
                <Button key={"jump-button-" + index} size="small" label={index + 1} onClick={() => {
                  question.ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }}/>
              )}
            </Nav>
          </Sidebar>
        </Layer>
        }
        <Box gap="medium" flex margin={{bottom: "xlarge"}}>
          <Box align={"center"}>
            <Heading margin={{bottom: "small"}}>{this.state.assignment.name}</Heading>
            <Box direction="row" gap="xsmall" align="center">
              <Pluralize singular={'point'} count={this.state.assignment.assignment_total_points}/>
              <Text>•</Text>
              {this.dueDateExists() ? <Text>Due <Moment format="LLLL">{this.state.assignment.due_date}</Moment></Text> :
                <Text textAlign="center" color="status-warning"><strong>Set a due date to make this assignment available
                  to students</strong></Text>

              }
            </Box>
          </Box>

          <Box gap="xlarge" pad={"small"}>
            {this.noQuestions() &&
            <Box align="center">
              <Paragraph>This assignment doesn't have any questions.</Paragraph>
            </Box>
            }
            {/*one question*/}
            {this.state.question.map((question, index) =>

              <Box gap="medium" ref={question.ref} pad="small" key={"question-" + index}>
                <Box direction="row" gap="xsmall" align="center" pad="small" justify="center" round alignSelf="center"
                     background="accent-1">
                  <Heading level="4" margin="none">Question {index + 1}</Heading>
                  <Text>•</Text>
                  <Heading level="4" margin="none">Points: {question.points}</Heading>
                  <Text>•</Text>
                  <Anchor href={"/question/" + question.id + "/submit"}>Submit</Anchor>
                </Box>

                <GrommetDraftJS key={"gdjs-" + index} questionObj={question} readOnly={true}/>

                {/*public file list*/}
                {Array.isArray(question.files) && question.files.length > 0 &&
                <Box direction={"row"} gap={"small"}>
                  {question.files.map(file =>
                    <Button key={"file-download-" + file.id} download href={HyperGrade.makeFileDownloadLink(file.id)}
                            label={file.name} icon={<Download/>}/>
                  )}
                </Box>
                }

                {/*one test case*/}
                <Box gap={"large"} margin={{vertical: "small"}}>
                  {this.state.test_cases && this.state.test_cases[question.id] && this.state.test_cases[question.id].map((tc, index) => {

                      let theOutput = tc.outputMixed ? tc.outputMixed : tc.output;
                      let outputToRender = null;

                      //if this test case is hidden, the output and outputMixed are null
                      if (!!tc.hide) {
                        outputToRender = <Paragraph margin="none">{HyperGrade.hardcoding}</Paragraph>;
                      } else if (theOutput) {
                        outputToRender = <CharacterByCharacterCombined plain teacherOutput={theOutput}/>
                      } else {
                        outputToRender = <Paragraph margin="none">No output for this test case</Paragraph>;
                      }


                      return (
                        <Box gap="small" key={"test-case-" + tc.id}>
                          <Heading level="4" margin="none">Test Case {index + 1}</Heading>
                          <CommandLineArguments command_line_arguments={tc.command_line_arguments}/>
                          <Box elevation="xlarge" round key={"c-by-c-" + tc.id} background="light-2" pad="medium">
                            {outputToRender}
                          </Box>
                        </Box>
                      )
                    }
                  )}
                </Box>
              </Box>
            )}
          </Box>

        </Box>
      </Box>
    )
  }
}

Assignment.propTypes = {
  assignmentID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]).isRequired,
};

Assignment.defaultProps = {};

export default Assignment