//react
import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
//util
import _ from "lodash";
//grommet
import {Box, Button, Grommet, Heading, Layer, Paragraph, Text} from 'grommet';
import {Checkmark, FormClose, StatusCritical, StatusGood, StatusInfo} from "grommet-icons";
//hypergrade - public pages
import Home from './components/Custom/Home';
import Login from "./components/Login";
import AssignmentEditor from "./components/AssignmentEditor";
//hypergrade - protected teacher routes
import StudentList from "./components/StudentList";
import Grades from "./components/Grades";
import CourseList from "./components/CourseList";
import QuestionEditor from "./components/QuestionEditor";
import Assignment from "./components/Assignment";
//hypergrade - web services and config
import HyperGrade from "./hg-config";

import Enroll from "./components/Enroll";
import Loading from "./components/Loading";
import Course from "./components/Course";
import HGFooter from "./components/Custom/HGFooter";
import MenuBar from "./components/MenuBar";
import StudentHome from "./components/StudentHome";
import Submit from "./components/Submit";
import Settings from "./components/Settings";
import ProtectedRoute from "./components/ProtectedRoute";
import GradeDetail from "./components/GradeDetail";
import MyGrades from "./components/MyGrades";
import ModalSkeleton from "./components/ModalSkeleton";
import ResetPasswordWithPin from "./components/ResetPasswordWithPin";
import ErrorScreen from "./components/ErrorScreen";
import TallBox from "./components/Tallbox";
import Privacy from "./components/Privacy";
import NotFound from "./components/NotFound";
import MetaTags from "react-meta-tags";
import FormFrame from "./components/Custom/FormFrame";

/* hypergrade */
import "./style/style.scss";
import FormRegister from "./components/Custom/FormRegister";
import FormLogin from "./components/Custom/FormLogin";
import FormForgotPassword from "./components/Custom/FormForgotPassword";
import FormSupport from "./components/Custom/FormSupport";
import LoadingPage from "./components/LoadingPage";

const theme = {
  global: {
    colors: {
      brand: '#228BE6',
      brand2: '#7D4CDB'
      // "toggle-bg": "#757575",
      // "toggle-knob": "white",
      // "toggle-accent": "accent-1"
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

class MainBox extends Component {
  render() {
    return <Box {...this.props} flex={{grow: 1, shrink: 1}}>{this.props.children}</Box>
  }
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {loading: true};
    this.closeGenericModal = this.closeGenericModal.bind(this);
    this.openSuccessModal = this.openSuccessModal.bind(this);
    this.openFailureModal = this.openFailureModal.bind(this);

    this.openGenericModal = this.openGenericModal.bind(this);

    this.setAccountInfo = this.setAccountInfo.bind(this);
    this.logout = this.logout.bind(this);
    this.userIsLoggedIn = this.userIsLoggedIn.bind(this);

    this.beginNotification = this.beginNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);

    this.saveTimeZone = this.saveTimeZone.bind(this);

    this.showNotification = this.beginNotification.bind(this, "light-1", <StatusInfo/>);
    this.showErrorNotification = this.beginNotification.bind(this, "status-error", <StatusCritical/>);
    this.showSuccessNotification = this.beginNotification.bind(this, "status-ok", <StatusGood/>);

    this.beginWorking = (workingMessage) => this.setState({showWorking: true, workingMessage});
    this.endWorking = () => this.setState({showWorking: false, workingMessage: undefined});

    this.saveRealName = this.saveRealName.bind(this);
    this.saveEmail = this.saveEmail.bind(this);
    this.workingWrapper = this.workingWrapper.bind(this);
    this.refresh = this.refresh.bind(this);

    this.noConnection = this.noConnection.bind(this);

    HyperGrade.notLoggedInHandler = this.notLoggedInHandler.bind(this);
    HyperGrade.noConnectionHandler = this.noConnection;
    HyperGrade.genericErrorHandler = this.genericErrorHandler.bind(this);

  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    //this is the first web service that is called in the entire app
    return HyperGrade.services.getMyAccountInfo().then(res => {
      if (res.success) {
        this.setAccountInfo(res.data);
        this.setState({loading: false});
      } else {
        this.notLoggedInHandler();
      }
    }).catch(err => {
      //here, we catch the NOT_LOGGED_IN error and do nothing
      //1. hg-config already called "notLoggedInHandler"
      //2. ProtectedRoute will handle the login->go back workflow
    });
  }

  genericErrorHandler(errMsg) {
    this.endWorking();
    //if we're loading, then showErrorNotification will not work
    if (this.state.loading) {
      this.setState({serverError: errMsg});
    } else {
      this.showErrorNotification(errMsg);
    }
  }

  notLoggedInHandler() {
    this.setAccountInfo(null);
    this.setState({loading: false});
  }

  noConnection() {
    //fires on web service failures
    this.setState({noConnection: true});
  }

  saveTimeZone(timezone) {
    this.workingWrapper(() => HyperGrade.services.setTimezone(timezone),
      {timezone},
      "Your timezone has been updated.");
  }

  saveRealName(firstname, lastname) {
    this.workingWrapper(() => HyperGrade.services.updateRealName(firstname, lastname),
      {firstname, lastname},
      "Your name has been updated");
  }

  saveEmail(username) {
    this.workingWrapper(() => HyperGrade.services.updateEmail(username),
      {username},
      "Your email has been updated.");
  }

  workingWrapper(promise, updatedAccountInfo, positive, callback) {
    if (this.userIsLoggedIn()) {
      this.beginWorking();
      promise().then((res) => {
        if (res.success) {
          this.showSuccessNotification(positive);
          let updated = _.cloneDeep(this.state.account);
          for (let [key, value] of Object.entries(updatedAccountInfo)) {
            updated[key] = value;
          }
          this.setAccountInfo(updated);
          if (callback)
            callback(res);
        } else {
          if (callback) {
            callback(res);
          } else {
            this.showErrorNotification(res.msg);
          }
        }
        this.endWorking()
      });
    } else {
      this.showErrorNotification("You are not logged in.");
    }
  }

  closeGenericModal() {
    if (this.state.modalOnClose) {
      this.state.modalOnClose();
    }
    this.setState({modalMessage: '', modalTitle: '', modalOnClose: undefined});
  }

  openSuccessModal(modalMessage, modalTitle, modalOnClose) {
    modalMessage = (
      <Box justify={"center"} align={"center"}>
        <Checkmark size={"large"} color={"status-ok"}/>
        <Paragraph>{modalMessage}</Paragraph>
        <Button label={"OK"} onClick={this.closeGenericModal}/>
      </Box>);
    this.setState({
        modalMessage,
        modalTitle: null,
        modalOnClose
      }
    );
  }

  openFailureModal(modalMessage, modalTitle, modalOnClose) {
    modalMessage = (
      <Box justify={"center"} align={"center"}>
        <StatusCritical size={"large"} color={"status-critical"}/>
        <Paragraph>{modalMessage}</Paragraph>
        <Button label={"OK"} onClick={this.closeGenericModal}/>
      </Box>);
    this.setState({
        modalMessage,
        modalTitle: null,
        modalOnClose
      }
    );
  }

  openGenericModal(modalMessage, modalTitle, modalOnClose) {
    this.setState({modalMessage, modalTitle, modalOnClose});
  }

  setAccountInfo(accountObj) {
    this.setState({account: accountObj});
  }

  logout() {
    HyperGrade.services.logout().then(() => {
      this.setState({account: undefined});
    });
  }

  userIsLoggedIn() {
    return this.state.account && this.state.account.username;
  }

  beginNotification(background, icon, notificationMessage, millis = HyperGrade.notificationAutoCloseTimeout) {
    this.setState({notificationMessage, background, icon});
    this.timeoutId = setTimeout(this.closeNotification, millis);
  }

  closeNotification() {
    this.setState({notificationMessage: undefined});
    clearTimeout(this.timeoutId);
  }

  userIsTeacher() {
    return this.userIsLoggedIn() && this.state.account.role === 'teacher';
  }

  userIsStudent() {
    return this.userIsLoggedIn() && this.state.account.role === 'student';
  }

  render() {

    if (this.state.noConnection)
      return <ErrorScreen theme={theme}
                          message={"Either you don't have an Internet connection or the HyperGrade server is down."}/>

    if (this.state.serverError)
      return <ErrorScreen theme={theme} message={this.state.serverError}/>;

    if (this.state.showAppMismatchError)
      return <ErrorScreen theme={theme}
                          message={"App and server must be under the same domain."}/>;

    if (this.state.loading) {
      return <Loading/>;
    }

    let appServices = {
      openFailureModal: this.openFailureModal,
      showSuccessNotification: this.showSuccessNotification,
      showErrorNotification: this.showErrorNotification,
      showNotification: this.showNotification,
      beginWorking: this.beginWorking,
      endWorking: this.endWorking,
      openGenericModal: this.openGenericModal,
      openSuccessModal: this.openSuccessModal,
      updateUserInfo: this.setAccountInfo,
      saveEmail: this.saveEmail,
      saveTimeZone: this.saveTimeZone,
      saveRealName: this.saveRealName,
      refreshUserData: this.refresh
    };

    return (
      <Router>
        <Grommet theme={theme} full id="hypergrade-grommet-root">
          <Switch>
            <Route exact path="/login" render={({location}) =>
              <FormFrame>
                <MetaTags>
                  <title>Login - HyperGrade</title>
                  <meta name="description" content="Log in to HyperGrade."/>
                </MetaTags>
                <FormLogin account={this.state.account}
                           location={location}
                           updateUserInfo={this.setAccountInfo}
                           {...appServices}>
                </FormLogin>
              </FormFrame>
            }>
            </Route>

            <Route exact path="/login/:accessCode" render={(routedProps) =>
              <Login account={this.state.account}
                     location={routedProps.location}
                     accessCode={routedProps.match.params.accessCode}
                     updateUserInfo={this.setAccountInfo}
                     {...appServices}>
                <MetaTags>
                  <title>Login - HyperGrade</title>
                  <meta name="description" content="Log in to HyperGrade."/>
                </MetaTags>
              </Login>
            }>
            </Route>

            <Route exact path="/user/logout">
              <Login account={this.state.account}
                     updateUserInfo={this.setAccountInfo}
                     genericModalCallback={this.openGenericModal}
                     notification="You have successfully logged out."
                     {...appServices}>
                <MetaTags>
                  <title>Logout - HyperGrade</title>
                  <meta name="description" content="Log out of HyperGrade."/>
                </MetaTags>
              </Login>
            </Route>

            {/*<Route exact path="/lms/:partialUserCode" render={(routedProps) =>*/}
            {/*  <LandingLMS appServices={appServices}*/}
            {/*              location={routedProps.location}*/}
            {/*              partialUserCode={routedProps.match.params.partialUserCode}*/}
            {/*              updateUserInfo={this.setAccountInfo}*/}
            {/*  />*/}
            {/*}>*/}
            {/*</Route>*/}

            <Route exact path="/support" render={(location) =>

              <FormFrame>
                <MetaTags>
                  <title>Support - HyperGrade</title>
                  <meta name="description" content="Fill out this form to request a demo or to get help."/>
                </MetaTags>
                <FormSupport account={this.state.account}
                             location={location}
                             updateUserInfo={this.setAccountInfo}
                             appServices={appServices}>
                </FormSupport>
              </FormFrame>
            }/>

            <Route exact path="/privacy">
              <MetaTags>
                <title>Privacy Policy - HyperGrade</title>
                <meta name="description" content="HyperGrade's privacy policy."/>
              </MetaTags>
              <Privacy appServices={appServices} account={this.state.account}/>
            </Route>

            <Route exact path="/forgot-password">
              <MetaTags>
                <title>Forgot Password - HyperGrade</title>
                <meta name="description" content="Fill out the form to reset your password."/>
              </MetaTags>
              <FormFrame>
                <FormForgotPassword appServices={appServices}
                                    account={this.state.account}
                                    updateUserInfo={this.setAccountInfo}/>
              </FormFrame>
            </Route>

            <Route exact path="/register">
              <MetaTags>
                <title>Registration - HyperGrade</title>
                <meta name="description" content="Register for a HyperGrade account."/>
              </MetaTags>
              <FormFrame>
                <FormRegister appServices={appServices}
                              account={this.state.account}
                              updateUserInfo={this.setAccountInfo}/>
              </FormFrame>
            </Route>

            <Route exact path="/register/:courseCode"
                   render={(routedProps) =>
                     <FormFrame>
                       <MetaTags>
                         <title>Registration - HyperGrade</title>
                         <meta name="description" content="Register for a HyperGrade account."/>
                       </MetaTags>
                       <FormRegister appServices={appServices}
                                     account={this.state.account}
                                     updateUserInfo={this.setAccountInfo}
                                     courseID={routedProps.match.params.courseCode}
                       />
                     </FormFrame>
                   }/>

            <Route userIsLoggedIn={false} exact path="/reset/:pin"
                   render={(routedProps) =>
                     <TallBox>
                       <MetaTags>
                         <title>Password Reset - HyperGrade</title>
                         <meta name="description" content="Password reset form."/>
                       </MetaTags>
                       <MenuBar appServices={appServices} logout={this.logout} account={undefined}/>
                       <MainBox>
                         <ResetPasswordWithPin
                           updateUserInfo={this.setAccountInfo}
                           genericModalCallback={this.openGenericModal}
                           pin={routedProps.match.params.pin}
                           {...appServices}/>
                       </MainBox>
                       <HGFooter/>
                     </TallBox>
                   }/>

            <Route exact path="/">
              <MetaTags>
                <title>HyperGrade</title>
                <meta name="description"
                      content="Create programming challenges for your students. Used by teachers and students across the US."/>
              </MetaTags>
              {/*one-off design: menubar and footer and inside the Home component*/}
              <Home appServices={appServices} logout={this.logout} account={this.state.account}/>
            </Route>

            <Route exact path="/loading">
              <MetaTags>
                <title>HyperGrade</title>
                <meta name="description"
                      content="Loading. Please wait."/>
              </MetaTags>
                <LoadingPage appServices={appServices} />
            </Route>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/enroll/:courseID"
                            render={(routedProps) =>
                              <TallBox>
                                <MetaTags>
                                  <title>Course Enrollment - HyperGrade</title>
                                  <meta name="description" content="Enroll in a HyperGrade course."/>
                                </MetaTags>
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <Enroll appServices={appServices}
                                          courseID={routedProps.match.params.courseID}
                                          account={this.state.account}
                                  />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>


            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/enroll"
                            render={(routedProps) =>
                              <TallBox>
                                <MetaTags>
                                  <title>Course Enrollment - HyperGrade</title>
                                  <meta name="description" content="Enroll in a HyperGrade course."/>
                                </MetaTags>
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <Enroll appServices={appServices}
                                          account={this.state.account}
                                  />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/my-grades">
              <TallBox>
                <MetaTags>
                  <title>My Grades - HyperGrade</title>
                  <meta name="description" content="View feedback and grades for my work."/>
                </MetaTags>
                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                <MainBox>
                  <MyGrades {...appServices}/>
                </MainBox>
                <HGFooter/>
              </TallBox>
            </ProtectedRoute>

            <ProtectedRoute exact path="/user/settings" userIsLoggedIn={this.userIsLoggedIn}>
              <TallBox>
                <MetaTags>
                  <title>Setting - HyperGrade</title>
                  <meta name="description" content="Change user preferences."/>
                </MetaTags>
                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                <MainBox>
                  <Settings
                    account={this.state.account ? this.state.account : {}} //TODO: hack to prevent warning about missing required prop. Why?
                    appServices={appServices}
                  />
                </MainBox>
                <HGFooter/>
              </TallBox>
            </ProtectedRoute>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/home">
              <TallBox>
                <MetaTags>
                  <title>Dashboard - HyperGrade</title>
                  <meta name="description" content="HyperGrade's main account dashboard."/>
                </MetaTags>
                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                <MainBox>
                  {this.userIsStudent() ? <StudentHome/> : null}
                  {this.userIsTeacher() ? <CourseList account={this.state.account} appServices={appServices}/> : null}
                </MainBox>
                <HGFooter/>
              </TallBox>
            </ProtectedRoute>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/grade/:key/:assignmentID"
                            render={(routedProps) =>
                              <TallBox>
                                <MetaTags>
                                  <title>Grade Approval - HyperGrade</title>
                                  <meta name="description" content="Approve or reject a student's grade."/>
                                </MetaTags>
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <GradeDetail assignmentID={parseInt(routedProps.match.params.assignmentID)}
                                               enrollmentKey={routedProps.match.params.key} {...appServices} />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/question/:questionID/submit"
                            render={(routedProps) =>
                              <TallBox>
                                <MetaTags>
                                  <title>Code Submission - HyperGrade</title>
                                  <meta name="description" content="Submit your work to HyperGrade for testing."/>
                                </MetaTags>
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <Submit
                                    questionID={parseInt(routedProps.match.params.questionID)} {...appServices} />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/course/:courseID/"
                            render={(routedProps) =>
                              <TallBox>
                                <MetaTags>
                                  <title>View Course - HyperGrade</title>
                                  <meta name="description" content="View a single course."/>
                                </MetaTags>
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <Course courseID={parseInt(routedProps.match.params.courseID)} {...appServices} />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/assignment/:assignmentID"
                            render={(routedProps) =>
                              <TallBox>
                                {/*meta is in assignment*/}
                                <MainBox>
                                  <Assignment
                                    assignmentID={parseInt(routedProps.match.params.assignmentID)} {...appServices} />
                                </MainBox>

                              </TallBox>
                            }/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/question/:questionID/edit"
                            render={(routedProps) =>
                              <TallBox>
                                <MetaTags>
                                  <title>Question Editor - HyperGrade</title>
                                  <meta name="description"
                                        content="Create a question by uploading source code and make test cases."/>
                                </MetaTags>
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <QuestionEditor
                                    account={this.state.account}
                                    questionID={parseInt(routedProps.match.params.questionID)} {...appServices} />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            {/*question mark means optional parameter*/}
            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn}
                            path="/course/:courseID/assignment-editor/:assignmentID?"
                            render={(routedProps) => {

                              const {courseID, assignmentID} = routedProps.match.params;

                              return (
                                <TallBox>
                                  <MetaTags>
                                    <title>Assignments Overview - HyperGrade</title>
                                    <meta name="description" content="View all assignments."/>
                                  </MetaTags>
                                  <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                  <MainBox>
                                    <AssignmentEditor
                                      courseID={parseInt(courseID)}
                                      account={this.state.account}
                                      assignmentID={assignmentID ? parseInt(assignmentID) : undefined}
                                      appServices={appServices}/>
                                  </MainBox>
                                  <HGFooter/>
                                </TallBox>
                              )
                            }}/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/course/:courseID/students"
                            render={(routedProps) =>
                              <TallBox>
                                {/*meta inside*/}
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <StudentList courseID={parseInt(routedProps.match.params.courseID)}
                                               appServices={appServices}/>
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            <ProtectedRoute userIsLoggedIn={this.userIsLoggedIn} exact path="/course/:courseID/grades"
                            render={(routedProps) =>
                              <TallBox>
                                {/*meta inside*/}
                                <MenuBar appServices={appServices} logout={this.logout} account={this.state.account}/>
                                <MainBox>
                                  <Grades courseID={parseInt(routedProps.match.params.courseID)} appServices={appServices} />
                                </MainBox>
                                <HGFooter/>
                              </TallBox>
                            }/>

            <Route path="/" render={() =>
              // meta inside
              <NotFound userIsLoggedIn={this.userIsLoggedIn}/>
            }/>

          </Switch>
          {this.state.modalMessage &&
            <ModalSkeleton onClose={this.closeGenericModal} instructions={this.state.modalTitle}>
              {this.state.modalMessage}
            </ModalSkeleton>
          }
          {
            this.state.notificationMessage &&
            <Layer
              position="bottom"
              modal={false}
              margin={{vertical: "medium", horizontal: "small"}}
              onEsc={this.closeNotification}
              responsive={false}
              plain
            >
              <Box
                align="center"
                direction="row"
                gap="small"
                justify="between"
                round="medium"
                elevation="medium"
                pad={{vertical: "xsmall", horizontal: "small"}}
                background={this.state.background}
              >
                <Box align="center" direction="row" gap="xsmall">
                  {this.state.icon}
                  <Text>{this.state.notificationMessage}</Text>
                </Box>
                <Button icon={<FormClose/>} onClick={this.closeNotification} plain/>
              </Box>
            </Layer>
          }
          {this.state.showWorking &&
            <Layer position="center" modal animation="fadeIn">
              <Box margin="large" gap="small" align="center">
                <Heading textAlign="center" level={3} margin="none">Working</Heading>
                {!!this.state.workingMessage &&
                  <Paragraph textAlign="center" margin="none"
                             dangerouslySetInnerHTML={{__html: this.state.workingMessage}}/>
                }
                <Loading block={false}/>
              </Box>
            </Layer>
          }
        </Grommet>
      </Router>
    )
  }
}

export default App;
