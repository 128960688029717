//react
import React from "react"
import PropTypes from "prop-types";
import HyperGrade from "../../hg-config";
import qs from "qs";
import {Collapsible} from "grommet";
import {Redirect} from "react-router-dom";

//hypergrade

class FormRegister extends React.Component {

  constructor(props) {
    super(props);

    let queryStrings = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    this.state = {
      email: queryStrings && queryStrings.email ? queryStrings.email : "",
      firstName: "",
      lastName: "",
      password: "",
      password2: "",
    };

    this.studentSelected = this.studentSelected.bind(this);
    this.submit = this.submit.bind(this);
    this.manage = HyperGrade.util.manage.bind(this);

    this.postRegisterAction = HyperGrade.postRegisterAction.bind(this);

    //we are probably already logged in
    if (this.props.account) {
      if (this.props.courseID && this.props.account.role === "student") {
        this.state.redirect = "/enroll/" + this.props.courseID;
      } else {
        this.state.redirect = "/home";
      }
    }
  }

  componentDidMount() {
  }

  studentSelected() {
    return this.state.role === "student";
  }

  submit(e) {
    e.preventDefault();

    if (this.state.password !== this.state.password2) {
      this.setState({passwordError: "Passwords don't match"});
      return;
    }

    this.props.appServices.beginWorking();
    HyperGrade.services.register(
      this.state.email,
      this.state.role,
      this.state.firstName,
      this.state.lastName,
      this.state.password).then(res => {

      if (res.success) {
        //if the course code has a problem, the enroll page will ignore
        if (this.props.courseID && this.studentSelected()) {
          this.postRegisterAction(res.data, "/enroll/" + this.props.courseID);
        } else {
          this.postRegisterAction(res.data);
        }
      } else {
        if (res.code === "MISSING_KEYS") {
          this.props.appServices.showErrorNotification(res.msg);
        } else {
          Object.keys(res.data).forEach(key => {
            this.setState({[key + "Error"]: res.data[key]});
          });
        }
      }
      this.props.appServices.endWorking();
    });

  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    return (
      <div id={"registration-form"} className={"form-frame-theme"}>
        <div className={"lead"}>
          <h1>Create an account</h1>
          <p>Already have an account? <a href={"/login"}>Sign in</a></p>
        </div>
        <form method={"post"} onSubmit={this.submit}>
          <div className={"form-group"}>
            <label htmlFor={"email"}>Email</label>
            <input type={"email"} name={"email"} id={"email"} {...this.manage('email')} required/>
            <p className={"error"}>{this.state.emailError}</p>
          </div>
          <div className={"form-group iama"}>
            <label>I am a</label>
            <div {...this.manage('role', 'radio-button-group')}>
              <input type="radio" id="student" name="role" value="student" required/>
              <label htmlFor="html">Student</label>
              <input type="radio" id="teacher" name="role" value="teacher"/>
              <label htmlFor="css">Teacher</label>
            </div>
            <p className={"error"}>{this.state.roleError}</p>
          </div>

          <Collapsible open={this.studentSelected()} className={"form-group"}>
            <div className={"form-group"}>
              <label>First name</label>
              <input type={"text"} id={"firstName"} name={"firstName"} {...this.manage('firstName')}
                     required={this.studentSelected()}/>
              <p className={"error"}>{this.state.firstNameError}</p>
            </div>

            <div className={"form-group"}>
              <label>Last name</label>
              <input type={"text"} id={"lastName"} name={"lastName"} {...this.manage('lastName')}
                     required={this.studentSelected()}/>
              <p className={"error"}>{this.state.lastNameError}</p>
            </div>
          </Collapsible>

          <div className={"form-group"}>
            <label>Password</label>
            <input type={"password"} id={"password"} name={"password"} {...this.manage('password')} required/>
            <p className={"error"}>{this.state.passwordError}</p>
          </div>
          <div className={"form-group"}>
            <label>Repeat Password</label>
            <input type={"password"} id={"password2"} name={"password2"} {...this.manage('password2')} required/>
            <p className={"error"}>{this.state.password2Error}</p>
          </div>
          <div className={"form-group"}>
            <input className={"hg-button-1"} type={"submit"} value={"Submit"}/>
          </div>
        </form>
        <p>Review our <a href={"/privacy"}>Privacy policy</a></p>
      </div>
    )
  }
}

FormRegister.propTypes = {
  example: PropTypes.number,
};

FormRegister.defaultProps = {
  example: 5
};

export default FormRegister