//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Text} from "grommet";
import {Info} from 'grommet-icons';

class Suggest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {color: "neutral-3"};
  }

  componentDidMount() {
  }

  render() {
    return (
      <Box direction="row" align="center" gap="xsmall">
        <Info color={this.state.color}/><Text color={this.state.color}>{this.props.message}</Text>
      </Box>
    )
  }
}

Suggest.propTypes = {
  message: PropTypes.string.isRequired
};

Suggest.defaultProps = {};

export default Suggest