//react
import React from "react"

//hypergrade
import HyperGrade from '../../hg-config';
import Models from "../../models/appServices";
import MenuBar from "./MenuBar";

//images
import iUpload from '../../images/home/upload.svg';
import iPencil from '../../images/home/pencil.svg';
import iCheck from '../../images/home/check.svg';
import iJumpingStudent from '../../images/home/jumping-student.svg';
import iStudentsInClassroom from '../../images/home/students-in-classroom.svg';
import iPurpleCheck from '../../images/home/purple-check.svg';
import iFiveStars from '../../images/home/stars.svg';
import iZare from '../../images/home/zare.png';
import iJuan from '../../images/home/juan.png';
import iCurlyLeft from '../../images/home/curly-left.svg';
import iCurlyRight from '../../images/home/curly-right.svg';

import ContactUs from "./ContactUs";
import HGFooter from "./HGFooter";

class Home extends React.Component {

  render() {

    return (
      <div className={"super-hg"} id={"page-home"}>
        <MenuBar appServices={this.props.appServices} fixed/>
        <main>
          <div className={"hero"}>
            <div className={"hero-image-layer"}>
              <div className={"hero-inner"}>
                <div className={"left"}>
                  <p className={"pre-title"}>Supports <span
                    className={"highlight-1"}>{HyperGrade.supportedLanguagesString}</span></p>
                  <h1>Create <span className={"highlight-2"}>the best &lt;/&gt;</span> programming challenges for your
                    students</h1>
                  <p className={"post-title"}>Help your students master the fundamentals, object-oriented programming,
                    data
                    structures, and more.</p>
                  <form action={"/register"} method={"get"}>
                    <input type={"email"} name={"email"} placeholder={"Enter your email"}/>
                    <input type={"submit"} value={"Start"}/>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class={"video-block"}>
            <div className={"copy-container"}>
              <h2><span className={"highlight-2"}>Watch</span> our video</h2>
              <h3 className={"lead"}>Learn how easy it is to make an assignment</h3>
              <p>
                HyperGrade lets you create both simple and complex problems. In this video, we show the creation
                of a basic question and show what the student experiences when working on your problems.
              </p>
            </div>
            <div className={"video-container"}>
              <iframe src="https://www.youtube.com/embed/10E-ZYz6-4E"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen/>
            </div>
          </div>

          <div className={"easy-steps"}>
            <h2><span className={"highlight-2"}>3 Easy Steps</span> to Start</h2>
            <h3>
              Or, just <a href={"/support?src=sendAssignments"}>email us your assignments</a>.
            </h3>
            <ul>
              <li>
                <div className={"icon-wrapper upload"}>
                  <img src={iUpload} alt={"Upload Icon"}/>
                </div>
                <h4>Upload Source Code</h4>
                <p>
                  Create your files in any editor. Upload the source code to HyperGrade.
                </p>
              </li>
              <li>
                <div className={"icon-wrapper pencil"}>
                  <img src={iPencil} alt={"Edit Icon"}/>
                </div>
                <h4>Create Test Cases</h4>
                <p>
                  Provide standard input, command line arguments, and input files.
                </p>
              </li>
              <li>
                <div className={"icon-wrapper check"}>
                  <img src={iCheck} alt={"Complete Icon"}/>
                </div>
                <h4>Send Link</h4>
                <p>
                  Your assignment is ready. Send the assignment link to your students.
                </p>
              </li>
            </ul>
          </div>

          <div className={"payment"}>
            <div className={"payment-inner"}>
              <div className={"left"}>
                <div className={"price-pill"}>
                  <img src={iJumpingStudent} alt={"Jumping Student"}/>
                  ${HyperGrade.keyCost} for students
                </div>
              </div>
              <div className={"right"}>
                <p>Students can either pay at <span className={"highlight-1"}>your bookstore</span> or pay
                  directly <span
                    className={"highlight-2"}>on our site</span>.</p>
              </div>
            </div>
          </div>

          <div className={"step-up"}>
            <div className={"step-up-inner"}>
              <div className={"left"}>
                <div className={"content"}>
                  <h2><span className={"highlight-2"}>Gradually</span><br/>increase difficulty</h2>
                  <h3>Increase your student success rate!</h3>
                  <p>
                    HyperGrade automates code testing, code submission, and grading. Students are provided instant
                    feedback;
                    they know precisely where their code needs improvement. Because of this, instructors are now able to
                    produce
                    assignments that have many "mini-questions" so the student can gain confidence and skills as they
                    progress to larger programs. The question <span className={"highlight-2"}>"does this program meet all your requirements?"</span> will
                    be a thing of the past.
                  </p>
                </div>
              </div>
              <div className={"right"}>
                <img src={iStudentsInClassroom} alt={"Students in classroom"}/>
              </div>
            </div>
          </div>

          <div className={"mini-panels"}>
            <div className={"left"}>
              <div className={"pill"}>
                <h2>Instructors <span className={"highlight-2"}>approve</span> recommended grades.</h2>
                <img src={iPurpleCheck} alt={"Purple Checkmark"}/>
              </div>
            </div>
            <div className={"right"}>
              <ol>
                <li>
                  <b>Instructors write model programs</b> that produce some output. We
                  support {HyperGrade.supportedLanguagesString}.
                </li>
                <li><b>Students write programs</b> that produce the same output (code may be different).</li>
                <li><b>Submissions are checked</b> for plagiarism.</li>
                <li><b>Instructors review submitted work</b> and approve the recommended grade.</li>
              </ol>
            </div>
          </div>

          <div className={"testimonials"}>
            <div className={"copy"}>
              <p><span className={"highlight-1"}>Read testimonials</span></p>
              <h2>What our teachers say</h2>
              <h3>We <span className={"heart"}>❤</span> Community Colleges and High Schools</h3>
            </div>
            <div className={"testimonial-cards"}>
              <div className={"testimonial-card"}>
                <div className={"left"}>
                  <img src={iZare} alt={"Zare Agazaryan"}/>
                </div>
                <div className={"right"}>
                  <div className={"content"}>
                    <blockquote>"HyperGrade is the most used piece of software in my classrooms. My work as an educator
                      and students' work as learners depends on HyperGrade heavily."
                    </blockquote>
                    <div>
                      <div className={"signature"}>
                        <div className={"name highlight-1"}>Zare Agazaryan</div>
                        <div className={"title"}>Instructor of Computer Science</div>
                        <div className={"location"}>Glendale Community College · Glendale, CA</div>
                      </div>
                      <img src={iFiveStars} alt={"Five Stars"}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"testimonial-card"}>
                <div className={"left"}>
                  <img src={iJuan} alt={"Juan Leon"}/>
                </div>
                <div className={"right"}>
                  <div className={"content"}>
                    <blockquote>"I've been using Hypergrade for about 3 years. The user interface easily allows me to
                      set
                      up my own assignments with a number of different test cases."
                    </blockquote>
                    <div>
                      <div className={"signature"}>
                        <div className={"name highlight-1"}>Juan Leon</div>
                        <div className={"title"}>Computer Science Faculty</div>
                        <div className={"location"}>Pasadena City College · Pasadena, CA</div>
                      </div>
                      <img src={iFiveStars} alt={"Five Stars"}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"send-assignments"}>
            <img src={iCurlyLeft} alt={"Left curly bracket"}/>
            <div className={"send-assignments-inner"}>
              <div className={"content"}>
                <h2><span className={"highlight-3"}>Teachers,</span> send us your assignments.</h2>
                <p>We will automate them.</p>
                <div className={"ctas"}>
                  <a href={"/register"}>Try Free Account</a>
                  <a href={"/support?src=sendAssignments"}>Send Assignments</a>
                </div>
              </div>
            </div>
            <img src={iCurlyRight} alt={"Right curly bracket"}/>
          </div>

          <div className={"contact"}>
            <div className={"contact-inner"}>
              <h2>We're here to answers any of <span className={"highlight-1"}>your questions</span></h2>
              <ContactUs appServices={this.props.appServices}/>
            </div>
          </div>

        </main>
        <HGFooter appServices={this.props.appServices}/>
      </div>


    )
  }
}

Home.propTypes = {
  appServices: Models.appServices.isRequired,
};

Home.defaultProps = {};

export default Home
