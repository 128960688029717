//react
import React from "react"
import PropTypes from "prop-types";
//packages
import Dropzone from 'react-dropzone'
//grommet
import {Box, Paragraph} from "grommet";
import {DocumentUpload, Halt} from 'grommet-icons';

class GrommetDropzone extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.inBigMode = () => this.props.height === "medium";
  }

  render() {

    let color = this.props.error ? "status-critical" : undefined;

    if (this.props.disabled) {
      return (
        <Box height="small" border={{size: "large", style: "dashed"}}>
          <Box fill align="center" justify="center" gap="small">
            <Paragraph margin="none"/>
            <Halt size="large" title="No more changes can be made"/>
            <Paragraph margin="none">The assignment due date has passed.</Paragraph>
          </Box>
        </Box>);
    } else {
      return (
        <Dropzone onDrop={this.props.onDrop}>
          {({getRootProps, getInputProps}) => (
            <Box height={this.props.height} className="t1" border={{size: "large", style: "dashed"}} {...getRootProps()}>
              <input {...getInputProps()} />
              <Box fill align="center" justify="center" gap="medium">
                <DocumentUpload size="large"
                                color={color}
                                title="Drag and drop (or click) to upload files"/>
                <Paragraph margin="none"
                           size={this.inBigMode() ? "large" : "medium"}
                           color={color}>{this.props.message ? this.props.message : "Drag and drop (or click) to upload files"}</Paragraph>
              </Box>
            </Box>
          )}
        </Dropzone>
      )
    }
  }
}

GrommetDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.bool,
  height: PropTypes.string,
};

GrommetDropzone.defaultProps = {
  disabled: false,
  error: false,
  height: "small"
};

export default GrommetDropzone