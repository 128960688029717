//react
import React from "react"
//grommet
import {Box, Button, Heading, Text} from "grommet";
import {NewWindow} from 'grommet-icons';
//hypergrade
//lodash
import PropTypes from "prop-types";
import Moment from 'moment';
import 'moment-timezone';
import Pluralize from "react-pluralize";
import YesNoModal from "./YesNoModal";


import HyperGrade from "../hg-config";
import {Redirect} from "react-router-dom";
import AssignmentPasswordModal from "./AssignmentPasswordModal";


class AssignmentProgressCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.useLateDays = this.useLateDays.bind(this);
    this.closeLateDayModal = this.closeLateDayModal.bind(this);
    this.allQuestionsComplete = this.allQuestionsComplete.bind(this);
  }

  componentDidMount() {
    this.setState({now: new Moment(), loading: false});
  }

  useLateDays(howMany) {
    this.setState({confirmLateDayUsage: howMany});
  }

  closeLateDayModal() {
    this.setState({confirmLateDayUsage: undefined});
  }

  allQuestionsComplete() {
    let reducer = (accumulator, currentValue) => accumulator && currentValue.status && currentValue.status.complete;
    return this.props.assignment.questions.reduce(reducer, true);
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.state.loading) {
      return <Box/>;
    }

    let myDueDateMoment = HyperGrade.calcMyDueDateMoment(this.props.assignment.myDueDateUTC);

    let lateDaysNeededToUnlock = this.state.now.diff(myDueDateMoment, 'days') + 1;

    let allQuestionsComplete = this.allQuestionsComplete();

    let lateDayButton = null;

    //if late days are allowed, and late days would actually be useful, and we have enough late days
    if (!this.props.assignment.disable_late_days
      && this.state.now.isAfter(myDueDateMoment)
      && lateDaysNeededToUnlock > 0
      && lateDaysNeededToUnlock <= this.props.lateDaysRemaining
      && !allQuestionsComplete
    ) {

      let label = <Text>Use <Pluralize singular={'late day'} count={lateDaysNeededToUnlock}/></Text>;
      lateDayButton = <Button label={label} onClick={this.useLateDays.bind(this, lateDaysNeededToUnlock)}/>
    }

    let passwordInput;
    if (!this.props.assignment.canView) {
      passwordInput = (
        <Box direction={"row"} justify={"center"}>
          <Button label={"Enter Password"} onClick={() => this.setState({passwordModal: true})}/>
          {this.state.passwordModal &&
          <AssignmentPasswordModal
            onClose={() => this.setState({passwordModal: false})}
            assignmentID={this.props.assignment.id}
            onSuccess={(route) => this.setState({redirect: route})}
          />
          }
        </Box>
      );
    }

    return (
      <Box background="light-3" align="center" pad="medium" gap="small"
           border={{size: "medium"}} round elevation="large"
      >
        <Button target="_blank" href={"/assignment/" + this.props.assignment.id}>
          <Box direction="row" align="center" gap="small">
            <Heading level="4" margin="none">{this.props.assignment.name}</Heading>
            <NewWindow/>
          </Box>
        </Button>
        <Text textAlign="center">
          Due {myDueDateMoment.fromNow()}<br/>
          {myDueDateMoment.format("LLLL")}
        </Text>
        {lateDayButton}
        <Box fill gap="xsmall" margin={{top: lateDayButton ? "none" : "large"}}>
          {passwordInput}
          {this.props.assignment.questions.map((question, index) => {

            let bg = "hg-red-bg";
            let label = "No attempt";
            if (question.status) {
              if (question.status.complete) {
                bg = "hg-green-bg";
                label = "All passed";
              } else if (question.status.num_submitted_files) {
                bg = "hg-yellow-bg";
                label = "Failed cases";
              }
            }

            return (
              <Button key={"late-day-button-" + question.id} href={"/question/" + question.id + "/submit"}>
                <Box pad="small" round align="center" className={bg} elevation="large" border>
                  Question {index + 1} {'\u00b7'} {label}
                </Box>
              </Button>
            );
          })}
        </Box>
        {this.state.confirmLateDayUsage &&
        <YesNoModal
          bodyText={<Text>Clicking 'yes' will use <Pluralize singular={'late day'} count={lateDaysNeededToUnlock}/> to
            unlock the assignment.</Text>}
          onClickYes={() => {
            this.closeLateDayModal();
            this.props.useLateDays(this.state.confirmLateDayUsage);
          }}
          onSelectNo={this.closeLateDayModal}
        />
        }
      </Box>
    )
  }
}

AssignmentProgressCard.propTypes = {
  assignment: PropTypes.object.isRequired,
  lateDaysRemaining: PropTypes.number.isRequired,
  useLateDays: PropTypes.func.isRequired
};

AssignmentProgressCard.defaultProps = {};

export default AssignmentProgressCard