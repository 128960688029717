//react
import React from "react"
//grommet
import {Box, Button, Collapsible} from "grommet";
import {Alert, Close} from "grommet-icons";

//hypergrade

class HyperGradeAlert extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: true
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render() {
    return (
      <Collapsible open={this.state.open}>
        <Box background="brand" direction="row" align="center" gap="small" pad={{horizontal: "medium"}}
             justify="between">
          <Box direction="row" align="center" gap="small">
            <Alert/>
            {this.props.children}
          </Box>
          <Button icon={<Close/>} onClick={() => this.setState({open: false})}/>
        </Box>
      </Collapsible>
    )
  }
}

HyperGradeAlert.propTypes = {};

HyperGradeAlert.defaultProps = {};

export default HyperGradeAlert