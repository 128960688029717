//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Heading, Paragraph} from "grommet";
import {Lock} from 'grommet-icons';
//hypergrade
import HyperGrade from '../hg-config';
import PasswordChangeForm from "./PasswordChangeForm";
import Loading from "./Loading";

class ResetPasswordWithPin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};
  }

  componentDidMount() {
    HyperGrade.services.isPinStillValid(this.props.pin).then(server => {
      this.setState({pinError: !server.success, loading: false});
    });
  }

  render() {

    if (this.state.loading) {
      return <Loading/>;
    }

    let content;
    if (this.state.pinError) {
      content =
        <Paragraph margin="none">The given pin is no longer valid. <Anchor href="/login">Request a new
          one</Anchor>.</Paragraph>
    } else {
      content = <PasswordChangeForm {...this.props}/>;
    }

    return (
      <Box align="center">
        <Box margin="large" gap="medium" justify="center">
          <Box direction="row" gap="small" align="center">
            <Box animation="pulse">
              <Lock size="large"/>
            </Box>
            <Heading level="2" margin="none">Create a new password</Heading>
          </Box>
          {content}
        </Box>
      </Box>
    )
  }
}

ResetPasswordWithPin.propTypes = {
  pin: PropTypes.string.isRequired,
  beginWorking: PropTypes.func.isRequired,
  endWorking: PropTypes.func.isRequired,
  showSuccessNotification: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
};

ResetPasswordWithPin.defaultProps = {};

export default ResetPasswordWithPin