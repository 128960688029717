//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Anchor, Box, Grid, Heading, Paragraph} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';
import GrommetDropzone from "./GrommetDropzone";
import Loading from "./Loading";
import CompilationError from "./CompilationError";
//lodash
import _ from "lodash";
import QuestionSelect from "./QuestionSelect";
import QuestionText from "./QuestionText";
import StopLight from "./StopLight";
import FileTable from "./FileTable";
import CharacterByCharacterCombined from "./CharacterByCharacterCombined";
import ModalSkeleton from "./ModalSkeleton";
import {Alert, Validate} from "grommet-icons";
import AssignmentPasswordModal from "./AssignmentPasswordModal";
import {Redirect} from "react-router-dom";

class Submit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.onDrop = this.onDrop.bind(this);
    this.compileAndRunAll = HyperGrade.compileAndRunAll.bind(this);

    this.getNumCasesPassed = this.getNumCasesPassed.bind(this);
    this.getNumCases = this.getNumCases.bind(this);
    this.assignmentIsComplete = this.assignmentIsComplete.bind(this);

    this.canTurnIn = this.canTurnIn.bind(this);
    this.updateTurnInStatus = this.updateTurnInStatus.bind(this);
    this.refresh = this.refresh.bind(this);
    this.updateFileList = this.updateFileList.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getNumSubmittedFiles = this.getNumSubmittedFiles.bind(this);
    this.isFileSubmissionQuestion = () => this.state.question ? this.state.question.type === "file_submission" : false;
  }

  updateFileList(files) {
    this.setState({files});
    this.compileAndRunAll(); //calls endWorking
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({loading: true, showPasswordModal: false, assignmentID: null});
    HyperGrade.services.getQuestionComponents(this.props.questionID)
      .then(server => {
        if (server.data.problem === 'PASSWORD_REQUIRED') {
          this.setState({showPasswordModal: true, assignmentID: server.data.assignmentID});
        } else {
          this.setState({...server.data});
        }
        this.setState({loading: false});
      });
  }

  updateTurnInStatus() {
    return HyperGrade.services.canTurnIn(this.state.question.assignment_id).then(res => {
      this.setState({canTurnIn: res.success});
    });
  }

  getNumSubmittedFiles() {
    return Array.isArray(this.state.files) ? this.state.files.length : 0;
  }

  getFile(id) {
    return Array.isArray(this.state.files) ? this.state.files.find(file => file.id === id) : null;
  }

  canTurnIn() {
    return this.state.canTurnIn;
  }

  onDrop(acceptedFiles) {

    this.props.beginWorking("Uploading your files");

    let conflict = acceptedFiles.find(draggedFile =>
      this.state.files.find(projectFile =>
        projectFile.inherited && draggedFile.name === projectFile.name));

    if (conflict) {
      this.props.showErrorNotification("Could not upload "
        + conflict.name
        + " because the teacher already provided a file with the same name.");
      this.props.endWorking();
    } else {
      this.updateTurnInStatus().then(() => {
        if (this.canTurnIn()) {
          HyperGrade.services.storeFileForQuestion(this.props.questionID, acceptedFiles).then(server => {
            if (server.success) {
              let updatedFiles = _.cloneDeep(this.state.files);
              //iterate over the accepted files
              server.data.forEach(current => {
                let index = updatedFiles.findIndex(file => file.name === current.name);
                let newFile = _.cloneDeep(current);
                newFile.isNewOrUpdated = true;
                if (index >= 0) {
                  updatedFiles[index] = newFile;
                } else {
                  updatedFiles.push(newFile);
                }
              });
              this.setState({files: updatedFiles});
              this.compileAndRunAll() //calls endWorking
                .then(() => {
                  if (this.assignmentIsComplete()) {
                    this.setState({showCongratulationsModal: true});
                  }
                });
            } else {
              this.props.endWorking();
              this.props.showErrorNotification("Could not store file");
            }
          })
          .catch(err => {
            this.props.endWorking();
            if (err === HyperGrade.fetchErrorCode) {
              this.setState({changedFileWarning: true});
            } else {
              console.log("Fetch error: " + err);
            }
          })
          ;
        } else {
          this.props.endWorking();
          this.props.showErrorNotification("The assignment due date has passed.");
        }
      });
    }
  }

  getNumCases() {
    return this.state.test_cases.length;
  }

  getNumCasesPassed() {
    return Submit.getNumCasesPassed(this.state.runResults);
  }

  static getNumCasesPassed(runResults) {
    return Array.isArray(runResults) ? runResults.reduce((a, b) => a + b.matches, 0) : 0;
  }

  assignmentIsComplete() {
    return this.getStatus() === 'good';
  }

  getStatus() {
    if (this.isFileSubmissionQuestion() && this.getNumSubmittedFiles() > 0) {
      return "good";
    } else if (Array.isArray(this.state.runResults) && this.state.runResults.length > 0) {
      let casesPassed = this.getNumCases();
      let numTotalCases = this.getNumCasesPassed();

      if (casesPassed === numTotalCases)
        return "good";
      else if (casesPassed === 0)
        return "bad";
      else
        return "warning";
    } else {
      return 'unknown';
    }
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>;
    }

    if (this.state.loading) {
      return <Loading/>
    }

    if (this.state.showPasswordModal) {
      return <AssignmentPasswordModal
        onClose={() => this.setState({redirect: '/home'})}
        onSuccess={this.refresh}
        assignmentID={this.state.assignmentID}
      />
    }

    let resultsReport;
    if (this.state.runResults && !this.isFileSubmissionQuestion()) {
      let resultsLabel = this.getNumCasesPassed() + " of " + this.getNumCases() + " test cases passed";
      resultsReport = <Heading level="4" margin="none">{resultsLabel}</Heading>;
    }

    let fileError;
    let dropzoneMessage;
    let congratsMessageHeader;
    let congratsMessage;

    if (this.isFileSubmissionQuestion()) {
      fileError = false;
      dropzoneMessage =
        <Paragraph color={"neutral-1"} margin={"none"}>Submit files as directed by the question text below. This
          question is not automatically tested.</Paragraph>;
      congratsMessageHeader = "Complete!";
      congratsMessage = "Your file has been saved and is available to the instructor. You may submit more files if needed.";
    } else {
      fileError = !!this.state.projectError;
      dropzoneMessage = this.state.projectError;
      congratsMessageHeader = "You have passed all the test cases!";
      congratsMessage = "Your work has been saved and is available to the instructor. You are done with this question!";
    }

    return (
      <Box>
        <StopLight status={this.getStatus()}>
          {this.assignmentIsComplete() ?
            <Box align="center">
              <Box animation={"pulse"}>
                <Heading level="3">Question Complete</Heading>
              </Box>
            </Box> : null}
        </StopLight>

        <Box margin="large" gap="small">
          {/*header region*/}
          <Grid columns={["1/3", "1/3", "1/3"]}>
            <Box align="start" justify="end">
              <Heading level="2" margin="none">Submit your work</Heading>
            </Box>
            <Box align="center" justify="end">
              {resultsReport}
            </Box>
            <Box align="end" justify="end">
              <QuestionSelect selectedQuestionID={this.props.questionID}
                              action="submit"
                              assignment={this.state.assignment}
                              questions={this.state.questionIDs}/>
            </Box>
          </Grid>

          {/*file uploader region*/}
          <GrommetDropzone
            onDrop={this.onDrop}
            disabled={!this.canTurnIn()}
            error={fileError}
            message={dropzoneMessage}
          />

          <FileTable files={this.state.files} role={"student"}
                     updateFileList={this.updateFileList}
                     beginWorking={this.props.beginWorking}
                     endWorking={this.props.endWorking}
                     showErrorNotification={this.props.showErrorNotification}
          />

          <CompilationError message={this.state.compilationError}/>

          {/*question text*/}
          <QuestionText points={this.state.question.points} questionObj={this.state.question}/>

          {/*results*/}
          <Box gap="large">
            {this.state.test_cases.map((testCase, index) => {

              let studentAttempt = this.state.runResults.find(current => current.id === testCase.id);

              let teacherOutput, studentOutput;
              if (studentAttempt) {
                if (testCase.outputMixed && studentAttempt.outputMixed) {
                  teacherOutput = testCase.outputMixed;
                  studentOutput = studentAttempt.outputMixed;
                } else {
                  teacherOutput = testCase.output;
                  studentOutput = studentAttempt.output;
                }
              } else {
                teacherOutput = testCase.outputMixed ? testCase.outputMixed : testCase.output;
                studentOutput = undefined;
              }

              return (
                <CharacterByCharacterCombined
                  hide={!!testCase.hide}
                  plain={!studentOutput}
                  key={"submit-tc-" + testCase.id}
                  teacherOutput={teacherOutput}
                  studentOutput={studentOutput}
                  header={"Test Case " + (index + 1)}
                  passed={studentAttempt ? studentAttempt.matches > 0 : undefined} //accepts true, false, and undefined
                  runResult={studentAttempt}
                  command_line_arguments={testCase.command_line_arguments}
                />
              )

            })
            }
          </Box>

          {this.state.showCongratulationsModal &&
          <ModalSkeleton onClose={() => this.setState({showCongratulationsModal: false})}>
            <Box align="center">
              <Validate color={"status-ok"} size={"xlarge"}/>
              <Heading level={"3"}>{congratsMessageHeader}</Heading>
              <Paragraph margin={{top: "none"}} textAlign={"center"}>{congratsMessage}</Paragraph>
              <Paragraph textAlign={"center"}><Anchor href={"/home"}>Go to dashboard</Anchor></Paragraph>
            </Box>
          </ModalSkeleton>
          }

          {this.state.changedFileWarning &&
              <ModalSkeleton onClose={() => this.setState({changedFileWarning: false})}>
                <Box align="center">
                  <Alert color={"status-warning"} size={"large"}/>
                  <Heading level={"3"}>{"Save your files on your computer first, then resubmit."}</Heading>
                </Box>
              </ModalSkeleton>
          }

        </Box>
      </Box>
    )
  }
}

Submit.propTypes = {
  questionID: PropTypes.number
};

Submit.defaultProps = {};

export default Submit