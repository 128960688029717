//react
import React from "react"
import PropTypes from "prop-types";
import ContactUs from "./ContactUs";
import Models from "../../models/appServices";

//hypergrade

class FormSupport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

    //TODO: currently unused. Need to manage the inputs
    let reachOutProps = {};
    if (this.props.account) {
      reachOutProps.email = this.props.account.username;
      if (this.props.account.firstName && this.props.account.lastName) {
        reachOutProps.fullName = this.props.account.firstName + " " + this.props.account.lastName;
      }
    }

  }

  render() {
    return (
      <div className={"form-frame-theme"}>
        <div className={"lead"}>
          <h1>Contact us</h1>
          <p>We're here to answer your questions</p>
        </div>
        <ContactUs account={this.props.account}
                   appServices={this.props.appServices} />
        <p>New user? <a href={"/support"}>Create an account</a></p>
        <p>Already have an account? <a href={"/login"}>Sign in</a></p>
      </div>

    )
  }
}

FormSupport.propTypes = {
  appServices: Models.appServices.isRequired,
  account: PropTypes.object
};

FormSupport.defaultProps = {

};

export default FormSupport