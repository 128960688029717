//react
import React from "react"
import PropTypes from "prop-types";
//grommet
import {Box, Heading} from "grommet";

//hypergrade

class RegistrationFlowHeading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }


  render() {
    return (
      <Box direction="row" align="center" gap="small">
        <Box animation="pulse">
          {this.props.icon}
        </Box>
        <Heading margin={{vertical: "small"}} level="3">{this.props.label}</Heading>
      </Box>
    )
  }
}

RegistrationFlowHeading.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
};

RegistrationFlowHeading.defaultProps = {

};

export default RegistrationFlowHeading