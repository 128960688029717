import Moment from 'moment';
import classNames from "classnames";

let qs = require('qs');

const HyperGrade = {

  keyCost: 19,

  //web service API is set at the bottom of the file

  genericError: "HyperGrade doesn't seem to be responding. We are working on fixing this. Try again in a few minutes.",

  hardcoding: "To prevent hardcoding, this test case is hidden. If all the other test cases pass except this one, contact your instructor.",

  primaryCookieName: "primary",
  secondaryCookieName: "secondary",

  notificationAutoCloseTimeout: 10000,
  supportEmail: 'hyper@hypergrade.com',
  loading: {loading: true},

  projectErrorMessage1: "Fix the compilation errors and re-upload",

  noConnectionCode: "NO_CONNECTION",
  fetchErrorCode: "FAILED_TO_FETCH",

  //user roles
  roles: {teacher: "teacher", student: "student"},

  //date time
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'hh:mm A',
  momentFormat: 'is defined below',

  hourOptions: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  minuteOptions: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "59"],

  //supported file extensions
  languages: [
    {ext: "cpp", name: "C++", highlighterStyleName: "cpp"},
    {ext: "c", name: "C", highlighterStyleName: "cpp"},
    {ext: "h", name: "C/C++ Header", highlighterStyleName: "cpp"},
    {ext: "java", name: "Java", highlighterStyleName: "java"},
    {ext: "f", name: "Fortran", highlighterStyleName: "fortran"},
    {ext: "cs", name: "C#", highlighterStyleName: "cs"},
    {ext: "py", name: "Python", highlighterStyleName: "python"},
    {ext: "cxx", name: "C++", highlighterStyleName: "cpp"},
  ],
  binary_extensions: ["exe", "class"],
  supportedLanguagesString: "C, C++, C#, Java, and Python",
  supportedLanguagesString2: "C, C++, C#, Java, or Python",

  //assignment
  defaultText: 'Write a program that does the following.',

  //assignment editor
  workingMessage1: 'Your project is being recompiled and all test cases are being run. Please wait.',
  workingMessage2: 'The test case is updating. Please wait.',
  workingMessage3: 'Compiling and running.',

  errCannotContinue: -64600,

  //used for scrolling
  rootElementID: 'hypergrade-grommet-root',

  //default handler for not being logged in
  notLoggedInHandler: () => {
  },

  noConnectionHandler: () => {
    //App.js should override this
  },

  genericErrorHandler: (msg) => {
    //App.js should override this
  },

  postRegisterAction: function (userRow, redirect = "/home") {
    this.props.updateUserInfo(userRow);
    this.setState({redirect});
  },

  translateStringToMixedOutput(str) {
    return [{line: str, type: "output"}];
  },

  //////////////////////////////////////////////
  compileAndRunAll: function (verbose = true, refresh = true) {
    if (verbose) {
      this.props.beginWorking(HyperGrade.workingMessage1);
    }
    this.setState({compilationError: undefined, projectError: null});
    //this will take several seconds
    return HyperGrade.services.compileAndRunAll(this.props.questionID).then(res => {
      let {codeProject} = res.data;

      //clear the existing output on front-end (server does it on backend)
      this.setState({runResults: []});

      if (res.data === 'NO_CODE_TO_COMPILE') {
        this.setState({projectError: 'Add some code files to run'});
      } else if (codeProject.compilationError) {
        this.setState({projectError: HyperGrade.projectErrorMessage1});
        this.props.showErrorNotification('Code did not compile');
        this.setState({compilationError: codeProject.compilationError});
      } else {
        //this.props.showSuccessNotification("Success!");
        //take the easy route to refresh
        //wastes bandwidth
        if (refresh) {
          this.refresh(res.data);
        }
      }

      if (verbose) {
        this.props.endWorking();
      }
    }).catch(server => {
      this.props.endWorking();
      if (server.error && server.error.description) {
        this.props.showErrorNotification(server.error.description);
      } else {
        console.log(server);
      }

    });
  },

  compileAndRunAllFromAssignmentList: function (assignmentList, index = 0, appServices = null) {
    if (index < assignmentList.length) {
      let statusMessage = "Updating assignment " + (index + 1) + " of " + assignmentList.length + "\n";
      if (appServices) {
        appServices.beginWorking(statusMessage);
      }
      return this.compileAndRunAllFromAssignment(assignmentList[index], appServices)
        .then(() => this.compileAndRunAllFromAssignmentList(assignmentList, index + 1, appServices));
    } else {
      return Promise.resolve();
    }
  },

  compileAndRunAllFromAssignment: function (assignmentID, appServices = null) {
    return HyperGrade.services.getAssignmentAndQuestions(assignmentID).then(questionListResponse => {
      let questionList = questionListResponse.data.question;
      return this.compileAndRunAllFromQuestionList(questionList.map(question => question.id), 0, appServices);
    })
  },

  compileAndRunAllFromQuestionList: function (questionList, index = 0, appServices = null) {
    if (index < questionList.length) {
      return this.compileAndRunAllIncludingStudentWork(questionList[index], appServices)
        .catch(err => Promise.reject(questionList[index]))
        .then(() => this.compileAndRunAllFromQuestionList(questionList, index + 1, appServices));
    } else {
      return Promise.resolve();
    }
  },

  compileAndRunAllIncludingStudentWork: function (questionID, appServices) {
    return HyperGrade.services.compileAndRunAll(questionID).then(carResponse => {
      if (!carResponse.data.codeProject.compileResult) {
        return Promise.reject({
          compileFailed: questionID
        });
      }
      return HyperGrade.services.getStudentsThatHaveAttempted(questionID).then(studentResponse => {
        return this.daisyChain(questionID, appServices, studentResponse.data, 0);
      })
    });
  },

  daisyChain: function (questionID, appServices, arr, index = 0) {
    if (index < arr.length) {
      if (appServices) {
        appServices.beginWorking("Updating student work " + (index + 1) + " of " + arr.length);
      }

      return HyperGrade.services.compileAndRunAll(questionID, arr[index].key)
        .then(() => this.daisyChain(questionID, appServices, arr, index + 1))

    } else {
      return Promise.resolve();
    }
  },

  calcMyDueDateMoment: function (strMyDueDateUTC) {
    return Moment(Moment.utc(strMyDueDateUTC).toDate()).local();
  },
  ////////////////////////////

  validation: {
    validateRealName: function () {
      let problem = false;
      if (!this.state.firstName) {
        this.setState({firstNameError: "A name is required"});
        problem = true;
      }
      if (!this.state.lastName) {
        this.setState({lastNameError: "A name is required"});
        problem = true;
      }
      return problem;
    },
    validateEmail: function () {
      let problem = false;
      if (!this.state.email) {
        this.setState({emailError: "A valid email address is required"});
        problem = true;
      }
      return problem;
    },
    validatePassword: function () {
      let problem = false;
      if (!this.state.password || this.state.password.length < 8) {
        this.setState({passwordError: "Password must be at least 8 characters long"});
        problem = true;
      }

      //password 2
      if (this.state.password !== this.state.password2) {
        this.setState({password2Error: "Passwords must match"});
        problem = true;
      }
      return problem;
    },
  },

  util: {
    manage(name, additionalClasses) {
      return {
        value: this.state[name],
        onChange: e => {
          this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + "Error"]: null
          }, () => {

          })
        },
        className: classNames(
          additionalClasses,
          {"error": !!this.state[name + "Error"]}
        )
      }
    },
    makeBG(url) {
      return {"image": "url(" + url + ")"};
    },
    percentToLetter(p) {
      if (p >= 90)
        return 'A';
      else if (p >= 80)
        return 'B';
      else if (p >= 70)
        return 'C';
      else if (p >= 60)
        return 'D';
      else
        return 'F';
    },
    detectMyCountry() {
      let currentZone = HyperGrade.util.detectMyTimezone();
      return currentZone && currentZone.split('/')[0];
    },
    detectMyTimezone() { //get timezone based on browser, not based on HG settings
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    //converts the given time to local time (returns moment object)
    convertUTCToLocal(timestampUTC) {
      return Moment(Moment.utc(timestampUTC).toDate()).local();
    },
    moveByID(id, arr, delta) {
      let index = arr.findIndex(current => current.id === id);
      if (index >= 0) {
        HyperGrade.util.swap(arr, index, index + delta);
      } else {
        console.log("HyperGrade: Can't find object with ID " + id + " in ", arr);
      }
      return arr;
    },
    scrollToTop() {
      document.getElementById(HyperGrade.rootElementID).scrollTo(0, 0);
    },
    getScrollPosition() {
      return document.getElementById(HyperGrade.rootElementID).scrollTop;
    },
    registerForScrollEvents(handleScroll) {
      document.getElementById(HyperGrade.rootElementID).addEventListener('scroll', handleScroll);
    },
    unregisterForScrollEvents(handleScroll) {
      document.getElementById(HyperGrade.rootElementID).removeEventListener('scroll', handleScroll);
    },
    getFileExtension: filename => {
      let result = filename.lastIndexOf(".");
      return result >= 0 && result + 1 < filename.length ? filename.substring(result + 1) : undefined;
    },
    getLanguage: filename => {
      return HyperGrade.languages.find(current => current.ext === HyperGrade.util.getFileExtension(filename));
    },
    isSupportedCodeExtension: filename => {
      return !!HyperGrade.util.getLanguage(filename);
    },
    arrContainsSupportedCodeExtension: fileArr => //array of file objects (needs name property on each obj)
      Array.isArray(fileArr) && fileArr.findIndex(curr => HyperGrade.util.isSupportedCodeExtension(curr.name)) >= 0
    ,
    getNumCodeFiles: fileArr => !Array.isArray(fileArr) ? 0 :
      fileArr.reduce((acc, curr) => acc + (HyperGrade.util.isSupportedCodeExtension(curr.name) ? 1 : 0), 0)
    ,
    getNumOrganicCodeFiles: fileArr => !Array.isArray(fileArr) ? 0 :
      fileArr.reduce((acc, curr) => acc + (HyperGrade.util.isSupportedCodeExtension(curr.name) && !curr.inherited ? 1 : 0), 0)
    ,
    formatBytes: (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    swap: (arr, index1, index2) => {
      let t = arr[index1];
      arr[index1] = arr[index2];
      arr[index2] = t;
    },
    css_time_to_milliseconds: (time_string) => {
      let num = parseFloat(time_string);
      let milliseconds = 0;

      if (isNaN(num)) {
        console.log("HyperGrade: No float detected in ", time_string);
      } else {

        let unit = time_string.match(/m?s/);

        if (unit) {
          unit = unit[0];
          if (unit === "s")
            milliseconds = num * 1000;
          else if (unit === "ms")
            milliseconds = num;
        }
      }

      return milliseconds;
    }
  },

  getRequest(endpoint, dataToSend) {
    return HyperGrade.doFetch(endpoint, dataToSend, 'GET')
  },

  deleteRequest(endpoint, dataToSend) {
    return HyperGrade.doFetch(endpoint, dataToSend, 'DELETE')
  },

  putRequest(endpoint, dataToSend) {
    return HyperGrade.doFetch(endpoint, dataToSend, 'PUT')
  },

  postRequest(endpoint, dataToSend, useGenericErrorHandler = true) {
    return HyperGrade.doFetch(endpoint, dataToSend, 'POST', null, useGenericErrorHandler)
  },

  fileSend(endpoint, dataToSend, files) {
    return HyperGrade.doFetch(endpoint, dataToSend, 'POST', files)
  },

  makeFileDownloadLink(fileID) {
    return HyperGrade.webServiceBaseUrl + "/user/file/" + fileID;
  },

  doFetch(endpoint, dataToSend = null, method = 'POST', files, useGenericErrorHandler = true) {

    let fullEndpoint = HyperGrade.webServiceBaseUrl + endpoint;

    const options = {
      method, //like GET, POST, etc.
      origin: 'http://localhost:3000', //have some logic to change this to POLB
      credentials: 'include', //only needed for CORS otherwise it would be credentials: 'same-origin'
    };

    //POST requires the data in the headers
    if (files) {
      let formData = new FormData();

      if (files) {
        //attach the files
        files.forEach((file, index) => {
          formData.append(`file${index}`, file);
        });
      }

      //attach the other content
      if (dataToSend) {
        Object.entries(dataToSend).forEach((kv) => {
          formData.append(kv[0], kv[1]);
        });
      }

      options.body = formData; //will be multipart-form
    } else if (['PUT', 'DELETE', 'POST'].includes(method)) {

      if (dataToSend) {
        options.headers = {'Content-Type': 'application/json'};
        options.body = JSON.stringify(dataToSend);
      }

    } else if (method === "GET") { //GET requires data in the query string
      if (dataToSend) {
        fullEndpoint += "?" + qs.stringify(dataToSend, {encodeValuesOnly: true});
      }
    } else {
      return Promise.reject("HyperGrade: only POST, GET, PUT, DELETE, are supported");
    }

    return fetch(fullEndpoint, options)
      .catch(e => {
        //if we are online (message boards claim this is unreliable)
        if (navigator.onLine) {
          // double check we are online
          // Can't use HyperGrade.fetch here because we will get recursive calls and errors
          return fetch(HyperGrade.webServiceBaseUrl).then(res => {
            // we are pretty sure we're online however fetch failed
            // there are many reasons fetch files: https://developer.mozilla.org/en-US/docs/Web/API/Window/fetch
            // however, in our case, it likely failed because user drag-dropped a file without saving first
            // (file browser is holding on to is out of date)
            return Promise.reject(HyperGrade.fetchErrorCode);
          }).catch((err) => {
            // let error propagate if we have a fetch error
            if (err === HyperGrade.fetchErrorCode) {
              return Promise.reject(err);
            }

            HyperGrade.noConnectionHandler();
            return Promise.reject(HyperGrade.noConnectionCode);
          })
        } else {
          HyperGrade.noConnectionHandler();
          return Promise.reject(HyperGrade.noConnectionCode);
        }
      })
        .then(res => {
          //we did get a response from the server
          //check res.ok for HTTP errors
          return res.ok ? res.json() : res.json().then(obj => Promise.reject(obj));
        })
      .catch(res => {
        if (res !== HyperGrade.noConnectionCode && res !== HyperGrade.fetchErrorCode) {
          if (res.error && (res.error.type || res.error.description)) {
            let msg = res.error.type;

            if (res.error.description) {
              if (msg) {
                msg += ": " + res.error.description;
              } else {
                msg += res.error.description;
              }
            }

            HyperGrade.genericErrorHandler(msg);
          } else {
            HyperGrade.genericErrorHandler(res);
          }
        }
        return Promise.reject(res);
      })
      .then(res => {
        if (res.code === "NOT_LOGGED_IN") {
          HyperGrade.notLoggedInHandler();
          return Promise.reject(res);

          //if we want to use the generic handler
          //AND the server failed completing the request
          //AND we have something to show on screen...
        } else if (useGenericErrorHandler && !res.success && (res.code || res.msg)) {

          let msg;
          if (res.code && res.msg) {
            msg = res.code + ": " + res.msg;
          } else if (res.code) {
            msg = res.code;
          } else {
            msg = res.msg;
          }

          HyperGrade.genericErrorHandler(msg);
          return Promise.reject(res);
        } else {
          //here, res.success may still be false but since there is no code or msg, we let the component handle it
          return res;
        }
      })
  },
  services: {

    leaveStudentView: function () {
      return HyperGrade.postRequest('/leaveStudentView');
    },

    submitCode: function (assignmentID, code) {
      return HyperGrade.postRequest('/user/student/assignment/' + assignmentID + "/submitCode",
        {code}
      );
    },

    register: (email, role, firstName, lastName, password) => {
      const dataToSend = {
        email, role, firstName, lastName, password,
        timezone: HyperGrade.util.detectMyTimezone()
      };
      return HyperGrade.postRequest('/register', dataToSend, false);
    },
    updateEmail: (email) => {
      return HyperGrade.putRequest('/user/email', {email});
    },
    needsEmailConfirmation: () => {
      return HyperGrade.getRequest('/user/needsEmailConfirmation');
    },
    submitEmailConfirmationPin: (pin) => {
      return HyperGrade.postRequest('/user/submitEmailConfirmationPin', {pin});
    },
    usePaymentForKey: () => {
      return HyperGrade.postRequest('/user/student/usePaymentForKey');
    },
    sendEmailConfirmationMessage: () => {
      return HyperGrade.getRequest('/user/sendEmailConfirmationMessage');
    },
    isPinStillValid: (pin) => {
      return HyperGrade.getRequest('/pin/' + pin + '/valid');
    },
    getPartialUser: (partialUserCode) => {
      return HyperGrade.getRequest('/partialUser/' + partialUserCode);
    },
    commitPartialUser: (partialUserCode) => {
      return HyperGrade.postRequest('/partialUser/' + partialUserCode + '/commit');
    },
    sendSupportEmail: (supportName, supportEmail, supportText) => {
      const dataToSend = {
        supportName,
        supportEmail,
        supportText
      };
      return HyperGrade.postRequest('/sendSupportEmail', dataToSend);
    },
    updatePasswordWithPin: (pin, newPassword) => {
      const dataToSend = {password: newPassword};
      return HyperGrade.postRequest('/pin/' + pin + '/update', dataToSend);
    },
    updatePassword: (currentPassword, newPassword) => {
      const dataToSend = {
        currentPassword,
        newPassword
      };
      return HyperGrade.putRequest('/user/password', dataToSend);
    },
    updateRealName: (firstName, lastName) => {
      return HyperGrade.putRequest('/user/realName', {firstName, lastName});
    },
    canTurnIn: (assignmentID) => {
      return HyperGrade.getRequest('/user/student/assignment/' + assignmentID + '/canTurnIn');
    },
    getTextFile: (fileID) => {
      return HyperGrade.getRequest('/user/file/' + fileID + '/text');
    },
    getMyTasks: () => {
      return HyperGrade.postRequest('getMyTasks');
    },
    setTimezone: (timezone) => {
      return HyperGrade.putRequest('/user/timezone', {timezone});
    },
    enroll: (courseID, key) => {
      return HyperGrade.postRequest('/user/student/enroll', {courseID, key});
    },
    createPaymentIntent: () => {
      return HyperGrade.postRequest('/user/student/createPaymentIntent');
    },
    loginAsTestStudent: (courseID) => {
      return HyperGrade.postRequest('/user/teacher/course/' + courseID + '/testStudent/login');
    },
    loginWithAccessCode: (accessCode) => {
      const dataToSend = {
        accessCode
      };
      return HyperGrade.postRequest('/loginWithAccessCode', dataToSend);
    },
    login: (email, password) => {
      const dataToSend = {
        username: email,
        password: password
      };
      return HyperGrade.postRequest('/login', dataToSend);
    },
    logout: () => {
      return HyperGrade.postRequest('/user/logout');
    },
    resetPassword: (email) => {
      const dataToSend = {
        email: email
      };
      return HyperGrade.postRequest('/resetPassword', dataToSend);
    },
    generateRiskReport: (enrollmentID, questionIDs) => { //service for testing a connection
      return HyperGrade.getRequest('/user/teacher/riskReport/' + enrollmentID + '/' + questionIDs);
    },
    hello: () => { //service for testing a connection
      return HyperGrade.getRequest('');
    },
    getCoursesOverview: () => {
      return HyperGrade.getRequest('/user/teacher/courses/overview');
    },
    changeDueDate: (assignmentID, dueDateMoment) => {
      const dataToSend = {
        dateTime: dueDateMoment.format(HyperGrade.momentFormat)
      };
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/dueDate/update', dataToSend);
    },
    clearDueDate: (assignmentID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/dueDate/delete');
    },
    changeAssignmentName: (assignmentID, name) => {
      const dataToSend = {
        value: name
      };
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/name', dataToSend);
    },
    getAssignmentAndQuestions: (assignmentID) => {
      return HyperGrade.getRequest('/user/assignment/' + assignmentID);
    },
    createCourse: (courseName = null) => {
      return HyperGrade.postRequest('/user/teacher/course', {courseName});
    },
    createQuestion: (assignmentID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/question');
    },
    deleteScore: (enrollmentID, assignmentID) => {
      return HyperGrade.deleteRequest(
        '/user/teacher/student/' + enrollmentID + '/assignment/' + assignmentID + '/score'
      );
    },
    approve: (enrollmentID, assignmentID, value) => {
      return HyperGrade.putRequest(
        '/user/teacher/student/' + enrollmentID + '/assignment/' + assignmentID + '/score',
        {value}
      );
    },
    approveAll: (assignmentID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/approveAll');
    },
    saveComment: (enrollmentID, assignmentID, value) => {
      return HyperGrade.putRequest(
        '/user/teacher/student/' + enrollmentID + '/assignment/' + assignmentID + '/comment',
        {value}
      );
    },
    toggleHideAssignmentsInGradeCenter: (assignmentIDarr) => {
      const dataToSend = {
        assignmentIDs: assignmentIDarr
      };
      return HyperGrade.postRequest('/user/teacher/grade/assignments/toggleVisibility', dataToSend);
    },
    storeFileForQuestionFromString: (questionID, fileName, fileContent) => {
      return HyperGrade.postRequest('/user/file/question/' + questionID + "/text", {fileName, fileContent});
    },
    storeFileForQuestion: (questionID, files) => {
      return HyperGrade.fileSend('/user/file/question/' + questionID, null, files);
    },
    toggleHideSingleAssignmentInGradeCenter: (assignmentID) => {
      HyperGrade.services.toggleHideAssignmentsInGradeCenter([assignmentID]);
    },
    getStudentWork: (enrollmentKey, assignmentID, includeAssignmentData = false, questionIndexList = "0") => {
      const dataToSend = {
        includeAssignmentData,
        questionIndexList
      };
      return HyperGrade.getRequest('/user/teacher/student/' + enrollmentKey + '/assignment/' + assignmentID + '', dataToSend);
    },
    getQuestionComponents: (questionID) => {
      return HyperGrade.getRequest('/user/question/' + questionID + '/components');
    },
    getTestCases: (assignmentID) => {
      return HyperGrade.getRequest('/user/assignment/' + assignmentID + '/testCases');
    },
    toggleExtraCredit: (questionID) => {
      return HyperGrade.postRequest('/user/teacher/question/' + questionID + '/toggleExtraCredit');
    },
    setPointValue: (questionID, value) => {
      const dataToSend = {
        value: value
      };
      return HyperGrade.postRequest('/user/teacher/question/' + questionID + '/pointValue', dataToSend);
    },
    deleteQuestion: (questionID) => {
      return HyperGrade.postRequest('/user/teacher/question/' + questionID + '/delete');
    },
    rearrangeTestCases: (sortedTestCaseIDs) => {
      const dataToSend = {
        sortedTestCaseIDs
      };
      return HyperGrade.postRequest('/user/teacher/testCases/rearrange', dataToSend);
    },
    rearrangeQuestions: (sortedQuestionIDs) => {
      const dataToSend = {
        sortedQuestionIDs
      };
      return HyperGrade.postRequest('/user/teacher/questions/rearrange', dataToSend);
    },
    rearrangeAssignments: (sortedAssignmentIDs) => {
      const dataToSend = {
        sortedAssignmentIDs
      };
      return HyperGrade.postRequest('/user/teacher/assignments/rearrange', dataToSend);
    },
    copyAssignment: (assignmentID, courseID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/copyToCourse/' + courseID);
    },
    copyQuestion: (questionID, dstAssignmentID) => HyperGrade.postRequest('/user/teacher/question/' + questionID + '/copy', {dstAssignmentID}),
    copyCourse: (courseID) => HyperGrade.postRequest('/user/teacher/course/' + courseID + '/copy'),
    clearAllDueDates: (courseID) => HyperGrade.postRequest('/user/teacher/course/' + courseID + '/clearDueDates'),
    clearAllStudents: (courseID) => HyperGrade.postRequest('/user/teacher/course/' + courseID + '/clearStudents'),
    deleteCourse: (courseID) => HyperGrade.deleteRequest('/user/teacher/course/' + courseID),
    deleteAssignment: (assignmentID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/delete');
    },
    toggleDisableLateDays: (assignmentID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/toggleDisableLateDays');
    },
    setFileVisibility: (fileID, visibility) => {
      return HyperGrade.putRequest('/user/teacher/file/' + fileID + '/visibility/' + !!visibility);
    },
    setEntryPoint: (fileID) => {
      return HyperGrade.putRequest('/user/file/' + fileID + '/entryPoint');
    },
    toggleRequirePassword: (assignmentID) => {
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/password/toggle');
    },
    toggleStudentVisibility: (testCaseID) => {
      return HyperGrade.putRequest('/user/teacher/testCase/' + testCaseID + '/toggleStudentVisibility');
    },
    changeAssignmentPassword: (assignmentID, password) => {
      const dataToSend = {
        password: password
      };
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/password', dataToSend);
    },
    enableAssignmentPassword: (assignmentID, password) => {
      const dataToSend = {
        password: password
      };
      return HyperGrade.postRequest('/user/teacher/assignment/' + assignmentID + '/password/enable', dataToSend);
    },
    updateQuestionText: (questionID, contentState) => {
      const dataToSend = {
        value: contentState
      };
      return HyperGrade.putRequest('/user/teacher/question/' + questionID + '/draft', dataToSend);
    },
    toggleNoAutoGrade: (questionID) => {
      return HyperGrade.postRequest('/user/teacher/question/' + questionID + '/toggleNoAutoGrade');
    },
    getStudentList: (courseID) => {
      return HyperGrade.getRequest('/user/teacher/course/' + courseID + '/students');
    },
    isCourseCodeValid: (courseID) => {
      return HyperGrade.getRequest('/user/course/' + courseID + "/isValid");
    },
    getCourse: (courseID) => {
      return HyperGrade.getRequest('/user/teacher/course/' + courseID);
    },
    updateCourseName: (courseID, name) => {
      return HyperGrade.putRequest('/user/teacher/course/' + courseID + '/name', {name});
    },
    getGradingInfo: (courseID) => {
      return HyperGrade.getRequest('/user/teacher/course/' + courseID + '/grades');
    },
    getMyCourses: () => {
      return HyperGrade.getRequest('/user/courses');
    },
    getMyGrades: () => {
      return HyperGrade.getRequest('/user/student/grades');
    },
    getKeyCost: () => {
      return HyperGrade.getRequest('/user/student/getMyCost');
    },
    getAssignments: (courseID) => {
      return HyperGrade.getRequest('/user/teacher/course/' + courseID + '/assignments');
    },
    getQuestion: (questionID) => {
      const dataToSend = {
        question_id: questionID
      };
      return HyperGrade.getRequest('getQuestion', dataToSend);
    },
    getGradeSummary: (courseID) => {
      const dataToSend = {
        course_id: courseID
      };
      return HyperGrade.getRequest('getGradeSummary', dataToSend);
    },
    setLateDaysForStudent: (enrollmentKey, numLateDays) => {
      return HyperGrade.postRequest('/user/teacher/student/' + enrollmentKey + '/lateDay/set', {numLateDays});
    },
    incrementLateDayForStudent: (enrollmentKey) => {
      return HyperGrade.postRequest('/user/teacher/student/' + enrollmentKey + '/lateDay/increment');
    },
    decrementLateDayForStudent: (enrollmentKey) => {
      return HyperGrade.postRequest('/user/teacher/student/' + enrollmentKey + '/lateDay/decrement');
    },
    incrementLateDayForCourse: (courseID) => {
      return HyperGrade.postRequest('/user/teacher/course/' + courseID + '/lateDay/increment');
    },
    decrementLateDayForCourse: (courseID) => {
      return HyperGrade.postRequest('/user/teacher/course/' + courseID + '/lateDay/decrement');
    },
    changeEmailForStudent: (enrollmentKey, newStudentEmail) => {
      const dataToSend = {newStudentEmail};
      return HyperGrade.postRequest('/user/teacher/student/' + enrollmentKey + '/changeEmailForStudent', dataToSend);
    },
    changeRealNameForStudent: (enrollmentKey, firstName, lastName) => {
      const dataToSend = {firstName, lastName};
      return HyperGrade.postRequest('/user/teacher/student/' + enrollmentKey + '/changeRealNameForStudent', dataToSend);
    },
    deleteStudent: (enrollmentKey) => {
      return HyperGrade.postRequest('/user/teacher/student/' + enrollmentKey + '/delete');
    },
    compileFilesForQuestion: (questionID) => {
      const dataToSend = {
        question_id: questionID
      };
      return HyperGrade.postRequest('compileFilesForQuestion', dataToSend);
    },
    getStudentsThatHaveAttempted: (questionID) => {
      return HyperGrade.getRequest('/user/teacher/question/' + questionID + '/getStudentsThatHaveAttempted');
    },
    compileAndRunAll: (questionID, enrollmentID = null) => {
      const dataToSend = {
        enrollmentID
      };
      return HyperGrade.putRequest('/user/question/' + questionID + '/compileAndRunAll', dataToSend);
    },
    deleteFile: (fileID) => {
      return HyperGrade.deleteRequest('/user/file/' + fileID);
    },
    updateTestCase: (questionID, stdin, cla, testCaseID) => {
      const dataToSend = {stdin, cla};
      return HyperGrade.putRequest('/user/teacher/question/' + questionID + '/testCase/' + testCaseID, dataToSend);
    },
    createTestCase: (questionID, stdin, cla) => {
      const dataToSend = {stdin, cla};
      return HyperGrade.postRequest('/user/teacher/question/' + questionID + '/testCase', dataToSend);
    },
    createAssignment: (courseID) => {
      return HyperGrade.postRequest('/user/teacher/course/' + courseID + '/assignment');
    },
    deleteTestCase: (testCaseID) => {
      return HyperGrade.deleteRequest('/user/teacher/testCase/' + testCaseID);
    },
    getMyAssignments: (courseID) => {
      return HyperGrade.getRequest('/user/student/course/' + courseID + '/assignments')
        .then(res => {
          res.data.assignments.forEach(assignment =>
            assignment.myDueDateMoment = HyperGrade.calcMyDueDateMoment(assignment.myDueDateUTC));
          return res;
        });
    },
    getMyAccountInfo: () => {
      return HyperGrade.getRequest('/user/getMyAccountInfo');
    },
    useLateDays: (key, assignmentID, howMany) => {
      let dataToSend = {};
      return HyperGrade.postRequest('/user/student/assignment/' + assignmentID + '/useLateDays/' + key + '/' + howMany, dataToSend);
    },
  },
};

HyperGrade.momentFormat = HyperGrade.dateFormat + ' ' + HyperGrade.timeFormat;

if (process.env.NODE_ENV === 'production') {
  HyperGrade.webServiceBaseUrl = "https://bes.hypergrade.com/api";
  HyperGrade.stripePublishableKey = 'pk_live_7Tz6Bpgp4RMEFnWnpEgxoyL500XzZgGmRP';
} else {
  HyperGrade.webServiceBaseUrl = "https://hypergrade-web-services.ddev.site/api";
  HyperGrade.stripePublishableKey = 'pk_test_390Se7vCNueOSjuqbWWlUazc00mGjFt05o';
}

export default HyperGrade;